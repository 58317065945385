import React from 'react';
import * as AV from '../AVCore/AVCore';
import { useQuery } from 'react-query';
import { setUser } from '../Redux/Actions/userActions';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setFeedOwners, setFeeds, setFetchProcess, setPostList } from '../Redux/Actions/feedsAction';
import { Store } from '../Platform/Store';
import _ from 'lodash';
import { Alert } from '../AVCore/Alert';


function useGetFeeds ( page, id, isOwner, isFollowing, userInfo )
{
    const [ isRefreshing, setIsRefreshing ] = React.useState( false );
    const dispatch = useDispatch();
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const feedOwnersList = useSelector( ( state : RootStateOrAny ) =>
        state.feeds.feedowners ? state.feeds.feedowners[ 0 ] : []
    );
    const userfeeds = useSelector( ( state : RootStateOrAny ) => state.feeds.feeds[ 0 ] );

    const getFeeds = async () =>
    {
        console.log("GetFeeds::::::::::::")
        let feedsval = userfeeds;
        let lastUpdated = new Date( feedsval?.length > 0 ? feedsval[ 0 ]?.lastUpdated : 0 );
        let feeds : any = {};
        let reportedIds = currentUser?.reported;

        if ( page == "Profile" && isFollowing === false && isOwner === false )
        {
            feeds = await fetchFeeds( userInfo, new Date( 0 ), isOwner, reportedIds );
            feeds?.Post?.sort( ( a : any, b : any ) =>
            {
                const createDateA = new Date( a.createDate ) as any;
                const createDateB = new Date( b.createDate ) as any;
                return createDateB - createDateA;
            } );
            let posts = list_to_tree( feeds?.Post );
            return posts;
        }
        else if ( page == "Profile" && userInfo?.id && ( isOwner === true || isFollowing == true ) )
        {
            let feedsval = userfeeds?.filter( x => x?.owner?.id == userInfo?.id );
            let lastUpdated = new Date( feedsval?.length > 0 ? feedsval[ 0 ]?.lastUpdated : 0 );
            if ( feedsval?.length > 0 )
            {
                return feedsval;
            }
            feeds = await fetchFeeds( userInfo, lastUpdated, isOwner, reportedIds );
            setIsRefreshing( false );
            let returnvalue = list_to_tree( feeds?.Post );
            returnvalue = returnvalue?.sort( ( a : any, b : any ) =>
            {
                const createDateA = new Date( a.createDate ) as any;
                const createDateB = new Date( b.createDate ) as any;
                return createDateB - createDateA;
            } );
            FeedsreduxUpdate( returnvalue, userfeeds );
            return returnvalue;
        }
        else
        {
            let feedsval = userfeeds;
            let followers = _.uniqBy( [ ...( currentUser?.follows || [] ), ...( currentUser?.autoFollows || [] ) ], 'id' );
            followers.push( currentUser );
            if ( followers?.length > 0 )
            {
                feedsval = feedsval?.filter( ( item1 : any ) => followers?.some( ( item2 : any ) => item2?.id === item1?.pk ) );
            }
            if ( feedsval?.length > 0 )
            {
                return feedsval;
            }
            if ( currentUser )
            {
                feeds = await fetchFeeds( AV.AVEntity.getFk( currentUser ), lastUpdated, true, reportedIds );
                fetchFollowerFeeds( currentUser, lastUpdated, feeds );
                setIsRefreshing( false );
                feeds?.Post?.sort( ( a : any, b : any ) =>
                {
                    const createDateA = new Date( a.createDate ) as any;
                    const createDateB = new Date( b.createDate ) as any;
                    return createDateB - createDateA;
                } );
                let returnvalue = list_to_tree( feeds?.Post );
                dispatch( setFeeds( returnvalue ) );
                return returnvalue;
            }
            else
                return [];
        }
    }
    //Redux Update
    const FeedsreduxUpdate = async ( returnvalue, userfeeds ) =>
    {
        console.log( "FeedsreduxUpdate::::::::::::" )
        returnvalue = returnvalue?.filter( ( item1 : { id : any; } ) => !userfeeds?.some( ( item2 : { id : any; } ) => item2?.id === item1?.id ) );
        returnvalue = returnvalue.concat( userfeeds );
        returnvalue?.sort( ( a : any, b : any ) =>
        {
            const createDateA = new Date( a.createDate ) as any;
            const createDateB = new Date( b.createDate ) as any;
            return createDateB - createDateA;
        } );
        dispatch( setFeeds( returnvalue ) );
    }
    //FetchFeeds
    const fetchFeeds = async ( Entity, lastUpdated, iscurrentUser, reportedIds ) =>
    {
        console.log( "fetchFeeds::::::::::::" )
        let FeedOwnersList = feedOwnersList?.length > 0 ? feedOwnersList : []
        let param = { feed: { id: Entity?.id, pk: Entity?.id, type: "Feed" }, startDate: lastUpdated, forward: true, checkArchive: false };
        let response : any = [];
        if ( param?.feed?.id )
            /*if (FeedOwnersList == undefined || FeedOwnersList == null || !FeedOwnersList?.find((x : any) => x?.id == param?.feed?.id)) {*/
            response = await AV.Feed.getFeed( param );
        if ( response?.id )
        {
            let allFeedOwners = _.uniqBy( [ ...( feedOwnersList || [] ), ...( response?.id ? [ response ] : [] ) ], 'id' );
            dispatch( setFeedOwners( allFeedOwners ) );

        }
        //else {
        //  response = FeedOwnersList?.find((x : any) => x?.id == param?.feed?.id)
        //}
        response.followIds = _.uniqBy( response?.followIds, 'id' );
        response.privateIds = _.uniqBy( response?.privateIds, 'id' );
        response.publicIds = _.uniqBy( response.publicIds, 'id' );
        let PostList = await AV.Feed.getPostList( response );
        if ( PostList?.length > 0 )
        {
            let Post : any = await AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList?.slice( 0, 5 ) );
            for ( let i = 0; i < Post?.length; i++ )
            {
                console.log( "fetchFeeds for::::::::::::" )
                if ( Post[ i ].posts?.length > 0 )
                {
                    var cmdPost : any = await AV.Feed.getPosts( AV.AVEntity.getFk( Post[ i ] ), Post[ i ]?.posts?.slice( 0, 2 ) );
                    if ( cmdPost?.length > 0 )
                    {
                        Post[ i ].children = cmdPost;
                    }
                }
            }
            response.followIds = response?.followIds.map( ( post ) => ( { ...post, owner: response?.id } ) )
            response.privateIds = response?.privateIds.map( ( post ) => ( { ...post, owner: response?.id } ) )
            response.publicIds = response?.publicIds.map( ( post ) => ( { ...post, owner: response?.id } ) )
            // Combine posts and remove duplicates
            let allPosts = _.uniqBy( [ ...( response?.followIds || [] ), ...( response?.privateIds || [] ), ...( response?.publicIds || [] ), ], 'id' )
            return { Post, response, allPosts } || [];
        }
        return [];

    };
    //FetchFollowers Feeds
    const fetchFollowerFeeds = async ( person, lastUpdated, selfFeeds ) =>
    {
        console.log( "fetchFollowerFeeds::::::::::::" )
        // Combine followers and auto-followed users, removing duplicates
        const combinedFollowers = _.uniqBy(
            [
                ...( person?.follows || [] ),
                ...( person?.autoFollows?.slice( 0, 20 ) || [] )
            ],
            'id'
        );
        let responseCount = 0;
        let allPosts : any = _.uniqBy( [ ...( selfFeeds?.allPosts || [] ) ], 'id' );
        let allResponses : any = _.uniqBy( [ ...( [ selfFeeds?.response ] || [] ) ], 'id' );
        // Iterate through each follower to fetch their feeds
        for ( let i = 0; i < combinedFollowers?.length; i++ )
        {
            console.log( "fetchFollowerFeeds for::::::::::::" )
            dispatch( setFetchProcess( true ) );
            const follower = combinedFollowers[ i ];
            const feedParam = { feed: { id: follower?.id, pk: follower?.id, type: "Feed" }, startDate: lastUpdated, forward: true, checkArchive: false };
            // Create a function to handle the delay
            const delay = ( ms ) => new Promise( resolve => setTimeout( resolve, ms ) );

            if ( feedParam?.feed?.id )
            {
                try
                {
                    AV.Feed.getFeed( feedParam ).then( ( feed : any ) =>
                    {
                        if ( feed?.id )
                        {
                            feed.followIds = feed?.followIds.map( ( post ) => ( { ...post, owner: feed?.id } ) );
                            feed.privateIds = feed?.privateIds.map( ( post ) => ( { ...post, owner: feed?.id } ) );
                            feed.publicIds = feed?.publicIds.map( ( post ) => ( { ...post, owner: feed?.id } ) );
                            // Combine posts and remove duplicates
                            allPosts = _.uniqBy(
                                [
                                    ...( allPosts || [] ),
                                    ...( feed?.followIds || [] ),
                                    ...( feed?.privateIds || [] ),
                                    ...( feed?.publicIds || [] ),
                                ],
                                'id'
                            );
                            allResponses.push( feed );
                        }
                        // Check if all followers' feeds have been fetched
                        if ( feed && responseCount === combinedFollowers?.length - 1 )
                        {
                            processFollowerFeeds( allResponses, allPosts, selfFeeds );
                        }
                        responseCount++;
                    } );
                } catch ( error:any )
                {
                   if(error.response.status === 429) 
                    await delay(5000 );
                    console.error( 'Error fetching feed:', error );
                }
            }

            // Add 1 seconds delay
            await delay(1000);
        }
    };
    //GetPost List
    const handleGetPostList = async ( item ) =>
    {
        console.log( "handleGetPostList::::::::::::" )
        var retval : string[] = [];
        for ( var i = 0; item && i < item.length; i++ )
        {
            console.log( "handleGetPostList for1::::::::::::" )
            retval.push( item[ i ].id );
            //@ts-ignore
            for ( var g = 0; item[ i ].gallery && g < item[ i ].gallery.length; g++ )
            {
                console.log( "handleGetPostList for2::::::::::::" )
                //@ts-ignore
                retval.push( item[ i ].gallery[ g ] );
            }
        }
        return retval;
    }
    //ProcessFollowers Feeds
    const processFollowerFeeds = async ( response, postList, selfFeeds ) =>
    {
        console.log( "processFollowerFeeds::::::::::::" )
        // Sort PostList by date
        postList?.sort( ( a, b ) =>
        {
            const createDateA = new Date( a.date ) as any;
            const createDateB = new Date( b.date ) as any;
            return createDateB - createDateA;
        } );

        // Combine feed owners from previous fetch with the current response
        let allFeedOwners = _.uniqBy( [ ...( feedOwnersList || [] ), ...( response || [] ) ], 'id' );
        // Store All Feeds and Post 
        dispatch( setFeedOwners( allFeedOwners ) );
        dispatch( setPostList( postList ) );

        // Initialize Today's Posts array with unique self feeds
        let latestPost = _.uniqBy( [ ...( selfFeeds?.Post || [] ) ], 'id' );
        // Remove slef post [already fetch]
        postList = postList?.filter( ( item1 : { id : any; } ) => !selfFeeds?.Post?.some( ( item2 : { id : any; } ) => item2?.id === item1?.id ) );

        //postList = postList?.filter((x : any) => x?.id == selfFeeds?.Post?.id);
        // Limit PostList to the latest 10 posts
        postList = postList?.slice( 0, 10 );

        // Group posts by owner ID
        const postsByOwner = {};
        postList.forEach( post =>
        {
            const ownerId = post.owner;
            if ( !postsByOwner[ ownerId ] )
            {
                postsByOwner[ ownerId ] = [];
            }
            postsByOwner[ ownerId ].push( post );
        } );

        // Iterate through each owner's posts
        for ( const ownerId in postsByOwner )
        {
            if ( postsByOwner.hasOwnProperty( ownerId ) )
            {
                // Find the owner in the response
                const owner = response.find( x => x?.id === ownerId );
                if ( owner )
                {
                    const postIds = postsByOwner[ ownerId ];
                    // Fetch owner's posts asynchronously
                    const ownerPosts = await handleGetPostList( postIds );
                    if ( ownerPosts?.length > 0 )
                    {
                        // Fetch additional posts for each post
                        let fetchedPosts = await fetchAdditionalPosts( owner, ownerPosts );
                        // Combine fetched posts with Today's Posts
                        latestPost = _.uniqBy( [ ...( latestPost || [] ), ...( fetchedPosts || [] ) ], 'id' );
                    }
                }
            }
        }
        // Convert the list of posts into a tree structure and sort by createDate
        let formattedPosts = list_to_tree( latestPost );
        formattedPosts = formattedPosts?.sort( ( a, b ) =>
        {
            const createDateA = new Date( a.createDate ) as any;
            const createDateB = new Date( b.createDate ) as any;
            return createDateB - createDateA;
        } );
        // Update user feeds with the formatted posts
        Feedsupdate( userfeeds, formattedPosts );
        dispatch( setFetchProcess( false ) );
    }
    // Function to fetch additional posts for each post
    const fetchAdditionalPosts = async ( owner, posts ) =>
    {
        console.log( "fetchAdditionalPosts::::::::::::" )
        if ( posts?.length > 0 )
        {
            let Post : any = await AV.Feed.getPosts( AV.AVEntity.getFk( owner ), posts );
            for ( let i = 0; i < Post?.length; i++ )
            {
                console.log( "fetchAdditionalPosts for::::::::::::" )
                if ( Post[ i ].posts?.length > 0 )
                {
                    var cmdPost : any = await AV.Feed.getPosts( AV.AVEntity.getFk( Post[ i ] ), Post[ i ]?.posts?.slice( 0, 2 ) );
                    if ( cmdPost?.length > 0 )
                    {
                        Post[ i ].children = cmdPost;
                    }
                }
            }
            return Post;
        }
        return [];
    }
    //Feeds Update
    const Feedsupdate = async ( feedsval, feeds ) =>
    {
        console.log( "Feedsupdate::::::::::::" )
        let updatedFeeds = [ ...( feedsval || [] ) ];
        feeds.forEach( feed =>
        {
            let existingFeed = feedsval?.find( x => x?.id === feed?.id );
            if ( !existingFeed )
            {
                updatedFeeds.unshift( feed );
            } else
            {
                existingFeed.impressions = feed.impressions;
                existingFeed.text = feed.text;
            }
        } );
        updatedFeeds = _.uniqBy( updatedFeeds, 'id' );
        let usersfeeds = list_to_tree( updatedFeeds );
        usersfeeds = _.uniqBy( usersfeeds, 'id' );
        usersfeeds?.sort( ( a : any, b : any ) =>
        {
            const createDateA = new Date( a.createDate ) as any;
            const createDateB = new Date( b.createDate ) as any;
            return createDateB - createDateA;
        } );
        if ( page !== "Profile" )
        {
            dispatch( setFeeds( usersfeeds ) );
        }
        return usersfeeds;
    };
    //List to tree
    const list_to_tree = ( list ) =>
    {
        var map = {}, node : any, roots : any = [], i;

        for ( i = 0; i < list?.length; i += 1 )
        {
            map[ list[ i ]?.id ] = i; // initialize the map
            if ( list[ i ]?.children?.length === 0 || !list[ i ]?.children )
                list[ i ][ "children" ] = []; // initialize the children
            if ( list[ i ]?.galleryPost?.length === 0 || !list[ i ]?.galleryPost )
                list[ i ][ "galleryPost" ] = [];
        }

        for ( i = 0; i < list?.length; i += 1 )
        {
            node = list[ i ];
            if ( node.parentPost !== undefined )
            {
                if ( list[ map[ node.parentPost ] ]?.gallery?.items?.filter( ( x ) => x === node.id )?.length > 0 && node.gallery == undefined )
                {
                    let excitingpost = list[ map[ node.parentPost ] ]?.galleryPost?.filter( x => x?.id === node?.id );
                    if ( excitingpost?.length === 0 || excitingpost === undefined )
                        list[ map[ node.parentPost ] ]?.galleryPost.push( node );
                } else
                {
                    let excitingcomment = list[ map[ node.parentPost ] ]?.children?.filter( x => x?.id === node?.id );
                    if ( excitingcomment?.length === 0 || excitingcomment === undefined )
                        list[ map[ node.parentPost ] ]?.children.push( node );
                }
            } else
            {
                roots.push( node );
            }
        }
        return roots;
    }
    //OnRefresh function
    const onRefresh = () =>
    {
        //set isRefreshing to true
        getFeeds();
    };
    const { isLoading, data, isError, isFetching, refetch } = useQuery( [ 'allFeeds', id ], getFeeds );
    return {
        isLoading,
        data,
        onRefresh,
        isRefreshing,
        isError,
        isFetching,
        refetch,
    };
}
export default useGetFeeds;
