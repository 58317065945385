import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const Router = (props) => {
    const { routes } = props;
    const renderComponent = (Context, Component, route) => {
        return (
            <Component { ...props } { ...route } />
        );
    };
    return (
        <>
            <Routes>
                { routes.map((route, index) => {
                    const { exact, path, component: Component, withContext: Context } = route;
                    return (
                        <Route
                            exact={ exact }
                            key={ index }
                            path={ path }
                            element={ renderComponent(Context, Component, route) }
                        />
                    );
                }) }
            </Routes>
        </>
    );
};
