/*!
 * Copyright © 2021 Avtavivo
 */
import * as AV from './Interfaces/AV.Interfaces';
import { Server } from '../Platform/Server';
import { AVEntity } from './AVEntity';
import { Loc } from "../App/Loc";
//@ts-ignore
export class Person extends AVEntity {
  static _self: AV.IPerson;
  static _api = '/api/Person';
  static _entity = '/api/Entity';

  public static async getbyName(path: string): Promise<AV.IPerson> {
    var p: AV.IPerson = await Server.get<AV.IPerson>(Person._api + path);
    return p;
  }
  //public static async saveProfilePic(person: AV.IPerson): Promise<AV.IPerson> {

  //    var p: AV.IPerson = await Server.post<AV.IPerson>(Person._api + "/SaveProfilePic",person);
  //    return p;
  //}
  public static self(): AV.IPerson {
    return this._self;
  }
  static checkIfSelf(val: AV.IAVEntity) {
    //console.log('===>', val)
    if (this._self && val?.id === this._self.id) this._self = val;
  }
  public static async getSelf(force: boolean = false): Promise<AV.IPerson> {
    if (!Person._self || force) {
        Person._self = await Server.get<AV.IPerson>(Person._api + '/GetSelf');
        Loc.SetLang(Person._self?.preferences?.lang);
    }
    return Person._self;
  }
  public static async get(fk?: AV.IFK): Promise<AV.IPerson> {
    if (fk && Object.keys(fk).length > 0) {
      var p: AV.IPerson = await Server.post<AV.IPerson>(Person._entity + '/Get', fk);
      return p;
    }
    return await Person.getSelf();
  }
  public static async activate(req: AV.ITokenRequest): Promise<AV.IPerson> {
      var p: AV.IPerson = await Server.post<AV.IPerson>(Person._api + '/Activate', req);
      if (p)
    this._self = p;
    return p;
    }
    public static async checkActivate(req: AV.ITokenRequest): Promise<boolean> {
        return await Server.post<boolean>(Person._api + '/CheckActivate', req);
        
  }
  public static makeSmall (val : AV.IAVEntity) : AV.IAVEntity {
    let newval : AV.IAVEntity = JSON.parse(JSON.stringify(val));
    newval.autoFollows = undefined;
    newval.follows = undefined;
    newval.convos = undefined;
    newval.myRequests = undefined;
    newval.albums = undefined;
    newval.files = undefined;
    newval.members = undefined;
    newval.orgs = undefined;
    newval.ownedEvents = undefined;

    //Person
    (<AV.IPerson> newval).events = undefined;
    (<AV.IPerson> newval).wards = undefined;
    (<AV.IPerson> newval).guardians = undefined;
    (<AV.IPerson> newval).requests = undefined;
    (<AV.IPerson> newval).blockedBy = undefined;
    (<AV.IPerson> newval).fcmTokens = undefined;
    (<AV.IPerson> newval).blocked = undefined;
    (<AV.IPerson> newval).favs = undefined;
    (<AV.IPerson> newval).reported = undefined;
    (<AV.IPerson> newval).silenced = undefined;
    return newval;
  }

  public static async save(val: AV.IPerson): Promise<AV.IPerson> {
    var p : AV.IPerson = await Server.post<AV.IPerson>(Person._entity, this.makeSmall(val));
    if (p.id === this._self?.id) this._self = p;
    return p;
  }
    public static async getNoEmailInviteURL(): Promise<string> {
        return await Server.get(this._api + "/GetInviteNoEmailURL");
    }

    //Add Dependent, remove  is a request
    public static async removeDependant(child: AV.ILink): Promise<AV.IPerson> {
        return await Server.post(this._api + "/RemoveDependant", child);
    }
    public static async addGuardian(parent: AV.IContact): Promise<AV.IPerson> {
        return await Server.post(this._api + "/AddGuardian", parent);
    }
    public static async removeGuardian(parent: AV.IFK): Promise<AV.IPerson> {
        return await Server.post(this._api + "/RemoveGuardian", parent);
    }
    public static async createInvite (child : AV.IContact[]) : Promise<AV.IPerson> {
        return await Server.post(this._api + '/CreateInvite', child);
    }
    public static async changeContact (contact : AV.ITokenProcessRequest) : Promise<AV.IPerson> {
        return await Server.post(this._api + '/ChangeContact', contact);
    }
    public static async VerifyContact(contact: AV.ITokenProcessRequest) : Promise<AV.IPerson> {
        return await Server.post(this._api + '/VerifyContact', contact);
    }

    public static async addFCMToken(token: string): Promise<AV.IPerson> {
        return await Server.post(this._api + '/AddFCMToken', token);
    }

    public static async clientError ( error : string )
    {
        return await Server.post( this._api + '/ClientError', error );
    }
    public static async removeFCMToken (token : string) : Promise<AV.IPerson> {
        return await Server.post(this._api + '/RemoveFCMToken', token);
    }
  public static async addFavorite(source: AV.IFK, target: AV.ILink): Promise<AV.IPerson> {
    return await Server.post(this._api + '/AddFavorite', { source: source, target: target });
  }
  public static async removeFavorite(source: AV.IFK, target: AV.ILink): Promise<AV.IPerson> {
    return await Server.post(this._api + '/RemoveFavorite', { source: source, target: target });
  }
  public static async addActivity(source: AV.IFK, target: AV.IActivity): Promise<AV.IPerson> {
    return await Server.post(this._api + '/AddActivity', { source: source, target: target });
  }
  public static async removeActivity(source: AV.IFK, target: AV.IActivity): Promise<AV.IPerson> {
    return await Server.post(this._api + '/RemoveActivity', { source: source, target: target });
  }
}
