import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SignalRService } from 'AVCore/SignalRTest';
import { RootStateOrAny } from 'react-redux';

const SignalRTest : React.FC = () => {
  // Get the current user from the Redux store
  const currentUser = useSelector((state : RootStateOrAny) => state.user.user[ 0 ]);

  // Get the latest conversation ID
  const convoId = currentUser.convos[ currentUser.convos.length - 1 ].id;

  // Create a ref to hold typing indicators
  const typingIndicatorRef = useRef<{ [ key : string ] : HTMLParagraphElement | null }>({});

  // Function to set up the SignalR connection
  const setupSignalRConnection = async () => {

    // Join the conversation group
    await SignalRService.joinGroup(convoId);

    // Register the typing event handler
    if (SignalRService.getConnection()) {
      SignalRService.getConnection()!.on('typing', (receivedConvoId, userName) => {
        console.log("Typing event received:", { receivedConvoId, userName });

        // Update typing indicator if the event is for the current conversation and from a different user
        if (
          receivedConvoId === convoId &&
          typingIndicatorRef.current[ receivedConvoId ] &&
          currentUser.name !== userName
        ) {
          typingIndicatorRef.current[ receivedConvoId ]!.textContent = `${ userName } is typing...`;
          console.log(`${ userName } is typing in conversation ${ receivedConvoId }...`);

          // Clear the typing indicator after 6 seconds
          setTimeout(() => {
            if (typingIndicatorRef.current[ receivedConvoId ]) {
              typingIndicatorRef.current[ receivedConvoId ]!.textContent = '';
            }
          }, 6000);
        }
      });
    }
  };

  // Function to handle sending a typing event
  const handleSendTyping = async () => {
    await SignalRService.sendTyping(convoId);
  };

  // Effect to set up the SignalR connection when the component mounts or convoId/userId changes
  useEffect(() => {
    setupSignalRConnection();

    // Cleanup on unmount
    return () => {
      if (SignalRService.getConnection()) {
        SignalRService.getConnection()!.off('typing');
      }
    };
  }, [ convoId, currentUser.id ]);

  return (
    <div>
      <h1>Signal R</h1>
      <p ref={ el => typingIndicatorRef.current[ convoId ] = el }></p>
      <button onClick={ handleSendTyping }>Send Typing</button>
    </div>
  );
};

export default SignalRTest;