import React, { FC, useState } from 'react';
import { Box, VStack, HStack, Text, Pressable, Button,Divider, CloseIcon } from 'native-base';
import { Loc } from '../../App/Loc';
import AVInputtext from 'components/AVInputtext';
import { useWindowDimension } from 'Utility/WindowDimensions';
import * as AV from '../../AVCore/AVCore';
import _ from 'lodash';
import { AddIcon, EditIcon, DeleteIcon, CancelIcon  } from '../../assets/icons/icon-assets';
import { Server } from '../../Platform/Server';
import CustomToast from '../../Hooks/useCustomToast';

interface Props
{
    updateField : any | undefined,
    setShowFields : any | undefined,
    locationEntity : any | undefined,
    datafield : any | undefined,
    fieldName : any | undefined,
    leagueTeamFK : any | undefined,
    setLocationEntity : any | undefined,
    locationaddress : any | undefined,
    setIsDataField : any | undefined,
    setIsFieldName : any | undefined,
    siteField : any | undefined,
    setSiteField : any | undefined,
    isCreateevent : any | undefined,
    setIsUpdateField : any | undefined,
}

const AVFieldMap : FC<Props> = ( { updateField, setShowFields, locationEntity, datafield, fieldName, leagueTeamFK, setLocationEntity, locationaddress, setIsDataField, setIsFieldName, siteField, setSiteField, isCreateevent, setIsUpdateField }) =>
{
    const [ isLoadingField, setIsLoadingField ] = useState( false );
    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;
    const imageInputRef = React.useRef<HTMLInputElement>( null );
    const [ error, setError ] = useState<any>( '' );
    const [ showaddfied, setShowAddField ] = useState( false );
    const [ fieldList, setFieldList ] = useState( false );
    const [ itemname, setitemname ] = useState();
    const [ ImageProcessing, setIsImageProcessing ] = React.useState( false );
    const [ isAdd, setIsAdd ] = useState( false );
    const [ updatefiedname, setIsUpdatefieldname ] = useState( '' );
    const [ openInput, setOpenInput ] = useState( false );
    //Upload Image

    const handleDrop = async ( e ) =>
    {
        e.preventDefault();

        const doc = e.dataTransfer.files;
        const fileInput : any = imageInputRef.current;
        let files : any = Array.from( doc );
        const updatedFiles = files.map( ( file ) =>
        {
            const fileName = file.name;
            if ( fileName.toLowerCase().endsWith( '.jfif' ) )
            {
                const updatedFileName = fileName.replace( /\.\w+$/, '.jpg' );
                const updatedFile = new File( [ file ], updatedFileName, { type: "image/jpeg" } );
                return updatedFile;
            }
            else
            {
                return files;
            }
        } );
        files = updatedFiles;
        const data = await AV.Media.saveFiles( AV.Media.getFormData( files ), leagueTeamFK?.region );
        let map : any = await AV.Location.updateSiteMap( locationaddress?.id?.trim()?.length > 0 ? locationaddress : leagueTeamFK?.location, data[ 0 ] );
        setLocationEntity( map );
        fileInput.value = null;
        setIsImageProcessing( false );
        setIsLoadingField( false );
        setShowAddField( false );
    };

    const handleDragOver = ( e ) =>
    {
        e.preventDefault();
    };

    const handleImages = async ( e : any ) =>
    {
        setIsLoadingField( true );
        const fileInput : any = imageInputRef.current;
        let files : any = [];
        files = Array.from( e?.target?.files );
        const updatedFiles = files.map( ( file ) =>
        {
            const fileName = file.name;
            if ( fileName.toLowerCase().endsWith( '.jfif' ) )
            {
                const updatedFileName = fileName.replace( /\.\w+$/, '.jpg' );
                const updatedFile = new File( [ file ], updatedFileName, { type: "image/jpeg" } );
                return updatedFile;
            }
            else
            {
                return file;
            }
        } );
        files = updatedFiles;
        let file = updatedFiles[ 0 ];
        if ( !file?.type?.startsWith( 'image' ) && !file?.type?.startsWith( 'application' ) )
        {
            setIsImageProcessing( false );
            setIsLoadingField( false );
            setShowAddField( false );
            return;
        }
        const data = await AV.Media.saveFiles( AV.Media.getFormData( files ), leagueTeamFK?.region );
        let map : any = await AV.Location.updateSiteMap( locationaddress?.id?.trim()?.length > 0 ? locationaddress : leagueTeamFK?.location, data[ 0 ] );
        setLocationEntity( map );
        fileInput.value = null;
        setIsImageProcessing( false );
        setIsLoadingField( false );
        setShowAddField( false );
    };

    //Add Field
    const handleaddfield = async () =>
    {
        setIsLoadingField( true );
        let fieldmap : AV.IField = {
            id: Server.generateUUID(),
            name: fieldName,
        };
        //todo : send fk for location entity
        if ( siteField )
        {
            // Check if the name already exists in the mapfield.fields array
            const nameExists = siteField.some( field => field.name === fieldName );

            if ( nameExists )
            {
                // Name already exists, display toast message and stop execution
                let clientError = { message: 'Field already exists.' };
                CustomToast( { clientError } );
                setIsLoadingField( false );
                setIsFieldName( '' );;
                return;
            }
        }

        let mapfield : any = await AV.Location.addField( locationEntity, fieldmap );
        setIsDataField( ( field ) => [ ...field, fieldmap ] );
        setSiteField( mapfield?.fields );
        setFieldList( true );
        setIsLoadingField( false );
        setIsFieldName( '' );
        setOpenInput( false );
    };
    //Remove Field
    const handleRemovefield = async ( item ) =>
    {
        let mapfield = await AV.Location.removeField( locationEntity, item );
        setSiteField( mapfield?.fields );
        setIsDataField( datafield.filter( ( f ) => f.id != item.id ) );
    }
    //SetField
    const handlesetfield = ( item ) =>
    {
        setitemname( item );
        setIsAdd( true );
        setIsUpdatefieldname( item?.name );
        setIsFieldName( item?.name );
    };
    //Delete Field
    const handleDeleteFieldMap = async ( e : any ) =>
    {
        setIsLoadingField( true );
        let map : any = await AV.Location.deleteSiteMap( locationaddress );
        setLocationEntity( map );
        setIsLoadingField( false );
        setShowAddField( true );
    };
    //Field Name
    const handleFieldName = ( e ) =>
    {
        e.preventDefault();
        setIsFieldName( e?.target?.value );
    };
    //Update site map
    const handleUpdatefield = async ( itemname ) =>
    {
      
        if (!isAdd )
        {
            handleaddfield()
        }
        else
        {
            setIsLoadingField( true );
            if ( itemname )
            {
                // Check if item is not undefined
                const updatedItem = { ...itemname, name: fieldName }; // Create a new object with the updated property
                let mapfield = await AV.Location.updateField( locationEntity, updatedItem );
                setIsLoadingField( true );
                setSiteField( mapfield?.fields );
                setIsFieldName( '' );
            }
            setIsLoadingField( false );
            setOpenInput(false)
        }
        
    };


    return (
    
        <>
            <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={  isCreateevent ? '-300px':0 } right={ 0 } top={ 0 } left={ 0 }>
                <div
                    style={ {
                        position: 'fixed',
                        top: '53%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: '#ffffff',
                        boxShadow:
                            '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                        width: isMobile ? '88%' : isCreateevent ? '90%': '35%',
                        borderRadius: '8px',
                        height: '70%',
                        overflowY : 'scroll',
                        overflowX: 'hidden',
                    } }
                >
                    <HStack
                        alignItems={ 'center' }
                        justifyContent={ 'space-between' }
                        borderBottomColor={ 'AVColor.grey' }
                        px={ 5 }
                        zIndex={ 99 }
                        width={ '100%' }
                        marginTop={ '24px' }
                    >
                        <Text fontSize={ 30 }>{ updateField ? Loc.currentLang.menu.editFieldText : Loc.currentLang.menu.addField }</Text>
                        <Button
                            backgroundColor={ 'white' }
                            onPress={ () => setShowFields( false ) }
                            isDisabled={ isLoadingField }
                        >
                            <CancelIcon size={ '36px' } color={ 'AVColor.black' } />
                        </Button>

                    </HStack>
                    <VStack width={ '100%' }>
                        { ( locationEntity?.siteMap?.orgFile?.trim()?.length == undefined || showaddfied ) && (
                            <VStack width={ '100%' }>
                                <HStack width={ 'auto' } marginLeft={ '25px' } marginTop={ updateField ? '' : '24px' }>
                                    <Text fontSize={ '18px' } fontWeight={ 400 }>
                                        { !updateField
                                            ? Loc.currentLang.menu.addfieldorcampusmaptohelpotherfindthelocation
                                            : '' }
                                    </Text>
                                </HStack>
                                <VStack
                                    borderStyle={ 'dashed' }
                                    borderColor={ 'gray.300' }
                                    marginTop={ '24px' }
                                    borderRadius={ '5px' }
                                    borderWidth={ '2px' }
                                    padding={ '20px' }
                                    width={ '92%' }
                                    height={ '76px' }
                                    alignSelf={ 'center' }
                                    zIndex={ -3 }
                                >
                                    <div
                                        style={ { textAlign: 'center' } }
                                        onDrop={ handleDrop }
                                        onDragOver={ handleDragOver }
                                    >
                                    <HStack justifyContent={ 'center' } space={ 2 }>
                                        <Pressable onPress={ () => imageInputRef?.current?.click() } >
                                            <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.uploadFile }</Text>
                                        </Pressable>
                                        <Text >or</Text>
                                        <Text fontWeight={ 400 }>{ Loc.currentLang.menu.dragNDrop }</Text>
                                    </HStack>

                                    {/*<Text textAlign={'center'} color={'gray.300'}>*/ }
                                    {/*  {Loc.currentLang.menu.docExtensions}*/ }
                                    {/*</Text>*/ }
                                    { error && (
                                        <Text textAlign={ 'center' } color={ '#DF2827' } marginTop={ '5px' }>
                                            { ' ' }
                                            { Loc.currentLang.menu.error }
                                        </Text>
                                        ) }
                                    </div>
                                </VStack>
                                <input
                                    type='file'
                                    accept="image/jpeg,image/jfif,image/png,image/webp,image/gif,application/pdf"
                                    multiple
                                    hidden
                                    ref={ imageInputRef }
                                    onChange={ handleImages }
                                />
                            </VStack>
                        ) }
                        { ( locationEntity?.siteMap?.orgFile?.trim()?.length > 0 && !showaddfied ) && (
                            <VStack width={ '100%' }>
                                <Box
                                    width={ '87%' }
                                    marginLeft={ '24px' }
                                    height={ '50px' }
                                    justifyContent={ 'center' }
                                >
                                    <HStack justifyContent={ 'space-between' } >
                                        <HStack justifyContent={ 'center' } width={'80%'}>
                                            <Text textAlign={ 'center' } fontSize={ '16px' } color={ '#00A7BC' } numberOfLines={ 1 } ellipsizeMode={ 'tail' } textTransform={ 'capitalize' }>
                                                { locationEntity?.siteMap?.orgFile }
                                            </Text>
                                        </HStack>
                                        <HStack width={ '20%' } justifyContent={ 'center' } space={ 2 }>
                                            { updateField && (
                                                <Pressable
                                                    onPress={ () =>
                                                    {
                                                        setShowAddField( true );
                                                    } }
                                                >
                                                    <EditIcon size='20px' />
                                                </Pressable>
                                            ) }
                                            <Pressable
                                                onPress={ handleDeleteFieldMap }
                                            >
                                                <DeleteIcon size='20px' />
                                            </Pressable>
                                        </HStack>
                                    </HStack>
                                </Box>
                            </VStack>
                        ) }
                        <HStack marginTop={ '24px' } marginLeft={ '24px' } width={ 'auto' }>
                            { updateField  ? <Pressable onPress={ () => { setIsUpdateField( false ); setIsFieldName( '' ); setOpenInput(true);}}>
                                <Text color={ '#00A7BC' }>{ Loc.currentLang.menu.addFields }</Text>
                            </Pressable>:
                            <HStack width={ '100%' } padding={ '24px' }>
                                <Box width={ '80%' } marginTop={ '24px' }>
                                    <AVInputtext
                                        type={ 'text' }
                                        label={ updateField ? Loc.currentLang.placeholderText.updateFieldName : Loc.currentLang.placeholderText.enterFieldName }
                                        onChange={ handleFieldName }
                                        value={ fieldName }
                                        height={ '20px' }
                                        maxLength={ 50 } defaultValue={ undefined } width={ undefined } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined } />
                                </Box>
                                { !updateField && (
                                        <HStack alignItems={ 'center' }>
                                        <HStack marginLeft={ '25px' } height={ '37px' }>
                                            <Button
                                                bgColor={ 'AVColor.primary' }
                                                borderRadius={ 'full' }
                                                width={ '50px' }
                                                height={ '50px' }
                                                isDisabled={ fieldName?.trim().length === 0 }
                                                isLoading={ isLoadingField }
                                                onPress={ () => handleaddfield() }
                                            >
                                                <AddIcon size={ '30px' } color='AVColor.white' />
                                            </Button>
                                        </HStack>
                                    </HStack>
                                ) }
                                
                            </HStack>}
                        </HStack>
                        <VStack marginLeft={ '24px' } width={ '100%' } marginTop={ '20px' } >
                            { !updateField && fieldList && datafield?.length > 0 && (
                                <VStack>
                                    <HStack marginBottom={ '15px' }>
                                        <Text fontWeight={ 500 } fontSize={ '16px' }>
                                            { Loc.currentLang.menu.teamField }
                                        </Text>
                                    </HStack>{ ' ' }
                                    <VStack height={ '160px' } overflowY={ 'scroll' } width={ '88%' }>
                                        { datafield?.reverse().map( ( item ) => (
                                            <VStack marginBottom={ '15px' }>
                                                <HStack justifyContent={ 'space-between' } marginRight={ '10px' }>
                                                    <HStack>
                                                        <Text width={ '300px' } numberOfLines={ 1 } ellipsizeMode={ 'tail' } fontWeight={ 500 } fontSize={ '16px' }>
                                                            { item?.name }
                                                        </Text>
                                                    </HStack>
                                                    <HStack width={ '20%' } justifyContent={ 'center' } space={ 2 }>                                                    
                                                        <Pressable
                                                            onPress={ () => { setIsUpdateField( true ); setIsFieldName( '' ); setOpenInput( true ); handlesetfield( item ) } }>
                                                        
                                                            <EditIcon size='20px' />
                                                        </Pressable>
                                                        <Pressable
                                                            onPress={ () =>
                                                            {
                                                                handleRemovefield( item );
                                                            } }
                                                        >
                                                            <DeleteIcon size='20px' />
                                                        </Pressable>
                                                    </HStack>
                                                </HStack>
                                                <Divider width={ '100%' } marginTop={ '15px' } />
                                            </VStack>
                                        ) ) }
                                    </VStack>
                                </VStack>
                            ) }
                            { updateField && siteField?.length > 0 && (
                                <VStack>
                                    <HStack marginBottom={ '15px' }>
                                        <Text fontWeight={ 500 } fontSize={ '16px' }>
                                            { Loc.currentLang.menu.editField }
                                        </Text>
                                    </HStack>
                                    <VStack height={ '160px' } overflowY={ 'scroll' } width={ '88%' }>
                                        { siteField?.reverse()?.map( ( item ) => (
                                            <VStack marginBottom={ '15px' }>
                                                <HStack justifyContent={ 'space-between' } marginRight={ '10px' }>
                                                    <HStack>
                                                        <Text width={ '300px' } numberOfLines={ 1 } ellipsizeMode={ 'tail' } fontWeight={ 500 } fontSize={ '16px' }>
                                                            { item?.name }
                                                        </Text>
                                                    </HStack>
                                                    <HStack space={ 2 }>
                                                        <Pressable onPress={ () => { setIsUpdateField( true ); setIsFieldName( '' ); setOpenInput( true ); handlesetfield( item ) } }>
                                                            <EditIcon size='20px' />
                                                        </Pressable>
                                                        <Pressable
                                                            onPress={ () =>
                                                            {
                                                                handleRemovefield( item );
                                                            } }
                                                        >
                                                            <DeleteIcon size='20px' />
                                                        </Pressable>
                                                    </HStack>
                                                </HStack>
                                                <Divider width={ '100%' } marginTop={ '15px' } />
                                            </VStack>
                                        ) ) }
                                    </VStack>
                                </VStack>
                            ) }
                        </VStack>
                    </VStack>
                 
                </div>
            </Box>
            { openInput && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        className={ `create_post_popup ${ isMobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: isMobile ? '': '560px',
                            height: isMobile ? '650px' : '260px',
                            marginTop: '12px',
                        } }
                    >
                        <VStack width={ '100%' } padding={ '24px' }>
                            <HStack justifyContent={ 'space-between' } width={'100%'} >
                                <Text fontSize={'24px'}>{ updateField ? Loc.currentLang.menu.editField : Loc.currentLang.menu.addField }</Text>
                                <Pressable onPress={ () => { setOpenInput( false ); setIsUpdateField(true)}}> 
                                    <CloseIcon size={ '20px' } color={ 'AVColor.secondaryText' } />
                                </Pressable>
                            </HStack>
                            <HStack width={ '100%' } >
                            <Box width={ '80%' } marginTop={'24px'}>
                                <AVInputtext
                                    type={ 'text' }
                                        label={ updateField ? Loc.currentLang.placeholderText.updateFieldName : Loc.currentLang.placeholderText.enterFieldName }
                                    onChange={ handleFieldName }
                                    value={ fieldName }
                                    height={ '20px' }
                                    maxLength={ 50 } defaultValue={ undefined } width={ undefined } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined } />
                            </Box>
                            { !updateField && (
                                    <HStack alignItems={'center'}>
                                    <HStack marginLeft={ '25px' } height={ '37px' }>
                                        <Button
                                            bgColor={ 'AVColor.primary' }
                                            borderRadius={ 'full' }
                                            width={ '50px' }
                                            height={ '50px' }
                                            isDisabled={ fieldName?.trim().length === 0 }
                                            isLoading={ isLoadingField }
                                            onPress={ () => handleaddfield() }
                                        >
                                            <AddIcon size={ '30px' } color='AVColor.white' />
                                        </Button>
                                    </HStack>
                                </HStack>
                            ) }
                            { updateField && (
                                    <HStack alignItems={ 'center' }>
                                    <HStack marginLeft={ '30px' } marginTop={ '9px' } height={ '37px' }>
                                        <Button
                                            bgColor={ 'AVColor.primary' }
                                            borderRadius={ 'full' }
                                            width={ '50px' }
                                            height={ '50px' }
                                            isLoading={ isLoadingField }
                                            isDisabled={ fieldName?.trim().length === 0 }
                                            onPress={ () => { handleUpdatefield( itemname ) } }
                                        >
                                            <AddIcon size={ '30px' } color='AVColor.white' />
                                        </Button>
                                    </HStack>
                                </HStack>
                                ) }
                            </HStack>
                        </VStack>
                    </div>
                </Box>
            ) }
        </>
    )
}
export default AVFieldMap;