import {  Divider, HStack, Text, VStack, Box ,Tooltip} from 'native-base';
import React, { useEffect, useState, useRef } from 'react';
import { Loc } from '../../App/Loc';
import { RootStateOrAny, useSelector } from 'react-redux';
import helping from '../../assets/images/helping.png';
import inform from '../../assets/images/inform.png';
import { useWindowDimensions } from 'react-native';
import RenderEvents from '../Events/RenderEvents';
import { Dimensions } from 'react-native';
import AVSelectDropdown from '../../components/common/AVSelectDropdown';
import AVComDropdonwn from '../../components/common/AVComDropdown';
import _ from 'lodash';

const ShowEvents = ({ handleEvents, userData, isOwner }) => {
    const [ data, setEventData ] = React.useState([]);
    const [ eventType, setEventType ] = useState(Loc.currentLang.menu.all);
    const [ eventsList, setEventsList ] = React.useState();
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 500 || false;
    const GetEvents = () => {
        let allEvents = userData?.ownedEvents || [];
        setEventData(allEvents);
        setEventsList(allEvents);
        HandleCurrent('ALL')
    };
    const HandleCurrent = (type : string) => {
        const CurrentDateTime = new Date();

        let EventDataList : any = type === "ALL" ? userData?.ownedEvents : '';

        var currentEventsList : any = _(EventDataList) // Using lodash chain
            .filter((event : any) => {
                const originalStartDate = new Date(event.end);
                const formattedStartDate = originalStartDate.toISOString();
                return formattedStartDate >= CurrentDateTime.toISOString();
            }).sort((a : any, b : any) => {
                const startA : any = new Date(a.start);
                const startB : any = new Date(b.start);
                return startA - startB;
            })
            .map((event : any) => ({ ...event }))
            .uniqBy('id') // Ensure uniqueness by 'id'
            .value(); // Retrieve the value from lodash chain
        currentEventsList = currentEventsList.filter(x => x.status !== "Canceled" && x.data !== "Canceled");
        setEventData(currentEventsList);
    };

    useEffect(() => {
        GetEvents();
    }, [ userData ]);

    return (<>

        <VStack p={ 2 } backgroundColor={ 'AVColor.white' } minHeight={ '340px' } width={ '100%' }>
            <VStack space={ 5 }>
                <EventsMenu
                    handleEvents={ handleEvents }
                    eventType={ eventType }
                    eventsList={ eventsList }
                    setEventType={ setEventType }
                    setEventData={ setEventData }
                    isOwner={ isOwner }
                    data={ data }
                    userData={ userData }
                    HandleCurrent={ HandleCurrent }
                />
            </VStack>
            <Divider my={ 2 } zIndex={ -3 } />
            { data?.length > 0 && ((
                <HStack
                    zIndex={ -3 }
                    space={ 1 }
                    fontWeight={ '500' }
                    flexWrap={ 'wrap' }
                    height={ 'auto' }
                    paddingY={ '5px' }
                    borderRadius={ 10 }
                    width={ '100%' }
                    marginTop={ data?.length === 0 ? '10px' : '' }
                    justifyContent={ data?.length === 0 ? 'center' : isMobile ? 'center' : '' }
                >
                    <RenderEvents data={ data } />
                </HStack>
            )) }
            { data?.length == 0 &&
                (
                    <HStack width={ '100%' } top={ isMobile ? '20px' : '0px' } padding={ isMobile ? '10px' : '10px' } marginTop={ isMobile ? '-10px' : '0px' } space={ isMobile ? 2 : 2 } zIndex={ -3 }><img src={ inform } style={ { width: '20px', height: '20px' } } />{ Loc.currentLang.helpText.person.eventTab }</HStack>
                ) }

        </VStack>
    </>

    );
};

const EventsMenu = ({ handleEvents, eventType, eventsList, setEventType, userData, setEventData, isOwner, data, HandleCurrent }) => {
    const dimensions = useWindowDimensions();
    const isMobile = dimensions?.width <= 600 || false;
    const [ isActives, setIsActives ] = useState(false);
    const [ filterevent, setFilterEvent ] = useState(false);
    const [ currentstatus, setcurrentStatus ] = useState(Loc.currentLang.menu.current)
    const currentUser = useSelector((state : RootStateOrAny) => state?.user?.user[ 0 ]);
    const HandlePast = (type) => {
        const CurrentDateTime = new Date();

        let EventDataList : any = type == "ALL" ? eventsList : '';
        var PastEventsList : any = EventDataList
            .filter(event => {
                const originalStartDate = new Date(event.end);
                const formattedEndDate = originalStartDate.toISOString();

                return formattedEndDate < CurrentDateTime.toISOString();
            })
            .map(event => {
                return {
                    ...event,
                };
            })
            .sort((a : any, b : any) => {
                const startA : any = new Date(a.start);
                const startB : any = new Date(b.start);
                return startA - startB;
            });

        PastEventsList = PastEventsList.filter(x => x.status !== "Canceled" && x.data !== "Canceled");
        setEventData(PastEventsList);
    };
    const HandleAll = (type) => {
        const CurrentDateTime = new Date();
        let EventDataList : any = type == "ALL" ? eventsList : '';

        EventDataList.sort((a : any, b : any) => {
            const startA : any = new Date(a.start);
            const startB : any = new Date(b.start);
            return startA - startB;
        });
        EventDataList = EventDataList.filter(x => x.status !== "Canceled" && x.data !== "Canceled");
        setEventData(EventDataList);
    };
    const HandleDelete = (type) => {
        let EventDataList : any = type == "ALL" ? eventsList.filter((x) => x.data === "Canceled") : '';
        EventDataList.sort((a : any, b : any) => {
            const startA : any = new Date(a.start);
            const startB : any = new Date(b.start);
            return startA - startB;
        });
        setEventData(EventDataList);
    };
    const menuRefs = useRef(null);
    useOutsideAlerter(menuRefs);
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            let isMounted = true;
            if (isMounted) {
                function handleClickOutside (event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setIsActives(false);
                    }
                }
                document.addEventListener('mousedown', handleClickOutside);
                return () => {
                    document.removeEventListener('mousedown', handleClickOutside);
                };
            }
            return () => {
                isMounted = false; // Set the flag to false when the component is unmounted
                // Cancel subscriptions or perform any cleanup tasks here
            };
        }, [ ref ]);
    }
    const HandleAlls = () => {
        setcurrentStatus(Loc.currentLang.menu.all)
        setFilterEvent(!filterevent);
        HandleAll("ALL")
    }
    const HandleCurrents = () => {
        setcurrentStatus(Loc.currentLang.menu.current)
        HandleCurrent("ALL")
        setFilterEvent(!filterevent);
    }
    const HandlePasts = () => {
        setcurrentStatus(Loc.currentLang.menu.past)
        setFilterEvent(!filterevent);
        HandlePast("ALL")
    }
    const HandleCanceled = () => {
        setcurrentStatus(Loc.currentLang.menu.canceled)
        HandleDelete("ALL")
        setFilterEvent(!filterevent);

    }
    const dropdownValues = [
        { id: 1, name: Loc.currentLang.menu.all, flag: data, fn: HandleAlls },
        { id: 2, name: Loc.currentLang.menu.current, flag: data, fn: HandleCurrents },
        { id: 3, name: Loc.currentLang.menu.past, flag: data, fn: HandlePasts },
        { id: 4, name: Loc.currentLang.menu.canceled, flag: data, fn: HandleCanceled },
    ]
    const HandleSocial = () => {
        handleEvents('Social');
        setIsActives(!isActives);

    }
    const HandleMeeting = () => {

        handleEvents('Meeting');
        setIsActives(!isActives);

    }
    const dropdownEventValues = [
        { id: 3, name: Loc.currentLang.menu.socialText, flag: data, fn: HandleSocial },
        { id: 4, name: Loc.currentLang.menu.meeting, flag: data, fn: HandleMeeting },
    ]

    return (
        <>
            <HStack justifyContent={ 'space-between' } marginY={ '11px' } marginX={ '10px' } alignItems={ 'center' } space={ 2 } width={ '100%' } flexWrap={ isMobile ? 'wrap' : 'nowrap' }>
                <HStack width={ isMobile ? '96%' : '100%' } justifyContent={ 'space-between' } marginTop={ isMobile ? '14px' : '' } marginLeft={ '-15px' } height={ '38px' } alignItems={ 'center' } >
                    <Text
                        fontWeight={ '500' }
                        marginLeft={ '15px' }
                        fontSize={ '22px' }
                        color={ 'AVColor.primary' }
                        py={ '12px' }
                    >
                        { Loc.currentLang.menu.events }
                    </Text>

                    <HStack space={ isMobile ? 2 : 2 }>
                        { currentUser?.ownedEvents?.length > 0 &&
                            <HStack left={ isMobile ? '10px' : '0px' }>
                                <AVSelectDropdown dropdownValues={ dropdownValues } currentstatus={ currentstatus } setIsActives={ setFilterEvent } isActives={ filterevent } />
                            </HStack> }
                        <Tooltip label={ Loc.currentLang.helpText.person.eventTab } w={ 200 } left={ isMobile ? '10px' : undefined }>
                            <Box marginRight={ '10px' } p={ '9px' } left={ isMobile ? '10px' : undefined }
                                display={ data?.length == 0 && userData?.events?.length == 0 ? 'none' : '' }
                                shadow={ 1 }
                                zIndex={ -3 }
                                height={ '33px' }
                                backgroundColor={ 'AVColor.liteBg' }
                                rounded={ 'full' }>
                                <img src={ helping } height={ '17px' } width={ '17px' } alt="Help" />

                            </Box>
                        </Tooltip>
                        { isOwner && (
                            <>
                                <HStack top={ isMobile ? '0px' : '0px' } right={ isMobile ? '0px' : '0px' } >
                                    <AVComDropdonwn dropdownValues={ dropdownEventValues } />
                                </HStack>
                            </>
                        ) }
                    </HStack>

                </HStack>

            </HStack>
        </>
    )
};

export default ShowEvents;