import { useDispatch } from "react-redux";
import { setAlertState, setAlerts, setNewAlerts } from "../Redux/Actions/alertActions";
import { setFeedOwners, setFeeds, setFeedsState, setFeedsUpdate, setFetchProcess, setPostList } from "../Redux/Actions/feedsAction";
import { setConvo, setConvoState } from "../Redux/Actions/convoActions";
import { setEntityToken, setEntitydata, setFeedShowing, setFeedsLoad, setFollowRequestData, setFollowersData, setIsNotificationEnabled, setLocation, setSearch, setToastShow, setUser, setuserisguardian, setusersLang, setusersprofile, setisActive, setDeviceFcmToken, setIsConvoScreenOpen, setLastFeedUpdate, setresetState } from "../Redux/Actions/userActions";


const useClearCache = () => {
  const dispatch = useDispatch();

  const clearCache = (reloadWindow = false) => {
    // Clearing cache by dispatching actions
    dispatch(setAlertState());
    dispatch(setConvoState());
    dispatch(setFeedsState());
    dispatch(setresetState());
    //dispatch(setIsConvoScreenOpen([ { isOpen: false, convoId: undefined } ]));

    // Reload the window after clearing cache if reloadWindow is true
    if (reloadWindow) {
      window.location.reload();
    }
  };

  return clearCache;
};

export default useClearCache;