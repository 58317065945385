import React, { FC,useRef } from 'react';
import { HStack, Pressable, Text, VStack, Divider } from 'native-base';
import { Dimensions } from 'react-native';
import { Loc } from '../../App/Loc';

interface MenuItem {
    id: string;
    name: string;
    fn: () => void;
    flag?: boolean;
}

interface TabHeaderProps {
    menu: any
    MoreMenu: any;
    isDropDown: boolean;
    setIsDropDown: React.Dispatch<React.SetStateAction<boolean>>;
    type : string | undefined;
    Entity:any
}

const TabHeader : FC<TabHeaderProps> = ({ menu, isDropDown, MoreMenu, setIsDropDown, type,Entity }) => {
    const menuRefs = useRef(null);
    const dimensions = Dimensions.get( 'window' );
    const fourhundred = dimensions?.width <= 400 || false;
    const fiveSeventy = dimensions?.width <= 570 || false;
    const sevenSeventy = dimensions?.width <= 770 || false;
    const ninesixty = dimensions?.width <= 960 || false;
    const thousandthirty = dimensions?.width <= 1030 || false;
    const thousandfivty = dimensions?.width <= 1260 || false;
    const thousandSixty = dimensions?.width <= 1650 || false;
    const thousandEighty = dimensions?.width <= 1850 || false;
    const twothousand = dimensions?.width <= 2000 || false;
    const twothousandtwo = dimensions?.width <= 2200 || false;
    const twothousandmore = dimensions?.width >= 2200 || false;
    const numberOfTabShowing : number = fourhundred ? 3 : fiveSeventy ? 4 : sevenSeventy ? 5 : ninesixty ? 6 : thousandthirty ? 3 : thousandfivty && Entity?.type !== 'Person' ? 4 : thousandSixty && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : thousandEighty && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : Entity?.type == 'Neighborhood' && thousandEighty ? 5 : twothousand && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : twothousandtwo && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : twothousandmore ? 6: 5;
    const Start = fourhundred ? 3 : fiveSeventy ? 4 : sevenSeventy ? 5 : ninesixty ? 6 : thousandthirty ? 3 : thousandfivty && Entity?.type !== 'Person' ? 4 : thousandSixty && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : thousandEighty && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : Entity?.type == 'Neighborhood' && thousandEighty ? 5 : twothousand && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : twothousandtwo && Entity?.type !== 'Neighborhood' && Entity?.type !== 'Person' ? 6 : twothousandmore ? 6 : 5;
    const End = MoreMenu?.length
   /* console.log( "tabheaeder" );*/
  function useOutsideAlerter (ref) {
      React.useEffect(() => {
          let isMounted = true;
          if (isMounted) {
              function handleClickOutside(event) {
                  if (ref.current && !ref.current.contains(event.target)) {
                      setIsDropDown(false);
                  }
              }
              document.addEventListener('mousedown', handleClickOutside);
              return () => {
                  document.removeEventListener('mousedown', handleClickOutside);
              };
          }
          return () => {
              isMounted = false; // Set the flag to false when the component is unmounted
              // Cancel subscriptions or perform any cleanup tasks here
          };
    }, [ ref ]);
  }
  useOutsideAlerter(menuRefs);
  return (
    menu?.length > 0 ?
    <HStack width='100%'>
      <HStack width={ MoreMenu?.slice(Start, End)?.length == 0 ? '100%' : '80%' } paddingLeft={ '19.5px' } overflow={ 'hidden' }>
        { menu?.slice(0, numberOfTabShowing)?.map((item, index) => (
          /*TABMENU*/
          <Pressable
            key={ item?.id + index }
            onPress={ item.fn }
            borderBottomWidth={ item.flag ? 4 : 0 }
            borderBottomColor='#32AE78'
            marginRight={ '36px' }
            alignItems={ 'center' }
            justifyContent={ 'center' }
            _hover={ {
              borderBottomWidth: 4,
            } }
          >
            <Text
              fontSize={ '18px' }
              fontWeight={ '500' }
              fontFamily={ 'Roboto' }
              color={ item.flag ? '#000000' : 'rgba(0,0,0,0.5)' }
            >
              { item.name }
            </Text>
          </Pressable>
        )) }
      </HStack>
              { ( sevenSeventy && type === 'Event' || type !== 'Event' || thousandfivty ) && (
        /*MOREMENU*/
        <VStack width={ MoreMenu?.slice(Start, End)?.length == 0 ? '' : '20%' } alignItems={ 'center' } justifyContent={ 'center' }>
           {MoreMenu?.slice(Start, End)?.length > 0 && (
            <Pressable
            height={ isDropDown ? '40px' : '' }
            width={ isDropDown && !fiveSeventy ? '113px' : fiveSeventy && isDropDown ? '80px' : '' }
            background={ isDropDown ? 'rgba(0, 0, 0, 0.05)' : '' }
            borderRadius={ isDropDown ? '19px' : '' }
            alignItems={ 'center' }
            justifyContent={ 'center' }
            onPress={ () => setIsDropDown(!isDropDown) }
          >
            <Text
              fontSize={ '18px' }
              fontWeight={ '500' }
              fontFamily={ 'Roboto' }
              color={ 'rgba(0,0,0,0.5)' }
            >
                                  {Loc.currentLang.menu.more }
            </Text>
          </Pressable>) }
          { isDropDown && (<VStack
            ref={ menuRefs }
            width={ '128px' }
            height='auto'
            shadow={ 2 }
            background={ '#FFFFFF' }
            position={ 'absolute' }
            top={ '57px' }
           right={ fiveSeventy ? 0 : '' }
            zIndex={ 999 }
            borderRadius={ '6px' }
          >
            {MoreMenu?.slice(Start, End)?.map((item, index) => (
                              <React.Fragment key={item?.id + index}>
                                  <Pressable
                                      onPress={item?.fn}
                                      width={'100%'}
                                      height={'36px'}
                                      alignItems={'flex-start'}
                                      paddingX={'10px'}
                                      _hover={{
                                          backgroundColor: 'gray.100'
                                      }}
                                      justifyContent={'center'}
                                  >
                                      <Text
                                          fontSize={'14px'}
                                          fontFamily={'Roboto'}
                                      >
                                          {item?.name}
                                      </Text>
                                  </Pressable>
                                  <Divider key={`divider-${item?.id + index}`} />
                              </React.Fragment>
         ))}
          </VStack>) }
        </VStack>
      ) }
      </HStack> :
      <HStack>
        </HStack>
  );
};

export default React.memo(TabHeader);
