/*!
 * Copyright © 2024 Actavivo
 */
import { AVBase } from "./AVBase";
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { Feed } from "./Feed";

export class Convo extends Feed {
    static _api = "/api/Convo";

    public constructor (public data : AV.IConversation) {
        super(data);

    }
    //public static async list (param : AV.IFK) : Promise<AV.IConversation[]> {

    //    let list : AV.IConversation[] = await Server.post<AV.IConversation[]>(Convo._api + "/Posts", param);
    //    return list;
    //}
    //public static async get(param: AV.IFeedParam): Promise<AV.IConversation> {
    //    //if (isNaN(param?.startDate?.getDate()))
    //    //    param.startDate = new Date(1970,1,1);
    //    var p : AV.IConversation = await Server.post<AV.IConversation>(Convo._api + "/Get", param);
    //    return p;
    //}
    public static async save (val : AV.IConversation) : Promise<AV.IConversation> {
        var p : AV.IConversation = await Server.post<AV.IConversation>(Convo._api, val);
        return p;
    }
    public static async leave (val : AV.IConversation) {
        await Server.post(Convo._api + "/Leave", AVBase.getFk(val));
    }
    public static async hide(val: AV.IConversation) {
        await Server.post(Convo._api + "/Hide", AVBase.getFk(val));
    }
  public static async addPeople (conv : AV.IConversation, people : AV.ILink[]) : Promise<AV.IConversation> {
    var p : AV.IConversation = await Server.post(Convo._api + "/Add", { Source: AVBase.getFk(conv), Target: people });
    return p;
    }
    public static async update(conv: AV.IConversation): Promise<AV.IConversation> {
        var p: AV.IConversation = await Server.post(Convo._api + "/Update", conv);
        return p;
    }
  public static async removePeople (conv : AV.IConversation, people : AV.ILink[]) : Promise<AV.IConversation> {
    var p : AV.IConversation = await Server.post(Convo._api + "/Remove", { Source: AVBase.getFk(conv), Target: people });
    return p;
  }
    public static createConvoPost (convo : AV.IConversation) : AV.IPost {
        let post : AV.IPost = { pk: convo.pk, region: convo.region };
        return post;
    }
}