import * as React from 'react';

const Message = ({ color }) => (
  <>
    <path
      d='M14.85,6.75 L6.75,6.75 C6.30264935,6.75 5.94,7.11264935 5.94,7.56 C5.94,8.00735065 6.30264935,8.37 6.75,8.37 L14.85,8.37 C15.2973506,8.37 15.66,8.00735065 15.66,7.56 C15.66,7.11264935 15.2973506,6.75 14.85,6.75 Z M14.85,9.99 L6.75,9.99 C6.30264935,9.99 5.94,10.3526494 5.94,10.8 C5.94,11.2473506 6.30264935,11.61 6.75,11.61 L14.85,11.61 C15.2973506,11.61 15.66,11.2473506 15.66,10.8 C15.66,10.3526494 15.2973506,9.99 14.85,9.99 Z M16.47,2.7 L5.13,2.7 C3.78794806,2.7 2.7,3.78794806 2.7,5.13 L2.7,13.23 C2.7,14.5720519 3.78794806,15.66 5.13,15.66 L14.5179,15.66 L17.5149,18.6651 C17.6677623,18.8167211 17.8746996,18.9012448 18.09,18.9 C18.196253,18.9027379 18.3016697,18.8805449 18.3978,18.8352 C18.7002747,18.7109431 18.8983677,18.4169985 18.9,18.09 L18.9,5.13 C18.9,3.78794806 17.8120519,2.7 16.47,2.7 Z M17.28,16.1379 L15.4251,14.2749 C15.2722377,14.1232789 15.0653004,14.0387552 14.85,14.04 L5.13,14.04 C4.68264935,14.04 4.32,13.6773506 4.32,13.23 L4.32,5.13 C4.32,4.68264935 4.68264935,4.32 5.13,4.32 L16.47,4.32 C16.9173506,4.32 17.28,4.68264935 17.28,5.13 L17.28,16.1379 Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Message;
