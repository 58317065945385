import { Box, HStack, Image, Text, VStack, Tooltip } from 'native-base';
import React from 'react';
import Moment from 'moment';
import { Pressable } from 'native-base';
import { useNavigate } from 'react-router-dom';
import * as AV from '../../AVCore/AVCore';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CalenderCardIcon, LocationCardIcon, TimerCardIcon, Event, CreateTeam, TeamProfile, TeamBackground, EliteClub, Community, Neighbor, Organization, GradClass, Class, School, Group, Club, Profile } from '../../assets/icons/icon-assets';
export default function RenderEvents ({ data }) {

    const navigate = useNavigate();

    const currentUser = useSelector((state : RootStateOrAny) => state?.user?.user[ 0 ]);

    const entitydatalist = useSelector((state : RootStateOrAny) => state.user.entitydatalist);

    const RenderAvathar = ({ height, width, color, item }) => {
        return (<>
            { item?.type == 'Team' && item.subType !== 'Club' && <TeamProfile height={ height } width={ width } color={ color } /> }
            { item.type === 'LeagueTeam' && item.subType !== 'Class' && (<TeamProfile height={ height } width={ width } color={ color } />) }
            { item.type === 'Club' && (<EliteClub height={ height } width={ width } color={ color } />) }
            { item.subType === 'Club' && (<Club height={ height } width={ width } color={ color } />) }
            { item.type === 'School' && (<School height={ height } width={ width } color={ color } />) }
            { item.type === 'Group' && item.subType !== 'GradClass' && item.subType !== 'Class' && (<Group height={ height } width={ width } color={ color } />) }
            { item.subType === 'Class' && (<Class height={ height } width={ width } color={ color } />) }
            { item.subType === 'GradClass' && (<GradClass height={ height } width={ width } color={ color } />) }
            { item.type === 'Organization' && (<Organization height={ height } width={ width } color={ color } />) }
            { item.type === 'Neighborhood' && (<Neighbor height={ height } width={ width } color={ color } />) }
            { item.type === 'Community' && (<Community height={ height } width={ width } color={ color } />) }
            { item.type === 'Person' && (<Profile height={ height } width={ width } color={ color } />) }
        </>)
    }

    return (
        <>
            { data?.length > 0 &&
                data?.map((item, i) => {
                    let cacheItem : any = entitydatalist?.find(x => x?.id === item?.id);
                    if (cacheItem) {
                        item = AV.AVEntity.getLink(cacheItem);
                    }
                    let status = currentUser?.events?.filter(x => x?.id === item?.id);
                    if (status?.length > 0) item = status[ 0 ];
                    else if (currentUser?.ownedEvents?.filter(x => x?.id === item?.id)?.length > 0)
                        status = currentUser?.ownedEvents?.filter(x => x?.id === item?.id);
                    let profileImg = item?.thumb ? item?.thumb : item?.profile?.pic?.id
                    let backgroundImg = item?.background ? item.background : item?.profile?.background?.id

                    let profileImage = ""; let backgroundImage = ""; let thumb : any = AV.AVEntity.getLink(item)?.thumb;
                    if (item?.thumb)
                        profileImage = AV.Media.getThumb(item);
                    else if (item?.profile?.pic?.id)
                        profileImage = AV.Media.getThumb(AV.AVEntity.getLink(item));
                    if (item?.background)
                        backgroundImage = AV.Media.getBackground(item);
                    else if (item?.profile?.background?.id)
                        backgroundImage = AV.Media.getBackground(AV.AVEntity.getLink(item));

                    return (
                        <Pressable
                            width={ '235px' }
                            margin={ '12px' }
                            bgColor={ item?.type == 'Event' ? 'rgba(0,167,188,0.11)' : 'gray.100' }
                            borderRadius={ item?.type == 'Event' ? 6 : 10 }
                            borderColor={ 'AVColor.bgcolor' }
                            shadow={ 1 }
                            overflow={ 'hidden' }
                            _hover={ {
                                shadow: 3,
                            } }
                            onPress={ () => {
                                item?.type == 'Event'
                                    ? navigate(`/Events/${ item?.id }/${ item?.region }`, { state: { team: item } })
                                    : item?.type == 'LeagueTeam'
                                        ? navigate(`/season/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } }) :
                                        item?.type == 'Team' ?
                                            navigate(`/teams/${ item?.id }/${ item?.region }`, { state: { isCreate: false, team: item } }) :
                                            item?.id == '78b5b063-fd3e-4925-92bd-2a1888c6a46a' ?
                                                navigate('/teams/78b5b063-fd3e-4925-92bd-2a1888c6a46a/' + currentUser?.region, {
                                                    state: {
                                                        team: {
                                                            id: '78b5b063-fd3e-4925-92bd-2a1888c6a46a',
                                                            pk: 'a46a',
                                                            region: currentUser?.region,
                                                            type: 'Organization',
                                                            name: 'Actavivo',
                                                        },
                                                        type: 'Feedback'
                                                    },
                                                }) :
                                                (item?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa72' || item?.id == 'b495d4e0-bf52-4690-8dc0-677db954aa71' || item?.id === 'b495d4e0-bf52-4690-8dc0-677db954aa7f') ?
                                                    navigate(`/teams/${ item?.id }/${ item?.region }`, {
                                                        state: { isCreate: false, team: item, type: 'Feedback' },
                                                    }) :
                                                    item?.type == 'Person' ?
                                                        navigate(`/profile/${ item.id }/${ item.region }`, {
                                                            state: {
                                                                user: {
                                                                    id: item?.id,
                                                                    pk: item?.pk,
                                                                    region: item?.region,
                                                                    type: item?.type,
                                                                    name: item?.title,
                                                                },
                                                            },
                                                        }) :
                                                        navigate(`/organizations/${ item?.id }/${ item?.region }/${ item?.type }`, {
                                                            state: { isCreate: false, orgType: item?.type, team: item },
                                                        })
                            } }
                            key={ i }
                        >
                            <Box
                                width={ '100%' }
                                height={ item?.type == 'Event' ? '69px' : '140px' }
                                bgColor={ item?.type == 'Event' ? 'rgba(0,167,188,0.26)' : '' }
                                justifyContent={ 'center' }
                                alignItems={ 'center' }
                            >
                                { (backgroundImg) && <Image
                                    source={ {
                                        uri: backgroundImage,
                                    } }
                                    width={ '100%' }
                                    height={ '100%' }
                                    position={ 'absolute' }
                                    zIndex={ -3 }
                                    alt={ 'Background Image' }
                                />
                                }
                                { (!backgroundImg && item?.type !== 'Event') &&
                                    <TeamBackground />
                                }
                                { profileImg ? (
                                    <Image
                                        source={ {
                                            uri: profileImage,
                                        } }
                                        width={ item?.type == 'Event' ? '54px' : '84px' }
                                        height={ item?.type == 'Event' ? '54px' : '84px' }
                                        position={ item?.type == 'Event' ? undefined : 'absolute' }
                                        borderRadius={ '50%' }
                                        alt={ 'Profile Image' }
                                    />
                                ) : (
                                    <>
                                        { item?.type == 'Event' ? <Event height={ '50' } width={ '50' } color={ "#32AE78" } /> : <Box position={ 'absolute' }><RenderAvathar item={ item } height={ '84' } width={ '84' } color={ "#32AE78" } /></Box> }
                                    </>
                                ) }
                            </Box>
                            <VStack px={ 2 } py={ 1.5 } justifyContent={ 'center' } space={ 1 }>
                                <HStack justifyContent={ 'center' }>
                                    <Tooltip label={ item?.title ? item?.title : item?.name } openDelay={ 500 }>
                                        <Pressable
                                            onPress={ () => {
                                                navigate(`/Events/${ item?.id }/${ item?.region }`, { state: { team: item } });
                                            } }

                                        >
                                            <Text fontSize={ '18px' } width={ '200px' } color={ 'AVColor.primaryText' } numberOfLines={ 1 } text-overflow={ 'ellipsis' } textAlign={ 'center' } fontWeight={ 500 } >
                                                { item?.title ? item?.title : item?.name }
                                            </Text>
                                            { (item?.type == 'LeagueTeam' && item.subType !== 'Class') &&
                                                (<Text fontSize={ '18px' } width={ '200px' } color={ 'AVColor.primaryText' } numberOfLines={ 1 } text-overflow={ 'ellipsis' } textAlign={ 'center' } fontWeight={ 500 } >
                                                    { item?.data ? item?.data : item?.season ? item?.season : item?.title }
                                                </Text>) }
                                        </Pressable>
                                    </Tooltip>
                                </HStack>
                                { item?.type == 'Event' && (<VStack >
                                    <HStack alignItems={ 'center' } space={ '15px' } marginTop={ '5px' }>
                                        <CalenderCardIcon height={ '24' } width={ '24' } color={ 'black' } />
                                        <Text color={ 'black' } fontSize={ '14px' }>
                                            { Moment(item?.start).format('MM/DD/YYYY') }

                                        </Text>
                                    </HStack>
                                    <HStack alignItems={ 'center' } space={ '15px' } >
                                        <TimerCardIcon height={ '24' } width={ '24' } color={ 'black' } />
                                        <Text color={ 'black' } fontSize={ '14px' }>
                                            { Moment(item?.start).format('LT') } -{ ' ' }
                                            { Moment(item?.end).format('LT') }
                                        </Text>
                                    </HStack>
                                    <HStack alignItems={ 'center' } space={ '10px' } >
                                        <HStack width={ '30px' }>
                                            <LocationCardIcon height={ '24' } width={ '24' } color={ 'black' } />
                                        </HStack>
                                        <Text numberOfLines={ 1 } fontSize={ '14px' } color={ 'black' }>
                                            { item?.location?.title ? item?.location?.title : item?.locURL ? item?.locURL : item?.url }
                                        </Text>
                                    </HStack>
                                </VStack>) }
                            </VStack>
                            { item?.type == 'Event' && (<HStack py={ 1 } alignItems={ 'center' } justifyContent={ 'center' } backgroundColor={ 'rgba(0,167,188,1)' } height={ '25px' }>
                                <Text fontSize='14px' color={ 'rgba(255,255,255,1)' } fontWeight={ 500 } textAlign='center'>
                                    { item?.status ? item?.status : item?.status == "New" ? 'RSVP' : 'RSVP' }
                                </Text>
                            </HStack>) }
                        </Pressable>
                    );
                }) }
        </>
    );
}
