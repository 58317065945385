import * as React from 'react';

const Attach = ({ color }) => (
  <>
    <path
      d='M17.3752196,12.3409322 L12.0780767,17.6466465 C10.6364453,18.9278343 8.44605318,18.8633552 7.08228206,17.4995841 C5.71851095,16.1358129 5.65403182,13.9454208 6.9352196,12.5037894 L13.7923625,5.64664653 C14.64257,4.83902407 15.9764406,4.83902407 16.8266482,5.64664653 C17.6566606,6.48779104 17.6566606,7.83978773 16.8266482,8.68093224 L10.9123625,14.5866465 C10.7531241,14.758134 10.5144943,14.8316423 10.2863626,14.7794816 C10.0582309,14.7273208 9.87525589,14.5574155 9.80636256,14.3337673 C9.73746923,14.1101191 9.79312408,13.8667054 9.95236246,13.695218 L14.3495053,9.30664653 C14.68561,8.97054181 14.68561,8.42560839 14.3495053,8.08950368 C14.0134006,7.75339896 13.4684672,7.75339896 13.1323625,8.08950367 L8.7352196,12.495218 C8.29081653,12.9361745 8.04086171,13.5363123 8.04086171,14.1623608 C8.04086171,14.7884093 8.29081653,15.3885471 8.7352196,15.8295037 C9.66877605,16.7187554 11.1359489,16.7187554 12.0695053,15.8295037 L17.9752196,9.91521795 C19.3913509,8.3954535 19.3495652,6.02716796 17.8807031,4.55830585 C16.411841,3.08944374 14.0435555,3.0476581 12.523791,4.46378938 L5.66664817,11.3209322 C3.74558745,13.4486208 3.83988873,16.7115426 5.88062442,18.7247318 C7.92136011,20.737921 11.1852621,20.7878725 13.2866482,18.8380751 L18.5923625,13.5409322 C18.8097841,13.3235106 18.8946969,13.0066117 18.8151151,12.7096082 C18.7355332,12.4126047 18.5035471,12.1806186 18.2065436,12.1010368 C17.9095402,12.0214549 17.5926412,12.1063677 17.3752196,12.3237894 L17.3752196,12.3409322 Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Attach;
