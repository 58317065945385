//@ts-nocheck
import
    {
        HStack,
        Pressable,
        Text,
        VStack,
        CloseIcon,
    } from 'native-base';
import React, { useState } from 'react';
import './style.css';
import * as AV from 'AVCore/AVCore';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../Redux/Actions/userActions';
import Folder from '../../assets/images/Folder.png';
import EmptyImg from '../../assets/images/EmptyImg.png';
import AVConfirmationModal from '../../components/common/AVConfirmationModal';
function AlbumList ( {
    item,
    isPhotoPage,
    setalbumlist,
    setfilesList,
    setisEdit,
    isEdit,
    setalbum,
    setalbumdata,
    setalbumdatamedia,
    menuRefs
} )
{
    const dispatch = useDispatch();
    const currentUser = useSelector( ( state ) => state.user.user[ 0 ] );
    const [ isConfirmDelete, setisConfirmDelete ] = useState( false );
    const [ Confirmationtext, setConfirmationtext ] = React.useState( "Are you sure ?" );
    const [ itemSelect, setItemSelect ] = React.useState( '' );
    const [ selectType, setselectType ] = React.useState<any>();
    const [ isLoader, setLoader ] = useState( false );

    const handleclickimg = async ( item ) =>
    {
        await handleAlbumDataAndMedia( item );
    };

    const HandleRemoveAlbum = ( item ) =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( 'Are You Sure? You Want to Delete Your Album' );
        setItemSelect( item );
        setselectType( 'DeleteAlbum' )
    }

    const handleRemove = async ( itemSelect ) =>
    {
        setLoader( true )
        const val = await getMediaList( item );
        const remove = await AV.Media.deleteList( val );
        if ( remove )
        {
            if ( remove?.id == currentUser?.id )
            {
                dispatch( setUser( remove ) );
            }
            setalbumlist( remove?.albums );
            setfilesList( remove?.files );
        }
        setLoader( false )
        setisConfirmDelete( false )
    };

    // Helper function to get the media list
    const getMediaList = async ( item ) =>
    {
        const val = await AV.Media.getList( AV.AVEntity.getFk( item ) );
        return val;
    };

    const handleAlbumDataAndMedia = async ( item ) =>
    {
        const val = await getMediaList( item );
        if ( val )
        {
            setalbumdata( val );
            setalbumdatamedia( val.media?.filter( ( x ) => x?.deleted !== true ) );
        }
        setalbum( true );
        setisEdit( false );
    };

    React.useEffect( () =>
    {
        let StoreId = item?.storeId ? item?.storeId : item?.media?.filter( ( x : any ) => x?.deleted !== true )[ 0 ]?.storeId ? item?.media?.filter( ( x : any ) => x?.deleted !== true )[ 0 ]?.storeId : undefined;

        setimagesrc( item?.thumb
            ? AV.Media.getURL( {
                id: item?.thumb,
                pk: item?.pk,
                region: item?.region,
                storeId: StoreId,
            } )
            : item?.media?.filter( ( x ) => x?.deleted !== true )?.length > 0
                ? AV.Media.getURL( item?.media?.filter( ( x ) => x?.deleted !== true )[ 0 ] )
                : EmptyImg );
    }, [ item ]);

    let StoreId = item?.storeId ? item?.storeId : item?.media?.filter( ( x : any ) => x?.deleted !== true )[ 0 ]?.storeId ? item?.media?.filter( ( x : any ) => x?.deleted !== true )[ 0 ]?.storeId : undefined;

    const [ imagesrc, setimagesrc ] = React.useState( item?.thumb
        ? AV.Media.getURL( {
            id: item?.thumb,
            pk: item?.pk,
            region: item?.region,
            storeId: StoreId,
        } )
        : item?.media?.filter( ( x ) => x?.deleted !== true )?.length > 0
            ? AV.Media.getURL( item?.media?.filter( ( x ) => x?.deleted !== true )[ 0 ] )
            : EmptyImg );

    return (
        <>
            <VStack
                key={ item.id }
                bgColor='#fff'
                marginLeft={ '6px' }
                marginBottom={ 5 }
                overflow='hidden'
                width={ 150 }
                height={ 160 }
                rounded='lg'
                zIndex={ -2 }
            >
                { isPhotoPage ? (
                    <Pressable
                        marginTop={ '20px' }
                        onPress={ () =>
                        {
                            handleclickimg( item );
                        } }
                    >
                        { item?.thumb || item?.media?.filter( ( x ) => x?.deleted !== true )?.length > 0 ? (
                            <img
                                src={ imagesrc }
                                alt={ item?.Media?.length > 0 ? item?.media[ 0 ]?.orgFile : 's' }
                                onError={ () =>
                                {
                                    setimagesrc(
                                        item?.thumb
                                            ? AV.Media.getVideoPicURL( {
                                                id: item?.thumb,
                                                pk: '',
                                                region: undefined,
                                                storeId: undefined,
                                            } )
                                            : item?.media?.length > 0
                                                ? AV.Media.getVideoPicURL( item?.media[ 0 ] )
                                                : null
                                    )
                                } }
                                style={ {
                                    height: '112px',
                                    width: '140px',
                                    marginLeft: '-1px',
                                    borderRadius: '6px',
                                    objectFit: 'cover',
                                } }
                            />
                        ) : (
                            <img
                                src={ EmptyImg }
                                alt=''
                                style={ {
                                    height: '112px',
                                    width: '140px',
                                    marginLeft: '-1px',
                                    borderRadius: '6px',
                                    objectFit: 'cover',
                                } }
                            />
                        ) }
                        <HStack space={ 3 }>
                            <Text
                                hight='35px'
                                width={ '100px' }
                                ellipsizeMode='tail'
                                numberOfLines={ 1 }
                                paddingBottom={ '2px' }
                                fontFamily={ 'roboto' }
                                fontWeight={ [ 'bold' ] }
                                paddingLeft={ '3px' }
                                fontSize={ 16 }
                            >
                                { item?.title ? item?.title : item?.name }
                            </Text>
                            <Text
                                hight='35px'
                                ellipsizeMode='tail'
                                numberOfLines={ 1 }
                                paddingBottom={ '2px' }
                                fontFamily={ 'roboto' }
                                fontWeight={ [ 'bold' ] }
                                paddingLeft={ '3px' }
                                fontSize={ 16 }
                            >
                                { item?.data ? JSON.parse( item?.data )?.Count : item?.count ? item?.count : '0' }
                            </Text>
                        </HStack>
                    </Pressable>
                ) : (
                    <VStack flex={ 1 } justifyContent={ 'center' }>
                        <Pressable
                            onPress={ () =>
                            {
                                handleclickimg( item );
                            } }
                        >
                            <img
                                src={ Folder }
                                alt=''
                                style={ { height: '140px', width: '120px', marginLeft: '10px', borderRadius: '6px' } }
                            />
                            <Text
                                hight='35px'
                                ellipsizeMode='tail'
                                numberOfLines={ 1 }
                                paddingBottom={ '2px' }
                                paddingLeft={ '18px' }
                                fontSize={ 16 }
                            >
                                { item?.title ? item?.title : item?.name }
                            </Text>
                        </Pressable>
                    </VStack>
                ) }
                { isEdit && (
                    <Pressable
                        padding={ 1.5 }
                        rounded='full'
                        position='absolute'
                        right={ 0 }
                        top={ '10px' }
                        zIndex={ 999 }
                        _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                        backgroundColor='AVColor.buttonBg'
                        onPress={ () => HandleRemoveAlbum( item ) }

                    >
                        <CloseIcon size='12px' color='AVColor.secondaryText' />
                    </Pressable>
                ) }
            </VStack>
            { isConfirmDelete && <AVConfirmationModal showconfirmationPopup={ isConfirmDelete } setshowconfirmationPopup={ setisConfirmDelete }
                Confirmationtext={ Confirmationtext } type={ selectType } item={ itemSelect } function={ handleRemove } isLoader={ isLoader } /> }
        </>
    )
}
export default AlbumList;
