
const initialState = {

    convo: []
};

const convoReducer = (
    state = initialState,
    action : any
) => {
    switch (action.type) {
        case "SET_CONVO_DATA":
        return { ...state, convo: [ action.payload ] };
      case "SET_CONVO_STATE":
        return initialState;
        default:
            return state
    }
}

export default convoReducer;