/*!
 * Copyright © 2023 Avtavivo
 */
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { AVEntity } from "./AVEntity";
export class Location extends AVEntity {
    
    static _api = "/api/Location";
    
    public static async getLocation(placeId:string,name:string) :Promise<AV.ILocation>{
        
        return await Server.get<AV.ILocation>(this._api + "/GetLocation", {placeId:placeId,name:name});
                
    }
    public static async addField(source: AV.IFK, target: AV.IField): Promise<AV.ILocation> {
        return await Server.post(this._api + "/AddField", { source: source, target: target });
    }
    public static async removeField(source: AV.IFK, target: AV.IField): Promise<AV.ILocation> {
        return await Server.post(this._api + "/RemoveField", { source: source, target: target });
    }
    public static async updateField(source: AV.IFK, target: AV.IField): Promise<AV.ILocation> {
        return await Server.post(this._api + "/UpdateField", { source: source, target: target });
    } 
    public static async updateSiteMap(source: AV.IFK, item: AV.IMedia): Promise<AV.ILocation> {
        return await Server.post(this._api + "/UpdateSiteMap", { source: source, item: item });
    } 
    public static async deleteSiteMap(source: AV.IFK): Promise<AV.ILocation> {
        return await Server.post(this._api + "/DeleteSiteMap", source);
    } 
    public static async updateMap ( source : AV.IFK ) : Promise<AV.ILocation>
    {
        return await Server.post( this._api + "/UpdateMap", source );
    } 

    public static async searchLocation(name: string): Promise<AV.ILocationSearch[]> {
        return await Server.get<AV.ILocationSearch[]>(this._api + "/searchLocation", { name: name });
    } 
    public static getLink(loc: AV.ILocation,field?: AV.IField): AV.ILocationLink {

        let retval : AV.ILocationLink = AVEntity.getLink(loc);

        retval.thumb = loc.address?.map?.id;
        retval.geoData = loc.address?.geoData;
        retval.normalized = loc.address?.normalized;
        retval.placeId = loc.address?.placeId;
        retval.field = field;
        return retval;

    }
}
