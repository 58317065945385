/*!
 * Copyright © 2024 Actavivo
 */
import * as AV from "./Interfaces/AV.Interfaces";
import { Server } from "../Platform/Server";
import { AVEntity } from "./AVEntity";

export class OrgBase extends AVEntity {
    static _orgBaseApi = "/api/OrgBase";


    public static async addTeam(source: AV.IFK, target: AV.ILink): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/AddChild", { source: source, target: target });
    }
    public static async removeTeam(source: AV.IFK, target: AV.ILink): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/RemoveChild", { source: source, target: target });
    } 
    public static async addStaff(source: AV.IFK, target: AV.INewStaff): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/AddStaff", { source: source, target: [target] });
    }
    public static async addStaffs(source: AV.IFK, target: AV.INewStaff[]): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/AddStaff", { source: source, target: target });
    }
    public static async removeStaff(source: AV.IFK, target: AV.IStaff): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/RemoveStaff", { source: source, target: target });
    }    
   
    public static async updateStaff(source: AV.IFK, target: AV.IStaff): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/UpdateStaff", { source: source, target: target });
    } 
    public static async addRole(source: AV.IFK, target: AV.IRole): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/AddRole", { source: source, target: target });
    }
    public static async removeRole(source: AV.IFK, target: AV.IRole): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/RemoveRole", { source: source, target: target });
    }
    public static async updateRole(source: AV.IFK, target: AV.IRole): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/UpdateRole", { source: source, target: target });
    } 
    public static async seachByPlace(loc: AV.ILocation): Promise<AV.IOrgBase[]> {
        return await Server.post(this._orgBaseApi + "/SearchByPlace", loc);
    } 
    public static async addFacility(source: AV.IFK, target: AV.ILink): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/AddFacility", { source: source, target: target });
    }
    public static async removeFacility(source: AV.IFK, target: AV.ILink): Promise<OrgBase> {
        return await Server.post(this._orgBaseApi + "/RemoveFacility", { source: source, target: target });
    }
    public static async addSchool(source: AV.IFK, target: AV.ITypeLink): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/AddSchool", { source: source, target: target });
    }
    public static async removeSchool(source: AV.IFK, target: AV.ILink): Promise<AV.IOrgBase> {
        return await Server.post(this._orgBaseApi + "/RemoveSchool", { source: source, target: target });
    }

}