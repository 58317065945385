import * as React from 'react';

const Coaching = ({ color }) => (
  <>
    <path
      d='m16.46 1.164-9.665 5.58a.7.7 0 0 0-.18.105 7.835 7.835 0 0 0-2.193 10.665 7.626 7.626 0 0 0 2.89 2.627 1.802 1.802 0 1 0 3.475.958 7.634 7.634 0 0 0 3.937-.89l.206-.119a7.812 7.812 0 0 0 2.86-10.672 7.686 7.686 0 0 0-1.066-1.43L19.5 5.656a2.232 2.232 0 0 0 .482-2.855l-.472-.819a2.232 2.232 0 0 0-3.05-.817Zm1.83 2.867a.7.7 0 0 1-.152.896l-3.225 2.67a.7.7 0 0 0-.005 1.068 5.94 5.94 0 0 1-1.03 9.772 6.037 6.037 0 0 1-7.84-2.05 5.967 5.967 0 0 1 1.755-8.153l.103-.06 8.985-5.187a.7.7 0 0 1 .955.256l.455.788Z M8.96 9.77a4.16 4.16 0 1 0 5.683 1.522 4.168 4.168 0 0 0-5.684-1.523Zm3.201 5.474a2.306 2.306 0 1 1-2.306-3.994 2.306 2.306 0 0 1 2.306 3.994Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Coaching;
