/*!
 * Copyright © 2023 Actavivo
 */
import * as AV from './Interfaces/AV.Interfaces';
import { Server } from '../Platform/Server';
import { AVBase } from './AVBase';
import { Person } from './Person';
import { Link } from './Link';
import { ILink } from './Interfaces/Link';

export class AVEntity extends AVBase {
    static _entityApi = '/api/Entity';
    static _personApi = '/api/Person';
    static _baseApi = '/api/Base';

    public static getFk(obj: AV.IAVEntity): AV.IFK {
        if (obj) return { pk: obj.pk, id: obj.id, region: obj.region, type: obj.type };
        return {};
    }
    public static makeSmall(val: AV.IAVEntity): AV.IAVEntity{
        let newval: AV.IAVEntity = JSON.parse(JSON.stringify(val));
        newval.autoFollows = undefined;
        newval.follows = undefined;
        newval.convos = undefined;
        newval.myRequests = undefined;
        newval.albums = undefined;
        newval.files = undefined;
        newval.members = undefined;
        newval.orgs = undefined;
        newval.ownedEvents = undefined;

        //@ts-ignore
        if (val.links == "")
            newval.links = undefined;

        //Orgbase
        (<AV.IOrgBase>newval).staff = undefined;
        (<AV.IOrgBase>newval).childOrgs = undefined;
        //LeageTeam
        (<AV.ILeagueTeam>newval).players = undefined;
        (<AV.ILeagueTeam>newval).playersNeeded = undefined;
        //Event
        (<AV.IEvent>newval).participants = undefined;
      (<AV.IEvent> newval).resources = undefined;

      //Person
      (<AV.IPerson> newval).events = undefined;
      (<AV.IPerson> newval).wards = undefined;
      (<AV.IPerson> newval).guardians = undefined;
      (<AV.IPerson> newval).requests = undefined;
      (<AV.IPerson> newval).blockedBy = undefined;
      (<AV.IPerson> newval).fcmTokens = undefined;
      (<AV.IPerson> newval).blocked = undefined;
      (<AV.IPerson> newval).favs = undefined;
      (<AV.IPerson> newval).reported = undefined;
      (<AV.IPerson> newval).silenced = undefined;
      return newval;

        return newval;
    }

    public static getLink(obj: AV.IAVEntity): AV.ILink {
        if (obj) {
            let link: AV.ILink =
            {
                pk: obj.pk,
                id: obj.id,
                region: obj.region,
                type: obj.type,
                subType: (obj as AV.IOrgBase).subType,
                thumb: obj.profile?.pic?.id,
                background: obj.profile?.background?.id,
                title: obj.name,
                blurb: obj.profile?.text,
                storeId : obj.profile?.pic?.storeId
            };
            if (obj.type == "LeagueTeam")
                link.data = (<AV.ILeagueTeam>obj).season;

            return link;
        }
        return {};
    }
    public static async fileContacts (fData : FormData , isPlayer:Boolean =false) : Promise<AV.IContact[]> {

        var retval : AV.IMedia[] = await Server.post<AV.IMedia[]>(this._entityApi + "/FileContacts" + "?isPlayer=" + isPlayer, fData , true);
        return retval;
    }

    public static getThumb(obj: AV.IAVEntity, defaultImg = ''): string {
        if (obj) return Link.getTummb(this.getLink(obj), defaultImg);
        return defaultImg;
    }

    public static async get<T>(fk: AV.IFK): Promise<T> {
        return await Server.post<T>(this._entityApi + '/Get', fk);
    }
   
    public static async localSearch(val: string): Promise<AV.ILink[]> {
        let self = await Person.getSelf();

        let list: ILink[] = [];
        if (self.follows)
            list = self.follows.filter((l) => l.title?.toLowerCase().includes(val.toLowerCase()));
        if (self.orgs)
            list = list.concat(
                self.orgs.filter((l) => (l.title?.toLowerCase().includes(val.toLowerCase())) || (l?.data?.toLowerCase().includes(val.toLowerCase())))
            );

        return list;
    }
    public static async locationSearch(val: string): Promise<AV.ILocationLink[]> {
        let self = await Person.getSelf();
        let list: AV.ILocationLink[] = [];
        if (self.events) {
            var events = self?.events?.filter((l) => l.location?.title?.toLowerCase().includes(val.toLowerCase()) || l.location?.normalized?.toLowerCase().includes(val.toLowerCase()));
            if (self?.ownedEvents)
                events = events.concat(self?.ownedEvents?.filter((l) => l.location?.title?.toLowerCase().includes(val.toLowerCase()) || l.location?.normalized?.toLowerCase().includes(val.toLowerCase())));
            events.forEach((ev) => {
                if (list.findIndex(l => l.id == ev.location?.id) == -1)
                    list.push(ev.location)
            });
        }
        return list;
    }

    public static async save<T extends AV.IAVEntity>(val: T): Promise<T> {
        return await Server.post<T>(this._entityApi, this.makeSmall(val), false, 'application/json');
    }
    public static async Unsubscribe (region : string, id : string, type = "Person") {
        var header : HeadersInit = {};
        header[ 'Authorization' ] = '';
        header[ 'Accept' ] = 'application/json';
        header[ 'Content-Type' ] = 'application/json';
      return await fetch(this._entityApi + "/Unsubscribe" +  "/" + region + "/" + id + "/" + type, {
            method: 'GET',
            body:  null,
            credentials: 'include',
            headers: header,
        })
    }
    public static async langList<T extends AV.IAVEntity> (val : T) : Promise<T> {
        return await Server.get<T>(this._baseApi + '/LangList');
    }
    public static async delete<T extends AV.IAVEntity>(val: T) {
        await Server.post<T>(this._entityApi + '/Delete', AVBase.getFk(val));
    }
    public static async unDelete<T extends AV.IAVEntity>(fk: AV.IFK) {
        await Server.post<T>(this._entityApi + '/UnDelete', fk);
    }
    public static async suspend<T extends AV.IAVEntity>(val: T) {
        await Server.post<T>(this._entityApi + '/Suspend', AVBase.getFk(val));
    }
    public static async unSuspend<T extends AV.IAVEntity>(fk: AV.IFK) {
        await Server.post<T>(this._entityApi + '/UnSuspend', fk);
    }
    public static async hideOrg<T extends AV.IAVEntity>(fk: AV.IFK) {
        await Server.post<T>(this._entityApi + '/HideOrg', fk);
    }
    public static async getMyFollowers<T extends AV.IAVEntity> (val : T) : Promise<AV.IFollowList> {
        return await Server.post(this._entityApi + '/Followers', AVBase.getFk(val));
    }
    public static async search(
        val: string,
        type: string = 'Person',
        region?: string
    ): Promise<AV.ILink[]> {
        try {
            return await Server.post<AV.ILink[]>(this._entityApi + '/Search', {
                Value: val,
                Type: type,
                region: region,
            });
        }
        catch (err) {
            var e = err;
        }
        return [];
    }

    public static async unFollow(source: AV.IFK, target: AV.IFK) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/UnFlollow', { Source: source, Target: target })
        );
    }
    public static async unFollowMe(source: AV.IFK, target: AV.IFK) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/UnFlollowMe', { Source: source, Target: target })
        );
    }
    public static async addFavorite(source: AV.IFK, target: AV.IFK) {
        await Server.post(this._personApi + '/AddFavorite', { Source: source, Target: target });
    }
    public static async removeFavorite(source: AV.IFK, target: AV.IFK) {
        await Server.post(this._personApi + '/RemoveFavorite', { Source: source, Target: target });
    }
    public static async block(source: AV.IFK, target: AV.ILink) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/Block', { Source: source, Target: target })
        );
    }
    public static async silence(source: AV.IFK, target: AV.ILink) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/Silence', { Source: source, Target: target })
        );
    }
    public static async UnSilence(source: AV.IFK, target: AV.ILink) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/UnSilence', { Source: source, Target: target })
        );
    }
    public static async createTokenRequest (tokenprocessReq : AV.ITokenProcessRequest) : Promise<AVEntity> {
        return await Server.post(this._entityApi + "/CreateTokenRequest", tokenprocessReq);
    }
    public static async getTokenRequest (token : string, type : string,) : Promise<AVEntity> {
        var header : HeadersInit = {};
        header[ 'Authorization' ] = '';
        header[ 'Accept' ] = 'application/json';
        header[ 'Content-Type' ] = 'application/json';
        return await fetch(this._entityApi + "/GetTokenRequest" + "/" + token + "/" + type, {
            method: 'GET',
            body: null,
            credentials: 'include',
            headers: header,
        })
    }
    public static async processToken (tokenprocessReq : AV.ITokenProcessRequest) : Promise<AVEntity> {
        return await Server.post(this._entityApi + "/ProcessToken", tokenprocessReq);
    }
  
    public static async addMembers(source: AV.IFK, target: AV.IContact[]): Promise<AVEntity> {
        return await Server.post(this._entityApi + "/AddMember", { source: source, target: target });
    }
    public static async updateLink(source: AV.IFK, target: AV.IFK): Promise<AV.IAVEntity> {
        return await Server.post(this._entityApi + "/UpdateLink", { source: source, target: target });
    }
    public static async addMember(source: AV.IFK, target: AV.IContact): Promise<AV.IAVEntity> {
        return await Server.post(this._entityApi + "/AddMember", { source: source, target: [target] });
    }
    public static async removeMember(source: AV.IFK, target: AV.IContact): Promise<AV.IAVEntity> {
        return await Server.post(this._entityApi + "/RemoveMember", { source: source, target: target });
    }    
    public static async updatePreferences (source : AV.IFK, Preferences : AV.IPreferences) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/UpdatePreferences', { Source: source, Preferences: Preferences })
        );
    }
    public static async unBlock(source: AV.IFK, target: AV.IFK) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/UnBlock', { Source: source, Target: target })
        );
    }

    public static async makeRequest(source: AV.IFK, target: AV.ITypeLink) {
        let response = await Server.post(this._entityApi + '/MakeRequest', {
            Source: source,
            Target: target,
        });

        return response;
    }
    public static async approveRequest(source: AV.IFK, target: AV.IRequest) {
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/ApproveRequest', { Source: source, Target: target })
        );
    }
    public static async denyRequest(source: AV.IFK, target: AV.IRequest) {
        if (!source)
            source = AVEntity.getFk(await Person.getSelf());
        Person.checkIfSelf(
            await Server.post(this._entityApi + '/DenyRequest', { Source: source, Target: target })
        );
    }
    public static checkLink(list: ILink[]|undefined, chkLink: ILink): boolean {

        if (!list)
            return false;

        for (var i = 0; i < list.length; i++) {
            if (list[ i ].id == chkLink.id && (
                (chkLink?.type === "LeagueTeam" && (list[ i ].thumb != chkLink.thumb || list[ i ].background != chkLink.background || list[ i ].title != chkLink.title || list[ i ].data != chkLink.data)) ||
                (chkLink?.type !== "LeagueTeam" && (list[ i ].thumb != chkLink.thumb || list[ i ].background != chkLink.background || list[ i ].title != chkLink.title))
            )) {
                return true;
            }
        }
        return false;
    }
    public static async checkLinks(fromEntity: AV.IAVEntity, checkEntity: AV.IAVEntity): Promise<AV.IAVEntity> {

        var link: ILink = this.getLink(checkEntity);

        if (this.checkLink(fromEntity.orgs, link))
            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
        if (this.checkLink(fromEntity.ownedEvents, link))
            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
        if (this.checkLink(fromEntity.members, link))
            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
        if (this.checkLink(fromEntity.blocked, link))
            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
       
        //for (var i = 0; fromEntity.convos &&  i < fromEntity.convos?.length; i++) {                    
        //        if (this.checkLink(fromEntity.convos[i].people, link))
        //            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
        //}

        if (fromEntity.type == "Person")
        {
            var person:AV.IPerson = fromEntity;

            if (this.checkLink(person.favs, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            if (this.checkLink(person.events, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            if (this.checkLink(person.follows, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            if (this.checkLink(person.guardians, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            if (this.checkLink(person.wards, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            
        }
      if (fromEntity.type == "School" || fromEntity.type == "Club" || fromEntity.type == "Organization" || fromEntity?.type == "Community" || fromEntity?.type == "Neighborhood" || fromEntity?.type =="Group")
        {
          var org : AV.IOrgBase = fromEntity;
          if (this.checkLink(org.staff, link))
            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
          if (this.checkLink(fromEntity.ownedEvents, link))
            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
          if (this.checkLink(org.childOrgs, link))
            return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));

        }
        if (fromEntity.type == "LeagueTeam")        
        {
            var season: AV.ILeagueTeam = <AV.ILeagueTeam>fromEntity;
            if (this.checkLink(season.players, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            if (this.checkLink(season.sponsors, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));

        }
        if (fromEntity.type == "Team")          
        {
            var team: any = fromEntity;
            if (this.checkLink(team.childOrgs, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            if (this.checkLink(team.sponsors, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
 
        }
        if (fromEntity.type == "Event")           
        {
            var evt: AV.IEvent = <AV.IEvent>fromEntity;
            if (this.checkLink(evt.participants, link))
                return await this.updateLink(this.getFk(fromEntity), this.getFk(checkEntity));
            
        }
        return fromEntity;
    }
}
