import React, { FC } from 'react';
import { Link } from '../../AVCore/Link';
import { Config } from '../../App/Config';
import * as AV from '../../AVCore/AVCore';
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import { Box } from 'native-base';

//class ProfilePic extends React.Component<{
//    width : any;
//    height : any;
//    link : AV.ILink | undefined;
//}>
//{
//    key : number = 1;
//    link: AV.ILink;
//    width: any;
//    height: any;

//    constructor (props : { width : number; height : number; link : AV.ILink }) {
//        super(props);
//    }
//    componentDidMount () {
//        // autofocus the input on mount
//        Config.profilPics.push(this);
//    }

//    newProfile () {
//        this.key++;
//    }
//    componentWillUnmount () {
//        Config.removeProfile(this);
//    }
//    render () {
//        let uri = Link.getTummb(this.link);
//        if (this.link?.id === AV.Person.self()?.id) {
//            let link = AV.Person.getLink(AV.Person.self());
//            uri = Link.getTummb(link);
//        }
//        return (
//            <Box width={ this.width } height={ this.height }>
//                { uri && < img src={ uri } style={ { width: '100%', height: '100%', borderRadius: '50%' } } /> }
//                { !uri && <img src={ LogoSvg } style={ { width: '100%', height: '100%', borderRadius: '50%' } } />
//                }
//            </Box>
//        );
//    }
//}
interface Props {
    width : any;
    height : any;
    link : AV.ILink;
}
const ProfilePic : FC<Props> = ({ width, height, link }) => {
    let uri = Link.getTummb(link);
    if (link?.id === AV.Person.self()?.id) {
        let link = AV.Person.getLink(AV.Person.self());
        uri = Link.getTummb(link);
    }
    return (
        <Box width={ width } height={ height }>
            { uri && < img src={ uri } style={ { width: '100%', height: '100%', borderRadius: '50%' } } /> }
            { !uri && <img src={ LogoSvg } style={ { width: '100%', height: '100%', borderRadius: '50%' } } />
            }
        </Box>
    );
}
export default ProfilePic;
