import { combineReducers } from "redux";
import userReducer from "./userReducer";
import feedsReducer from "./feedsReducer";
import convoReducer from "./convoReducer";
import alertsReducer from './alertsReducer';

const reducers = combineReducers({
    user: userReducer,
    feeds: feedsReducer,
    convo: convoReducer,
    alerts: alertsReducer,
})

export default reducers
