import { useSelector, RootStateOrAny } from 'react-redux';
import { useEffect, useState } from 'react';
import { store } from '../../Redux/store';

export const CheckInternetConnectivity = async (body, endpoint) => {
  const isConnected = navigator.onLine;
  if (!isConnected) {
   var returnvalue : any = body ? JSON.parse(body) : [];
    const reduxstate : RootStateOrAny = store.getState();
    const { entitydatalist, alerts, user, followerslist, convo, feeds } = reduxstate;

    switch (endpoint) {
      case "/api/Person/GetSelf":
        returnvalue = user.user[ 0 ];
        break;

      case "/api/Alert/List":
        returnvalue = alerts.alert[ 0 ];
        break;

      case "/api/Entity/Followers":
        returnvalue = followerslist;
        break;

      case "/api/Convo/Get":
        if (returnvalue?.owner?.type === "Convo") {
          const userconvo = convo.convo[ 0 ];
          returnvalue = userconvo?.find((x : any) => x?.id === returnvalue?.owner?.id) || null;
        }
        break;

      case "/api/Post/Get":
        if (returnvalue?.type === "Post") {
          const userfeeds = feeds.feeds[ 0 ];
          returnvalue = userfeeds?.find((x : any) => x?.id === returnvalue?.id) || null;
        }
        break;

      default:
        if ([ "Team", "LeagueTeam", "Club", "School", "Person", "Event" ].includes(returnvalue?.type)) {
          returnvalue = entitydatalist?.find((x : any) => x?.id === returnvalue?.id) || null;
        } else {
          returnvalue = null;
        }
    }
  }
  return { isConnected: isConnected, returnvalue };
};
