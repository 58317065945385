import * as React from 'react';

const Edit = ({ color }) => (
  <>
    <path
      d='M4.12462736,20.9999816 L8.8928895,20.9999816 C9.19180891,21.001691 9.47911716,20.8843397 9.69134849,20.6738316 L17.4735122,12.880422 L17.4735122,12.880422 L20.6673481,9.7540615 C20.8802473,9.54290103 21,9.25546045 21,8.95560251 C21,8.65574457 20.8802473,8.36830398 20.6673481,8.15714352 L15.899086,3.33265187 C15.6879255,3.11975274 15.4004849,3 15.100627,3 C14.8007691,3 14.5133285,3.11975274 14.302168,3.33265187 L11.1308239,6.51524193 L11.1308239,6.51524193 L3.32616837,14.3086515 C3.11566027,14.5208828 2.99830901,14.8081911 3.00001842,15.1071105 L3.00001842,19.8753726 C3.00001842,20.4964666 3.50353339,20.9999816 4.12462736,20.9999816 Z M15.100627,5.71678294 L18.2832171,8.899373 L16.6862991,10.496291 L13.503709,7.31370092 L15.100627,5.71678294 Z M5.24921749,15.5681925 L11.9180369,8.899373 L15.100627,12.0819631 L8.43180755,18.7507825 L5.24921749,18.7507825 L5.24921749,15.5681925 Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Edit;
