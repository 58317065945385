import React, { useState } from 'react'
import { VStack, Text, Box, Image, Pressable, CloseIcon} from 'native-base'
import * as AV from "../../AVCore/AVCore";
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Profile } from '../../assets/icons/icon-assets';

const ParticipantsCard = ( { item, status, index, editAccess, setisConfirmDelete, setConfirmationtext, setItemSelect, setselectType, filteredParticipantList } ) =>
{
    const currentUser : any = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const entitydatalist = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    const navigate = useNavigate();
    item = status == "ALL"|| "RSVP" ? item : status == item?.status;
    let cacheItem = entitydatalist?.find( ( x : any ) => x?.id == item?.id );
    if ( cacheItem || currentUser?.id == item?.id )
        item = currentUser?.id == item?.id ? AV.AVEntity.getLink( currentUser ) : AV.AVEntity.getLink( cacheItem )
    const baseUrl = 'https://ust-store.actavivo.com/thumb/';
    const imagePath = item?.thumb;
    const fullUrl = `${ baseUrl }${ imagePath }`;
    const [ ParticipantProfile, setParticipantProfile ] = useState<any>(  )

    const HandleRemoveParticipant = ( item ) =>
    {
        setisConfirmDelete( true )
        setConfirmationtext( 'Are You Sure? You Want to Delete Your Participant' );
        setItemSelect( item );
        setselectType( 'DeleteParticipant' )
    }

    return (
        <Box bgColor={ '#fff' } borderWidth={ "1px" } margin={ 4 } borderColor={ "#e7ebe5" } width={ "150px" } height={ "150px" } rounded="lg">
            <Pressable
                p={ 2 }
                borderRadius={ 8 }
                _hover={ { bgColor: '#EEEEEE' } }
                onPress={ () =>
                {
                    navigate( `/profile/${ item?.id }/${ item?.region }`, {
                        state: {
                            user: {
                                id: item?.id,
                                pk: item?.pk,
                                region: item?.region,
                                type: item?.type,
                                name: item?.title,
                            },
                        },
                    } );
                } }
            >
                { editAccess && filteredParticipantList?.length !== 1 &&
                    ( <Pressable
                        key={ item.id }
                        padding={ 1.5 }
                        rounded={ 'full' }
                        position={ 'absolute' }
                        right={ -10 }
                        top={ -10 }
                        _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                        backgroundColor={ 'AVColor.buttonBg' }
                        onPress={ () => HandleRemoveParticipant( item ) }
                    >
                        <CloseIcon size={ '12px' } color={ 'AVColor.secondaryText' } />
                    </Pressable> ) }
                <VStack
                    key={ item.id + index }
                    alignItems={ 'center' }
                    padding={ 2 }
                    overflow='hiden'
                >
                    { ( item?.thumb || item?.linkPic ) ? (
                        <Box
                            borderRadius={ [ 'full' ] }
                            width={ '88px' }
                            height={ '88px' }
                            left={ '1px' }
                            alignSelf={ 'center' }
                            p={ 0 }
                        >
                            <Image
                                source={ {
                                    uri: item?.linkPic?.id?.trim()?.length > 0 ? AV.Media.getURL( item?.linkPic ) : fullUrl,
                                } }
                                borderRadius={ [ 'full' ] }
                                //top={ "2" }
                                width={ '100%' }
                                height={ [ '100%' ] }
                                alignSelf={ 'center' }
                            />
                        </Box>
                    ) : (
                        <Box
                            alignItems={ 'center' }
                            zIndex={ -3 }
                        >
                            <Profile height={ '88' } width={ '88' } color={ "#32AE78" } />
                        </Box>
                    ) }
                    <Text marginTop={ 2 } left={ '2px' } width={ '100px' } text-overflow={ 'ellipsis' } numberOfLines={ 1 } textAlign={ 'center' }>
                        { item.title }
                    </Text>
                </VStack>
            </Pressable>
        </Box>
    )
}

export default ParticipantsCard;                                