import { Box, Divider, HStack, Image, Pressable, Text, VStack, Tooltip } from 'native-base';
import React, { useState, useEffect } from 'react';
import helping from '../../assets/images/helping.png';
import { Loc } from '../../App/Loc';
import { Dimensions } from 'react-native';
import Filter from '../../components/common/AVFilter';
import Dropdown from '../../assets/images/dropdown.svg';
import RenderEvents from '../Events/RenderEvents';
import { RootStateOrAny, useSelector } from 'react-redux';

const ShowTeams = ({ userData }) => {
    const [ teamType, SetTeamType ] = useState('All');
    const [ isLoading, setIsLoading ] = useState(true);
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 500 || false;
    const isSixHundred = dimensions?.width <= 600 || false;
    const isEventView = dimensions?.width <= 750 || false;
    const IsThreeHundred = dimensions?.width <= 300 || false;
    const IsNineFifty = dimensions?.width <= 950 || false;
    const IsFourHundred = dimensions?.width <= 400 || false;
    const entitydatalist = useSelector((state : RootStateOrAny) => state.user.entitydatalist);
    const all : any = userData?.orgs?.filter(x => x?.type !== 'Person' && x?.hidden != true);
    const [ data, setData ] = useState<any>(all);
    const deleteOrg = entitydatalist?.filter((x : any) => x?.status == "Deleted" && x?.type !== "Person")
    const filteredDataIds = deleteOrg?.map((item : any) => item.id);
    const [ isDropDown, setIsDropDown ] = React.useState(false);

    const setAll = () => {
        SetTeamType(Loc.currentLang.menu.all);
        setData(userData?.orgs?.filter(x => x?.type !== 'Person' && x?.hidden != true));
        setIsLoading(false);
    };

    const setMyTeams = () => {
        SetTeamType(Loc.currentLang.menu.filterTeams);
        setData(userData?.orgs?.filter((x) => x.type === 'LeagueTeam' || (x.type === 'Team' && x?.subType === undefined && x?.hidden != true)) || []);
        setIsLoading(false);
    }

    const setClub = () => {
        SetTeamType(Loc.currentLang.menu.clubs);
        setData(userData?.orgs?.filter((x) => x.subType === 'Club' && x?.hidden != true) || []);
        setIsLoading(false);
    };

    const setElite = () => {
        SetTeamType(Loc.currentLang.menu.eliteClubs);
        setData(userData?.orgs?.filter((x) => x?.type === "Club" && x?.hidden != true) || []);
        setIsLoading(false);
    };

    const setSchools = () => {
        SetTeamType(Loc.currentLang.menu.schools);
        setData(userData?.orgs?.filter((x) => x.type === 'School' && x?.hidden != true) || []);
        setIsLoading(false);
    };

    const setGroup = () => {
        SetTeamType(Loc.currentLang.menu.groups);
        setData(userData?.orgs?.filter((x) => x.type === 'Group' && x?.subType === undefined && x?.hidden != true) || []);
        setIsDropDown(false);
        setIsLoading(false);
    };

    const setClass = () => {
        SetTeamType(Loc.currentLang.menu.classes);
        setData(userData?.orgs?.filter((x) => x?.type === 'Group' && x?.subType === "Class" && x?.hidden != true) || []);
        setIsDropDown(false);
        setIsLoading(false);
    };

    const setCommunity = () => {
        SetTeamType(Loc.currentLang.menu.community);
        setData(userData?.orgs?.filter((x) => x.type === 'Community' && x?.hidden != true) || []);
        setIsDropDown(false);
        setIsLoading(false);
    };

    const setGradClass = () => {
        SetTeamType(Loc.currentLang.menu.gradClass);
        setData(userData?.orgs?.filter((x) => x?.type === 'Group' && x?.subType === "GradClass" && x?.hidden != true) || []);
        setIsDropDown(false)
    };

    const setNeighbors = () => {
        SetTeamType(Loc.currentLang.menu.neighborHood);
        setData(userData?.orgs?.filter((x) => x.type === 'Neighborhood' && x?.hidden != true) || []);
        setIsDropDown(false);
        setIsLoading(false);
    };

    const setOrgs = () => {
        SetTeamType(Loc.currentLang.menu.orgs);
        setData(userData?.orgs?.filter((x) => x.type === 'Organization' && x?.hidden != true) || []);
        setIsDropDown(false);
        setIsLoading(false);
    };

    const setDeleted = () => {
        let deleteOrg = entitydatalist?.filter((x : any) => x?.status == "Deleted" && x?.type !== "Person")
        let filteredDataIds = deleteOrg?.map((item : any) => item.id);
        setData(userData?.orgs?.filter((item : any) => item?.hidden == true || filteredDataIds.includes(item.id)));
        SetTeamType(Loc.currentLang.menu.deleted);
        setIsDropDown(false);
        setIsLoading(false);
    };

    const MenuFilter = [
        ...(userData?.orgs?.length > 0 ? [ { id: 1, name: Loc.currentLang.menu.all, flag: data, fn: setAll } ] : []),
        ...(userData?.orgs?.filter((x) => x.type === 'LeagueTeam' || (x.type === 'Team' && x?.subType === undefined && x?.hidden != true))?.length > 0 ? [ { id: 2, name: Loc.currentLang.menu.filterTeams, flag: data, fn: setMyTeams } ] : []),
        ...(userData?.orgs?.filter((x) => x.subType === 'Club' && x?.hidden != true)?.length > 0 ? [ { id: 3, name: Loc.currentLang.menu.clubs, flag: data, fn: setClub } ] : []),
        ...(userData?.orgs?.filter((x) => x?.type === "Club" && x?.hidden != true)?.length > 0 ? [ { id: 4, name: Loc.currentLang.menu.eliteClubs, flag: data, fn: setElite } ] : []),
        ...(userData?.orgs?.filter((x) => x.type === 'School' && x?.hidden != true)?.length > 0 ? [ { id: 5, name: Loc.currentLang.menu.schools, flag: data, fn: setSchools } ] : []),
        ...(userData?.orgs?.filter((x) => x.type === 'Group' && x?.subType === undefined && x?.hidden != true)?.length > 0 ? [ { id: 6, name: Loc.currentLang.menu.groups, flag: data, fn: setGroup } ] : []),
        ...(userData?.orgs?.filter((x) => x?.type === 'Group' && x?.subType === "Class" && x?.hidden != true)?.length > 0 ? [ { id: 7, name: Loc.currentLang.menu.classes, flag: data, fn: setClass } ] : []),
        ...(userData?.orgs?.filter((x) => x.type === 'Community' && x?.hidden != true)?.length > 0 ? [ { id: 8, name: Loc.currentLang.menu.community, flag: data, fn: setCommunity } ] : []),
        ...(userData?.orgs?.filter((x) => x?.type === 'Group' && x?.subType === "GradClass" && x?.hidden != true)?.length > 0 ? [ { id: 9, name: Loc.currentLang.menu.gradClass, flag: data, fn: setGradClass } ] : []),
        ...(userData?.orgs?.filter((x) => x.type === 'Neighborhood' && x?.hidden != true)?.length > 0 ? [ { id: 10, name: Loc.currentLang.menu.neighborHood, flag: data, fn: setNeighbors } ] : []),
        ...(userData?.orgs?.filter((x) => x.type === 'Organization' && x?.hidden != true)?.length > 0 ? [ { id: 11, name: Loc.currentLang.menu.orgs, flag: data, fn: setOrgs } ] : []),
        ...(userData?.orgs?.filter((x) => x?.hidden == true)?.length > 0 || filteredDataIds.length > 0 ? [ { id: 12, name: Loc.currentLang.menu.deleted, flag: data, fn: setDeleted } ] : []),
    ];

    useEffect(() => {
        let deleteOrg = entitydatalist?.filter((x : any) => x?.status == "Deleted" && x?.type !== "Person");
        let filteredData = userData?.orgs;
        if (deleteOrg?.length > 0) {
            filteredData = userData?.orgs?.filter((item : any) => {
                return deleteOrg?.some((canceledItem : any) => canceledItem.id !== item.id);
            });
        }
        setData(filteredData?.filter((x : any) => x?.type !== "Person" && x?.hidden !== true)?.reverse());
    }, [ userData ]);

    const menuRefs = React.useRef(null);

    useOutsideAlerter(menuRefs);
    function useOutsideAlerter (ref) {
        React.useEffect(() => {
            function handleClickOutside (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsDropDown(false)
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ ref ]);
    }

    return (
        <VStack width={ { base: '100%' } } minHeight={ '340px' } backgroundColor={ 'AVColor.white' } p={ 2 } borderRadius={ 'md' }>
            <VStack space={ 5 }>
                <HStack width={ '100%' } space={ 5 } justifyContent={ 'space-between' }>
                    <VStack width={ isMobile ? "100%" : "" }>
                        <HStack marginY={ '24px' } backgroundColor={ 'rgba(0,0,0,0.05)' } borderRadius={ '18px' } width={ 'auto' } height={ '36px' }>
                            { MenuFilter?.slice(0, IsThreeHundred ? 2 : IsFourHundred ? 3 : isMobile ? 4 : isEventView ? 4 : IsNineFifty ? 5 : 6)?.map((item, index) => (
                                <Filter key={ item.id } Type={ teamType } SetType={ item?.fn } Name={ item?.name } />
                            )) }
                            { MenuFilter?.slice(6)?.length > 0 && (
                                <Pressable marginLeft={ '-5px' } marginTop={ '4px' } onPress={ () => setIsDropDown(!isDropDown) }>
                                    <Image source={ Dropdown } alt='Actavivo' size='30px' />
                                </Pressable>
                            ) }
                        </HStack>
                        { isDropDown && (<VStack
                            ref={ menuRefs }
                            width={ '128px' }
                            height='auto'
                            shadow={ 2 }
                            background={ '#FFFFFF' }
                            position={ 'absolute' }
                            top={ '66px' }
                            right={ 0 }
                            zIndex={ 999 }
                            borderRadius={ '6px' }
                        >
                            { MenuFilter?.slice(IsThreeHundred ? 2 : IsFourHundred ? 3 : isMobile ? 4 : isSixHundred ? 4 : IsNineFifty ? 5 : 6, MenuFilter?.length)?.map((item, index) => (
                                <>
                                    <Pressable
                                        ref={ menuRefs }
                                        key={ item.id + index }
                                        onPress={ item.fn }
                                        width={ '100%' }
                                        height={ '36px' }
                                        alignItems={ 'flex-start' }
                                        paddingX={ '10px' }
                                        _hover={ {
                                            backgroundColor: 'gray.100'
                                        } }
                                        justifyContent={ 'center' }
                                    >
                                        <Text
                                            fontSize={ '14px' }
                                            fontFamily={ 'Roboto' }
                                        >
                                            { item.name }
                                        </Text>
                                    </Pressable>
                                    <Divider />
                                </>
                            )) }

                        </VStack>) }
                    </VStack>
                    <HStack >
                        <Tooltip label={ Loc.currentLang.helpText.person.organizationTab }>
                            <Box p={ '9px' }
                                display={ data?.length > 0 ? '' : 'none' }
                                shadow={ 1 }
                                zIndex={ -3 }
                                height={ '33px' }
                                backgroundColor={ 'AVColor.liteBg' }
                                rounded={ 'full' }>
                                <img src={ helping } height={ '17px' } width={ '17px' } />
                            </Box>
                        </Tooltip>
                    </HStack>

                </HStack>
            </VStack>
            <HStack
                backgroundColor={ 'AVColor.white' }
                space={ 1 }
                flexWrap={ 'wrap' }
                mt={ 2 }
                zIndex={ -3 }
                mb={ isMobile ? "30px" : "" }
                height={ 'auto' }
                borderRadius={ 5 }
                justifyContent={ 'center' }
                paddingY={ 2 }
                paddingLeft={ 0 }
                paddingRight={ 0 }
                width={ '100%' }
            >

                { data?.length !== 0 &&
                    (<RenderEvents data={ data } />) }
                { data?.length === 0 &&
                    (<VStack >
                        <HStack padding={ '10px' } alignItems={ 'center' } >
                            <Text>{ Loc.currentLang.menu.noOrganizationFound }</Text>
                        </HStack>
                    </VStack>) }
            </HStack>
        </VStack>
    );
};

export default ShowTeams;
