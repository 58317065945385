import * as React from 'react';

const Happy = ({ color }) => (
  <>
    <path
      d='M14.2251429,14.1025714 C12.9264765,15.1496273 11.0735235,15.1496273 9.77485714,14.1025714 C9.37389841,13.769307 8.77869297,13.8241841 8.44542857,14.2251429 C8.11216417,14.6261016 8.16704127,15.221307 8.568,15.5545714 C10.5552819,17.2135387 13.4447181,17.2135387 15.432,15.5545714 C15.6913748,15.3389872 15.8160342,15.0014043 15.7590202,14.668987 C15.7020061,14.3365696 15.4719804,14.05982 15.1555916,13.942987 C14.8392027,13.8261539 14.4845177,13.8869872 14.2251429,14.1025714 L14.2251429,14.1025714 Z M9.17142857,11.0571429 C9.69215419,11.0571429 10.1142857,10.6350113 10.1142857,10.1142857 C10.1142857,9.59356009 9.69215419,9.17142857 9.17142857,9.17142857 C8.65070295,9.17142857 8.22857143,9.59356009 8.22857143,10.1142857 C8.22857143,10.6350113 8.65070295,11.0571429 9.17142857,11.0571429 Z M14.8285714,9.17142857 C14.3078458,9.17142857 13.8857143,9.59356009 13.8857143,10.1142857 C13.8857143,10.6350113 14.3078458,11.0571429 14.8285714,11.0571429 C15.349297,11.0571429 15.7714286,10.6350113 15.7714286,10.1142857 C15.7714286,9.59356009 15.349297,9.17142857 14.8285714,9.17142857 Z M12,2.57142857 C6.79274379,2.57142857 2.57142857,6.79274379 2.57142857,12 C2.57142857,17.2072562 6.79274379,21.4285714 12,21.4285714 C17.2072562,21.4285714 21.4285714,17.2072562 21.4285714,12 C21.4285714,9.49938738 20.4352069,7.10119335 18.6670068,5.33299321 C16.8988067,3.56479307 14.5006126,2.57142857 12,2.57142857 Z M12,19.5428571 C7.83419503,19.5428571 4.45714286,16.165805 4.45714286,12 C4.45714286,7.83419503 7.83419503,4.45714286 12,4.45714286 C16.165805,4.45714286 19.5428571,7.83419503 19.5428571,12 C19.5428571,14.0004901 18.7481655,15.9190453 17.3336054,17.3336054 C15.9190453,18.7481655 14.0004901,19.5428571 12,19.5428571 Z'
      id='Shape'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Happy;
