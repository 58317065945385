import React from 'react';
import {  Text, HStack, Box } from 'native-base';
import * as AV from '../../AVCore/AVCore';
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import { AVButtonRoundedFull } from 'components/common/AVButtons';
import { Loc } from '../../App/Loc';
import { Config } from 'App/Config';

function Unsubscribe (props : any) {

    const [ value, setvalue ] = React.useState<any>();

    React.useEffect(() => {
        if (new URLSearchParams(window.location.search).get('id') !== null) {
            let id = new URLSearchParams(window.location.search).get('id');
            let region = new URLSearchParams(window.location.search).get('region');
            let fk : AV.IFK = { id: id, region: region };
            setvalue(fk);
        }

    }, []);

    const handleUnsubscribe = async () => {
        if (value) {
            await AV.AVEntity.Unsubscribe(value.region, value.id);
            await Config.init().then((res) => {
                window.location.href = window.location.origin;
                //  window.location.reload();
            })
        }
    };

    const handleCancelUnsubscribe = async () => {
        await Config.init().then((res) => {
            window.location.href = window.location.origin;
            // window.location.reload();
        })
    }

    return (
        <>
            <Box bgColor={ 'AVColor.white' } shadow={ 1 } borderRadius={ 5 } borderColor={ 'AVColor.buttonBg' }
                width={ '100%' } height={ '800px' } alignItems={ 'Center ' }  >
                <img src={ LogoSvg } style={ { width: 200, height: 200, objectFit: 'cover', paddingTop: '200px' } } alt='' />
                <Text fontWeight={ '400' } marginLeft={ '15px' } fontSize={ '25px' } color={ 'AVColor.primary' } py={ '12px' }   >
                    { Loc?.currentLang?.menu?.unsubscribe }
                </Text>
                <Text fontWeight={ '400' } marginLeft={ '15px' } fontSize={ '16px' } color={ 'AVColor.primary' } py={ '12px' }   >
                    { Loc?.currentLang?.menu?.doYouReallyWantToUnsubscribe }?
                </Text>
                <HStack space={ '2' }>
                    <AVButtonRoundedFull btnText='Yes' callback={ handleUnsubscribe } px={ undefined } height={ undefined } isLoadingText={ undefined } isDisabled={ undefined } isLoading={ undefined } loading={ undefined } />
                    <AVButtonRoundedFull btnText='No' callback={ handleCancelUnsubscribe } px={ undefined } height={ undefined } isLoadingText={ undefined } isDisabled={ undefined } isLoading={ undefined } loading={ undefined } />
                </HStack>

            </Box>
        </>
    );
}
export default React.memo(Unsubscribe);
