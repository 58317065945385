import React, { useState, useRef } from 'react'
import { Input, Text, View, HStack, Button, Pressable, Divider, Image } from 'native-base'
import { Loc } from '../../App/Loc';
import { useWindowDimension } from 'Utility/WindowDimensions';
import Dropdown from '../../assets/images/dropdown.svg';

const AVComDropdown = ( { dropdownValues } ) =>
{
    const [ isActives, setIsActives ] = useState( false );
    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;

    const menuRefs = useRef( null );
    useOutsideAlerter( menuRefs );
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setIsActives( false );
                }
            }
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }
    return (
        <HStack ref={ menuRefs }>
            <HStack zIndex={ 3 } ref={ menuRefs }>
                <Button
                    
                    bgColor='AVColor.primary'
                    rounded='full'
                    color='#fff'
                    width={ isMobile ? '105px': '135px' }
                    paddingTop={ '6px' }
                    paddingBottom={ '6px' }
                    paddingLeft={ '8px' }
                    paddingRight={ '8px' }
                    paddingY={ '8px' }
                    paddingX={ '10px' }
                    justifyContent={ 'flex-start' }
                    borderColor={ 'AVColor.primary' }
                    onPress={ () =>
                    {
                        setIsActives( !isActives );
                    } }
                >
                    <HStack space={ 3 } alignItems={'center' }>
                    <Text
                        color={ 'white' }
                        lineHeight={ '1rem' }
                        fontWeight={500 }
                        paddingLeft={ '13px' }
                        maxW={ [ '130px' ] }
                        text-overflow={ 'ellipsis' }
                        numberOfLines={ 1 }
                    >
                            { Loc.currentLang.menu.create }
                        </Text>
                    </HStack>
                    <Pressable alignItems={ 'center' } position={ 'absolute' } left={'95px' } bottom={ '-5px' } onPress={ () =>
                    {
                        setIsActives( !isActives );
                    } } >
                        <Image source={ Dropdown } alt='Actavivo' size='25px' right={ isMobile ? '30px' : '0px' }
                            tintColor={ '#ffffff' }/>
                    </Pressable>
                </Button>
            </HStack>
            <HStack>
                { isActives && (
                    <HStack
                        ref={ menuRefs }
                        position={ 'absolute' }
                        right={'3px'}
                        top={ '40px' }
                        flexDirection={ 'column' }
                        width={ isMobile ? '105px' : '140px' }
                        borderRadius={ '5px' }
                        bgColor='AVColor.primary'
                        shadow='9'
                        rounded='lg'
                        zIndex={ 999 }
                    >
                        { dropdownValues?.map( ( item ) => (
                            <>
                            <Button
                                ref={ menuRefs }
                                onPress={item?.fn}
                                bgColor='#FFFFFF'
                                height='36px'
                                justifyContent={ 'start' }
                                zIndex={ 999 }
                                _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                width={ '140px' }
                            >
                                <Text color={ 'black' }>
                                    { item?.name }
                                </Text>
                            </Button>
                                <Divider />
                            </>
                        ) ) }
                       
                    </HStack>
                ) }
            </HStack>
        </HStack>
    )
}

export default AVComDropdown;