import * as React from 'react';

const Store = ({ color }) => (
  <>
    <path
      d='M21.695 5.771a.963.963 0 0 0-.772-.389H6.289l-.197-.833a2.23 2.23 0 0 0-2.179-1.725H2.371a.96.96 0 0 0 0 1.919h1.542a.32.32 0 0 1 .312.245l2.098 8.904a2.23 2.23 0 0 0 2.18 1.725h4.743a.96.96 0 1 0 0-1.919H8.502a.319.319 0 0 1-.31-.245L6.74 7.3h12.883l-1.899 6.17a.317.317 0 0 1-.306.227h-.973a.96.96 0 0 0 0 1.92h.973a2.23 2.23 0 0 0 2.14-1.58l2.282-7.413a.963.963 0 0 0-.145-.854ZM9.728 16.897c-1.058 0-1.92.86-1.92 1.919 0 1.058.862 1.919 1.92 1.919 1.058 0 1.919-.861 1.919-1.919s-.861-1.92-1.92-1.92Zm6.397 0c-1.058 0-1.92.86-1.92 1.919 0 1.058.862 1.919 1.92 1.919 1.058 0 1.919-.861 1.919-1.919s-.861-1.92-1.92-1.92Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Store;
