import React, {FC} from 'react';
import { Button, Text } from 'native-base';
import { Dimensions } from 'react-native';


interface Props {
    Type: any,
    Name: string,
    SetType:any
}

const dimensions = Dimensions.get( 'window' );
const isMobile = dimensions?.width <= 500 || false;
const Filter: FC<Props> = ({Type, Name, SetType}) => {
    return (
        <Button
        borderRadius={'19px'}
        height={'36px'}
        paddingLeft={'24px'}
        paddingRight={ '24px' }
        width={ isMobile ? "81px" : "" }
            bgColor={ Type === Name ? '#2FA3BE' : ''}
        onPress={() => {
            SetType(Name);
        } }
    >
         <Text color={Type === Name ? 'white' : 'rgba(0,0,0,0.6)'} fontWeight={400} fontSize={'16px'} >{Name}</Text>
     </Button>
    );
}


export default Filter;