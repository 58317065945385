import * as React from 'react';
import { Person } from '../AVCore/Person';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { setConvo } from '../Redux/Actions/convoActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

const useConvoList = () => {
    const navigate = useNavigate();
    const [ showConvoListModal, setShowConvoListModal ] = React.useState(false);
    const [ filter, setFilter ] = React.useState( '' );
    const userconvo = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const getConvoList = async () =>
    {
        let convoList = userconvo?.filter(
            ( x ) =>
                ( x?.people[ 0 ] && x?.people[ 0 ]?.title !== undefined &&
                    x?.people[ 0 ]?.title?.toLocaleLowerCase().includes( filter.toLocaleLowerCase() ) ) ||
                ( x?.title !== undefined &&
                    x?.title?.toLocaleLowerCase().includes( filter.toLocaleLowerCase() ) ) )
        return convoList?.sort( function ( a, b )
        {
            let c : any = new Date( b.lastUpdated );
            let d : any = new Date( a.lastUpdated );
            return c - d;
        } );;
    };

    const handleConversation = async () => {
        if (showConvoListModal) navigate('/convos');
        setShowConvoListModal(false);
    };
    const handleSearchConvo = async (item) => {
        setFilter(item);
    };
    const { data: convoListData } = useQuery(
        [ 'allconvolist', filter ],
        getConvoList,
        {
            keepPreviousData: true,
            refetchInterval: 1000,
        }
    );

    return {
        showConvoListModal,
        setShowConvoListModal,
        handleConversation,
        handleSearchConvo,
        convoListData,
    };
};
export default useConvoList;
