import * as React from 'react';
import MyFollowings from './MyFollowings';
import CreateNew from './CreateNew';
import { HStack, Stack, ScrollView, VStack, Text, Pressable, Hidden } from 'native-base';
import { MoreHorizontalIcon } from '../../assets/icons/icon-assets';

const RightContent = ( { setShowCreateNewPage, setType, setIsShowEdit }) => {
    const [fixedWidth, setFixedWidth] = React.useState<number | undefined>(undefined);
    const rightRef = React.useRef<HTMLDivElement | null>(null);

    React.useLayoutEffect(() => {
        setFixedWidth((rightRef.current?.offsetWidth ?? 0) - 2);
    }, []);
    return (
        <Hidden only={['base', 'sm', 'md']}>
            <VStack width={'227px'} zIndex={-1} marginLeft={'24px'}  ref={rightRef}>
                <VStack
                    position={'fixed'}
                    space={3}
                    width={'227px'}
                >
                    <CreateNew setShowCreateNewPage={ setShowCreateNewPage } setType={ setType } setIsShowEdit={ setIsShowEdit } />
                    {/*<MyFollowings />*/}
                </VStack>
            </VStack>
        </Hidden>
    );
}
export default React.memo(RightContent);
