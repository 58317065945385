import React, { useEffect, useState } from 'react';
import { Box, Button, Image } from 'native-base';
import { AddPhotoIcon, TeamProfile, FavouriteIcon, Community, Neighbor, Organization, GradClass, Group, Class, EliteClub, School, Profile, Event, LogoSvgIcon, FeedbackIcon, BugReportIcon, FeatureRequestIcon, Club } from '../../assets/icons/icon-assets';
import { useDropzone } from 'react-dropzone';
import { useSelector, RootStateOrAny } from 'react-redux';
import * as AV from '../../AVCore/AVCore';
import { useParams,useLocation } from 'react-router-dom';
import { Dimensions } from 'react-native';

const AVProfilePic = ( { isMyFavourite, handleFavourite, Entity, type, isLoader, handleUpload, isCreate, profile, setProfile, EntityType, isAdminView, imageLoader, setImageLoader }) => {
/*    console.log('ProfileType', EntityType)*/
    const params = useParams();
    const location:any = useLocation();
    const FeedBackId = location?.state?.team?.id;
    const dimensions = Dimensions.get('window');
    const isMobile = dimensions?.width <= 600 || false;
    const orgType = params?.type;
    const pathname = location?.pathname;
    const segments = pathname.split( '/' );
    // Get the last segment
    const teamValue = segments[ segments.length - 1 ];

    const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);
    const [ProfileImage, setProfileImage] = useState<any>(isCreate ? null : AV.Media.getURL(Entity?.profile?.pic) ? AV.Media.getURL(Entity?.profile?.pic) : null);
    const [picLoader, setPicLoader] = useState(false)
/*    console.log('ProfileOrgType', orgType)*/

    React.useEffect(() => {
        let isMounted = true;
        if(isMounted){
            setProfileImage(isCreate ? null : AV.Media.getURL(Entity?.profile?.pic) ? AV.Media.getURL(Entity?.profile?.pic) : null)
        }
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
            // Cancel subscriptions or perform any cleanup tasks here
        };

    }, [isCreate])

    const { getRootProps: getProfilePicFile, getInputProps: getProfileInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setPicLoader( true );
            setImageLoader(true)
            if ( acceptedFiles.length > 0 && acceptedFiles[ 0 ].type.startsWith( 'image' ) )
            {
                setProfileImage( URL.createObjectURL(acceptedFiles[ 0 ]));
            } else
            {
                // Handle non-image files
                // Implement your validation or error handling logic here
                console.log( "Unsupported file type. Please drop an image file." );
                setPicLoader( false );
                return;
            }

            getMedia(acceptedFiles)
                .then((res:any) => {
                    setProfile((profile: any) => {
                        return { ...profile, pic: res };
                    });
                    if (!profile) {
                        profile = { background: null, pic: null, thumb: null, gallery: null, previousPic: null, text: null, color: null};
                    }
                    profile.pic = [];
                    profile.background = Entity?.profile?.background?.id ? Entity?.profile?.background : profile.background?.id ? profile.background : null;
                    profile.color = Entity?.profile?.color ? Entity?.profile?.color : null;
                    profile.text = Entity?.profile?.text ? Entity?.profile?.text : profile?.text ? profile?.text : null;
                    res = { ...res, parent: AV.AVEntity.getFk(Entity) }
                    profile.pic = res;
                    profile.text = Entity?.profile?.text && Entity?.profile?.text 
                    setProfile( profile );
                    setPicLoader( false );
                })
                .then(async (x) => {
                    if (!isCreate) {
                        Entity.profile = profile;
                        await handleUpload( Entity );
                        setImageLoader( false )
                    }
                });
        },
    });

    const getMedia = async (files: any) => {
        const updatedFiles = files.map((file) => {
            const fileName = file.name;
            if (fileName.toLowerCase().endsWith('.jfif')) {
                const updatedFileName = fileName.replace(/\.\w+$/, '.jpg');
                const updatedFile = new File([file], updatedFileName, { type: "image/jpeg" });
                return updatedFile;
            }
            else {
                return file;
            }
        });
        files = updatedFiles;
        let iMedia = await AV.Media.saveFiles(AV.Media.getFormData(files), Entity?.region ? Entity?.region : currentUser?.region);
        return iMedia[0];

    };
    useEffect(() => {
        setProfileImage( isCreate ? null : AV.Media.getURL( Entity?.profile?.pic ) ? AV.Media.getURL( Entity?.profile?.pic ) : null )
        //setProfile( { background: null, pic: null, thumb: null, gallery: null, previousPic: null, text: null, color: null } )
    }, [Entity])

    const ProfilePlaceHolder = ({ EntityType, type }) => {
        return (
            <>
                {((EntityType?.type === 'Team' && EntityType?.subType == undefined) || (type == 'Team' || teamValue =='Team')) ?
                    <TeamProfile height={ isMobile ? '150' : '170' } width={ isMobile ? '150' : '170' } color={ "#32AE78" } /> :
                (EntityType?.type === 'LeagueTeam' && type !== 'Team') ?
                    (<TeamProfile height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />):
               ((type == 'EliteClub' || EntityType?.type === 'Club' || teamValue == 'EliteClub' ) && type !== 'Team') ?
                    (<EliteClub height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />) :
                EntityType?.type === 'Person' ?
                    (<Profile height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />):
                (EntityType?.subType === 'Club' || type == 'Club' || teamValue == 'Club') ?
                    <Club height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />:
                 (EntityType?.type == 'School' || type == 'School' || teamValue == 'School' ) ?
                    <School height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />:
                ((EntityType?.type === 'Group'  && Entity?.subType !== 'Class' && EntityType?.subType !== 'GradClass' && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa7f" && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa71" && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa72" ) || ( type === 'Group')) ?
                    <Group height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />: 
                 ((Entity?.subType == 'Class'  ) || (type === 'Class' || teamValue == 'Class' )) ?
                    <Class height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />:
                ((EntityType?.type === 'Group' && EntityType?.subType == 'GradClass') || (type === 'GradClass' || teamValue == 'GradClass')) ?
                <GradClass height={ isMobile ? '150' : '170' } width={ isMobile ? '150' : '170' } color={ "#32AE78" } />:

                 ((EntityType?.type == 'Organization' || type === 'Organization' || teamValue == 'Organization' ) && (Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa7f" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa71" && Entity?.id !== "b495d4e0-bf52-4690-8dc0-677db954aa72") && type !== 'Feedback') ?
                    <Organization height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />:
                (EntityType?.type === 'Neighborhood' || type === 'Neighborhood' || teamValue == 'Team' || teamValue == 'Neighborhood' ) ?
                    <Neighbor height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />:
                ( EntityType?.type == 'Community' || type === 'Community' || teamValue == 'Community' ) ?
                    <Community height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />:
                (EntityType?.type === 'Event') ?
                    <Event height={isMobile ? '150' : '170'} width={isMobile ? '150' : '170'} color={"#32AE78"} />:
                ( Entity?.id === "78b5b063-fd3e-4925-92bd-2a1888c6a46a" && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa72" && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa7f" && FeedBackId !== "b495d4e0-bf52-4690-8dc0-677db954aa71" ) ?
                    <LogoSvgIcon height={isMobile ? '150' : '160'} width={isMobile ? '150' : '160'} />:
                 FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa7f" ?
                    <FeedbackIcon height={isMobile ? '150' : '160'} width={isMobile ? '150' : '160'} />:
                 FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa71" ?
                    <BugReportIcon height={isMobile ? '150' : '160'} width={isMobile ? '150' : '160'} />:
                FeedBackId === "b495d4e0-bf52-4690-8dc0-677db954aa72" ?
                    <FeatureRequestIcon height={isMobile ? '150' : '160'} width={isMobile ? '150' : '160'} />:''}
            </>
        )
    }
    return (
        <>
            <Box
                position={'absolute'}
                left={isCreate && isMobile ? '2%':'5%'}
                top={ type === 'Feedback' ? '24px' : Entity?.type === "Event" && !Entity?.profile?.background?.id  ? '12px':'116px'}
                height={isMobile ? '150':170}
                width={isMobile ? '150' : 170}
                rounded={'full'}
                bgColor={'white'}
                alignItems={type === 'Feedback' ? 'center' : ''}
                justifyContent={type === 'Feedback' ? 'center' : ''}
            >
                {(isMyFavourite && !isCreate) &&
                    (<Box
                        position={'absolute'}
                        zIndex={99}
                        top={2}
                        left={2}
                        backgroundColor={'AVColor.white'}
                        rounded={'full'}
                        p={2}
                        shadow={1}

                    >
                        <Button
                            backgroundColor={'AVColor.white'}
                            size={'20px'}
                            isLoading={isLoader}
                            _spinner={{ color: 'AVColor.black' }}
                            onPress={() => {
                                handleFavourite('removeFavourite');
                            }}
                        >
                            <FavouriteIcon color={'AVColor.secondary'} />
                        </Button>
                    </Box>)}
                { ( ( type !== 'Feedback' && isAdminView && Entity?.status !== 'Deleted' && Entity?.status !== 'Canceled') || isCreate) && (<Button
                    bgColor={'AVColor.white'}
                    position={'absolute'}
                    height={'40px'}
                    width={'40px'}
                    right={'-12px'}
                    bottom={'20px'}
                    rounded={'full'}
                    _hover={{ backgroundColor: 'AVColor.hovercolor' }}
                    shadow={4}
                    zIndex={99}
                    isLoading={ picLoader }
                   // isDisabled={ imageLoader }
                    _spinner={{ color: 'AVColor.black' }}
                >
                    <Box {...getProfilePicFile({ className: 'dropzone' })}>
                        <input {...getProfileInputProps()} />
                        <AddPhotoIcon size={'28px'} color={'black'} />
                    </Box>
                </Button>)}
                {(Entity?.profile?.pic || profile?.pic) ? (<Image
                    style={{ height: '95%', width: '95%', borderRadius: 100, margin: 'auto' }}
                    source={{
                        uri: ProfileImage,
                    }}
                />) :
                    (
                        <ProfilePlaceHolder EntityType={EntityType} type={type} />)}
            </Box>
        </>
    )
}

export default AVProfilePic