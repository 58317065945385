/*!
 * Copyright © 2024 Actavivo
 */
import * as AV from './Interfaces/AV.Interfaces';
import { Server } from '../Platform/Server';


export class AVBase {
    static _api = "/api/";
  static _baseApi = "/api/Base";
    public static getFk(obj: AV.IAVBase) :AV.IFK{
        return { pk: obj.pk, id: obj.id, region: obj.region, type: obj.type };
    }

    public static async getURLLink(urlLink: AV.IURLLink): Promise<AV.IURLLink> {
      return await Server.post<AV.IURLLink>(this._baseApi + "/GetLink", urlLink);
        
    }
    //public static async save<T extends AV.IAVBase>(val: T): Promise<T> {

    //    return await Server.post<T>(this._api + val.type, val);
    //}
    //public static async delete<T extends AV.IAVBase>(val: T) {
    //    await Server.delete<T>(this._api + val.type, AVBase.getFk(val));
    //}
}