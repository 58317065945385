import * as signalR from '@microsoft/signalr';
import { Auth } from '../Platform/Auth';

export class SignalRService {
  private static hubUrl = '/SignalRHub'; // Ensure this matches the URL in Startup.cs
  private static connection : signalR.HubConnection | null = null;

  // Create the SignalR connection with user ID and token
  private static async createConnection () {
    if (SignalRService.connection) {
      return; // Connection already created
    }

    try {
      // Factory function to get the authentication token
      const accessTokenFactory = async () => {
        const token = await Auth.getToken();
        if (!token) {
          throw new Error('Authentication token is not available');
        }
        return token;
      };

      // Build the SignalR connection
      SignalRService.connection = new signalR.HubConnectionBuilder()
        .withUrl(SignalRService.hubUrl, {
          accessTokenFactory: accessTokenFactory, // Ensure token is included in each request
        })
        .withAutomaticReconnect() // Enable automatic reconnection
        .configureLogging(signalR.LogLevel.Information)
        .build();
    } catch (error) {
      console.error('Error creating SignalR connection:', error);
      throw error;
    }
  }

  // Function to start SignalR connection
  private static async startSignalRConnection () {
    try {
      if (SignalRService.connection) {
        await SignalRService.connection.start();
        console.log('SignalR Connected');
      } else {
        console.error('SignalR connection is null.');
      }
    } catch (error) {
      console.error('SignalR Connection Error: ', error);
      throw error;
    }
  }

  // Function to stop SignalR connection
  public static async stopSignalRConnection () {
    try {
      if (SignalRService.connection?.state === signalR.HubConnectionState.Connected) {
        await SignalRService.connection.stop();
        console.log('SignalR Connection Stopped');
      }
    } catch (error) {
      console.error('Error stopping SignalR connection:', error);
      throw error;
    }
  }

  // Function to handle sending typing status
  public static async sendTyping (convoId : string) {
    try {
      if (!SignalRService.connection) {
        throw new Error('SignalR connection is not created.');
      }

      if (SignalRService.connection.state === signalR.HubConnectionState.Disconnected) {
        await SignalRService.startSignalRConnection();
      }

      if (SignalRService.connection.state === signalR.HubConnectionState.Connected) {
        await SignalRService.connection.invoke('SendTyping', convoId);
        console.log('SendTyping invoked successfully');
      } else {
        console.error('SignalR connection is not in the connected state.');
      }
    } catch (error) {
      console.error('Error invoking SendTyping:', error);
      throw error;
    }
  }

  // Function to handle removing impression
  public static async removeImpression () {
    try {
      await SignalRService.createConnection();
      if (SignalRService.connection?.state === signalR.HubConnectionState.Disconnected) {
        await SignalRService.startSignalRConnection();
      }

      if (SignalRService.connection?.state === signalR.HubConnectionState.Connected) {
        await SignalRService.connection?.invoke('RemoveImpression');
        console.log('RemoveImpression invoked successfully');
      } else {
        console.error('SignalR connection is not in the connected state.');
      }
    } catch (error) {
      console.error('Error invoking RemoveImpression:', error);
      throw error;
    }
  }

  // Function to join a group
  public static async joinGroup (convoId : string) {
    try {
      await SignalRService.createConnection();
      if (SignalRService.connection?.state === signalR.HubConnectionState.Disconnected) {
        await SignalRService.startSignalRConnection();
      }

      if (SignalRService.connection?.state === signalR.HubConnectionState.Connected) {
        await SignalRService.connection?.invoke('AddConvo', convoId);
        console.log('Joined group successfully');
      } else {
        console.error('SignalR connection is not in the connected state.');
      }
    } catch (error) {
      console.error('Error joining group:', error);
      throw error;
    }
  }

  // Function to leave a group
  public static async leaveGroup (convoId : string) {
    try {
      if (SignalRService.connection?.state === signalR.HubConnectionState.Disconnected) {
        await SignalRService.startSignalRConnection();
      }

      if (SignalRService.connection?.state === signalR.HubConnectionState.Connected) {
        await SignalRService.connection?.invoke('RemoveConvo', convoId);
        console.log('Left group successfully');
      } else {
        console.error('SignalR connection is not in the connected state.');
      }
    } catch (error) {
      console.error('Error leaving group:', error);
      throw error;
    }
  }

  // Optionally, export the connection itself if needed externally
  public static getConnection () {
    return SignalRService.connection;
  }
}