import React, { Component, ReactNode } from 'react';
import ErrorHandlerPopup from './ErrorHandlerPopup';
import logger from '../../Utility/logger'

interface ErrorBoundaryProps {
    hasError: any;
}

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError ( error : any )
    {
        logger( error )
        return { hasError: true };
    }

    componentDidCatch ( error : any, errorInfo : any )
    {
        logger(error)
        // Handle or log the error here
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorHandlerPopup />;
        }

        return this.props.children;
    }
}


export default ErrorBoundary;
