import React from 'react';
import Add from './add';
import AddPhoto from './addPhoto';
import ArrowDown from './arrowDown';
import Attach from './attach';
import Cancel from './cancel';
import Coaching from './coaching';
import CreateMessage from './createMessage';
import Dislike from './dislike';
import Edit from './edit';
import Events from './events';
import Favorites from './favorites';
import Groups from './groups';
import Happy from './happy';
import Home from './home';
import Message from './message';
import Notification from './notification';
import Organization from './organization';
import PhotoAlbum from './photoAlbum';

import Search from './search';
import Startruck from './startruck';
import Store from './store';
import Video from './video';

const getIconDetail = (name, props) => {
  const icon = {
    component: null,
    viewBox: '',
  };
  switch (name) {
    case 'STARTRUCK':
      icon.component = <Startruck {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'SEARCH':
      icon.component = <Search {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'HOME':
      icon.component = <Home {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'EVENTS':
      icon.component = <Events {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'GROUPS':
      icon.component = <Groups {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'FAVORITES':
      icon.component = <Favorites {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'ORGANIZATIONS':
      icon.component = <Organization {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'COACHING':
      icon.component = <Coaching {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'VIDEO':
      icon.component = <Video {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'STORE':
      icon.component = <Store {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'NOTIFICATION':
      icon.component = <Notification {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'MESSAGE':
      icon.component = <Message {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'ADD':
      icon.component = <Add {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'ADD_PHOTO':
      icon.component = <AddPhoto {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'ARROW_DOWN':
      icon.component = <ArrowDown {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'ATTACH':
      icon.component = <Attach {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'CANCEL':
      icon.component = <Cancel {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'CREATE_MESSAGE':
      icon.component = <CreateMessage {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'DISLIKE':
      icon.component = <Dislike {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'EDIT':
      icon.component = <Edit {...props} />;
      icon.viewBox = '0 0 25 25';
      break;

    case 'PHOTO_ALBUM':
      icon.component = <PhotoAlbum {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    case 'HAPPY':
      icon.component = <Happy {...props} />;
      icon.viewBox = '0 0 25 25';
      break;
    default:
      icon.component = <path {...props} />;
      icon.viewBox = '0 0 20.164 20.664';
      break;
  }
  return icon;
};

const SvgIcon = (props) => {
  const { name, color, classes, strokeColor, size } = props;
  const { component, viewBox } = getIconDetail(name, { color, strokeColor });
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size || '100%'}
      height={'100%'}
      viewBox={viewBox}
      name={name}
      className={classes}
    >
      {component}
    </svg>
  );
};

export default SvgIcon;
