import * as React from 'react';
import {
  HomeIcon,
  EventsIcon,
  NetworkIcon,
  FavouriteIcon,
  SearchIcon,
  MoreHorizontalIcon,
} from '../../assets/icons/icon-assets';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setFeeds } from '../../Redux/Actions/feedsAction';
import {
  Image,
  HStack,
  Pressable,
  Hidden,
  Box,
  Modal,
  VStack,
  Text,
  Button,
  Input,
  CloseIcon,
} from 'native-base';
import { Link } from '../../AVCore/Link';
import { useNavigate } from 'react-router-dom';
import useGetFeeds from '../../Hooks/useGetFeeds';
import { Dimensions } from 'react-native';
import useAlerts from '../../Hooks/useAlerts';

function Menu(props: any) {
  const navigate = useNavigate();

    const [ isHome, setIsHome ] = React.useState(window.location.pathname === '/');
    const [ isEvent, setIsEvent ] = React.useState(window.location.pathname  === '/my-events');
    const [ isTeams, setIsTeams ] = React.useState(window.location.pathname === '/my-teams');
    const [ isFavourites, setIsFavourites ] = React.useState(window.location.pathname === '/favorites');
  const user = useSelector((state : RootStateOrAny) => state.user.user[0]);
    const { refetch } = useGetFeeds( "Home", user?.id, undefined, undefined, undefined );
    const { handlerefetch } = useAlerts();
    const dispatch = useDispatch();
    const dimension = Dimensions.get('window');
    const isMobile = dimension?.width <= 600 || false;


    const reload = () => {
        refetch();
    };

  return (
    <>
      <HStack space={'10'} justifyContent={'center'}>
      <Button
       borderRadius={'full'}
       paddingTop={'10px'}
       paddingBottom={'12px'}
        paddingLeft={'11px'}
        bgColor={isHome ? '#00A7BC' : isHome && isMobile ? '#2d9c6c' : 'none'}
        _hover={ !isHome ? { bgColor: '#EEEEEE' } : isMobile ? { bgColor: '#2d9c6c' } : { bgColor: '#00A7BC' } }
        isDisabled={ user?.status === 'Deleted' }
          onPress={() => {
            navigate('/');
           // handlerefetch();
            setIsEvent(false);
            setIsFavourites(false);
            setIsTeams(false);
            setIsHome(true);
          }}
        >
          <HomeIcon size={'34px'} borderWidth={1} color={isHome ? 'white' : '#000'} />
        </Button>
        <Button
                  borderRadius={'full'}
                  paddingTop={'10px'}
                  paddingBottom={'12px'}
                  paddingLeft={'11px'}
          bgColor={isEvent ? '#00A7BC' : 'none'}
          _hover={ !isEvent ? { bgColor: '#EEEEEE' } : { bgColor: '#00A7BC' } }
             isDisabled={ user?.status === 'Deleted' }
          onPress={() => {
            navigate('/my-events');
            setIsHome(false);
            setIsFavourites(false);
            setIsTeams(false);
            setIsEvent(true);
          }}
              >
                      <EventsIcon size={'34px'} color={isEvent ? 'white' : '#000'} />
        </Button>
        <Button
                  borderRadius={'full'}
                  paddingTop={'10px'}
                  paddingBottom={'12px'}
                  paddingLeft={'11px'}
          bgColor={isTeams ? '#00A7BC' : 'none'}
                  _hover={ !isTeams ? { bgColor: '#EEEEEE' } : { bgColor: '#00A7BC' } }
                  isDisabled={ user?.status === 'Deleted' }
          onPress={() => {
            navigate('/my-teams');
            setIsHome(false);
            setIsEvent(false);
            setIsFavourites(false);
            setIsTeams(true);
          }}
        >
                  <NetworkIcon height='34px' width='34px' color={ isTeams ? 'white' : '#000' } stroke={ isTeams ? 'white' : '#000' } strokeWidth={ isTeams ? '4px' : '4px'} />
        </Button>
        <Button
                  borderRadius={'full'}
                  paddingTop={'10px'}
                  paddingBottom={'12px'}
                  paddingLeft={'11px'}
          bgColor={isFavourites ? '#00A7BC' : 'none'}
                  _hover={ !isFavourites ? { bgColor: '#EEEEEE' } : { bgColor: '#00A7BC' } }
                  isDisabled={ user?.status === 'Deleted' }
          onPress={() => {
            navigate('/favorites');
            setIsHome(false);
            setIsEvent(false);
            setIsTeams(false);
            setIsFavourites(true);
          }}
        >
                  <FavouriteIcon size={'34px'} color={isFavourites  ? 'white' : '#000'} />
        </Button>
      </HStack>
      
    </>
  );
}

export default React.memo(Menu);
