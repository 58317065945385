import * as React from 'react';

const Add = ({ color }) => (
  <>
    <g id='Home-Page' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Artboard' transform='translate(-55.000000, -140.000000)'>
        <g id='add-24px' transform='translate(55.000000, 140.000000)'>
          <polygon id='Path' points='0 0 25.1851852 0 25.1851852 25.1851852 0 25.1851852'></polygon>
          <polygon
            id='Path'
            fill={color ? color : '#000000'}
            fillRule='nonzero'
            points='19.9382716 13.6419753 13.6419753 13.6419753 13.6419753 19.9382716 11.5432099 19.9382716 11.5432099 13.6419753 5.24691358 13.6419753 5.24691358 11.5432099 11.5432099 11.5432099 11.5432099 5.24691358 13.6419753 5.24691358 13.6419753 11.5432099 19.9382716 11.5432099'
          ></polygon>
        </g>
      </g>
    </g>
  </>
);

export default Add;
