import React, { FC, useState } from 'react';
import { Box, VStack, HStack, Text, Pressable, Button, Divider, CloseIcon, Image } from 'native-base';
import { Loc } from '../../App/Loc';
import AVInputtext from 'components/AVInputtext';
import { useWindowDimension } from 'Utility/WindowDimensions';
import * as AV from '../../AVCore/AVCore';
import _ from 'lodash';
import { AddIcon, EditIcon, DeleteIcon, CancelIcon } from '../../assets/icons/icon-assets';
import { Server } from '../../Platform/Server';
import CustomToast from '../../Hooks/useCustomToast';
import AVCancel from './AVCancel';


interface Props
{
    setAddLinkPopup : any | undefined;
    setLinksList : any | undefined;
    linkList : any;
    isCreateevent : any | undefined;
}

const AddandEditLinks : FC<Props> = ( { setAddLinkPopup, setLinksList, linkList, isCreateevent } ) =>
{
    const [ isLoadingField, setIsLoadingField ] = useState( false );
    const dimensions = useWindowDimension();
    const isMobile = dimensions?.width <= 500 || false;
    const [ openInput, setOpenInput ] = useState( false )
    const [ editEnable, setEditEnable ] = useState( false );
    const [ links, addLinks ] = useState<any>( "" )
    const [ editingItem, setEditingItem ] = useState<any>( [] );
    const [ error, setError ] = useState( '' );

    const handleaddfield = async () =>
    {
        setIsLoadingField( true );
        const isValidLink = /^(https?|ftp):\/\/[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/\S*)?$/;
        if ( !isValidLink?.test( links ) )
        {
            let clientError = { message: 'Invalid link format.' }
            CustomToast( { clientError } ); // Display error message using CustomToast
            setIsLoadingField( false );
            return; // Exit the function early if the link format is invalid
        }

        let data : AV.IURLLink = {
            id: editingItem?.id ? editingItem?.id : Server.generateUUID(),
            url: links,
            icon: ''
        };

        let val = await AV.AVBase.getURLLink( data );
        if ( editingItem?.id )
        {
            let index = linkList.findIndex( item => item.id === val?.id );
            linkList[ index ] = val;
            setLinksList( linkList );
        } else
        {
            if ( linkList == undefined )
            {
                linkList= [];
                linkList?.push( val )
                setLinksList( linkList )
            }
            else
            {
                setLinksList( [ ...linkList, val ] );
            }

        }
        setIsLoadingField( false );
        addLinks( '' );
        setOpenInput( false );
        setEditEnable( false );
        setEditingItem( '' );
    };

    const HandleDelete = ( item ) =>
    {
        const updatedItems = linkList.filter( x => x.id !== item?.id );
        setLinksList( updatedItems );
    };


    const HandleEdit = ( item ) =>
    {
        setEditingItem( item )
        addLinks( item?.url )
        setOpenInput( true )
        setEditEnable( true );
    }


    const handleAddLinks = ( e ) =>
    {
        e.preventDefault();
        addLinks( e?.target?.value );
        setError( '' ); // Reset error message when the user changes the input
    };

    return (

        <>
            <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ isCreateevent ? '-370px' : 0 } right={ 0 } top={ 0 } left={ 0 }>
                <div
                    style={ {
                        position: 'fixed',
                        top: '53%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: '#ffffff',
                        boxShadow:
                            '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)',
                        width: isMobile ? '88%' : isCreateevent ? '83%' : '39%',
                        borderRadius: '8px',
                        height: 'auto',
                    } }
                >
                    <VStack padding={ '24px' }>
                        <HStack justifyContent={ 'space-between' } width={ '100%' } alignItems={ 'center' }
                            marginTop={ '20px' }
                            borderBottomColor={ 'AVColor.grey' } >
                            <Text fontSize={ 30 }>{ Loc.currentLang.menu.addLink }</Text>
                            
                                <AVCancel callback={ setAddLinkPopup } color={ undefined } size={ undefined } />

                        </HStack>
                        {( linkList?.length < 1 || linkList == undefined)  && ( <HStack width={ '100%' } marginTop={ '24px' }>
                            <Box width={ '95%' } justifyContent="center">
                                <AVInputtext
                                    type={ 'text' }
                                    label={ Loc.currentLang.placeholderText.link }
                                    onChange={ handleAddLinks }
                                    value={ links }
                                    height={ '20px' }
                                    maxLength={ 500 } defaultValue={ undefined } width={ undefined } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined }/>
                            </Box>

                        </HStack> ) }


                        { linkList?.length >= 1 && ( <Pressable
                            width={ '100%' } marginTop={ '24px' }
                            onPress={ () => { setOpenInput( true ) } }
                        >
                            <HStack>
                                <Text color={ '#00A7BC' } fontWeight={ 500 }>+</Text>
                                <Text color={ '#00A7BC' } fontWeight={ 500 } >
                                    { Loc.currentLang.menu.addLink }</Text>
                            </HStack>
                        </Pressable> ) }
                        <VStack maxH={(linkList?.length > 0 ||  linkList == undefined) ? '' : '180px' } overflowY={ 'scroll' } width={ '100%' } marginTop={ '24px' }>
                            {(linkList?.length > 0 || linkList == undefined) && linkList?.reverse().map( ( item : any ) => (
                                <VStack marginBottom={ '15px' }>
                                    <HStack width={ '100%' } marginRight={ '10px' }>
                                        <HStack width={ '10%' } >
                                            <img
                                                src={ item?.icon?.trim()?.length > 0 ? item?.icon : "https://cdn-icons-png.flaticon.com/512/2985/2985013.png" } height={ '24px' } width={ '24px' } />
                                        </HStack>
                                        <HStack width={ '75%' } >
                                            <Text width={ '75%' } numberOfLines={ 1 } ellipsizeMode={ 'tail' } fontWeight={ 500 } fontSize={ '16px' }>
                                                { item?.url }
                                            </Text>
                                        </HStack>
                                        <HStack space={ 3 }>
                                            <Pressable onPress={ () => HandleEdit( item ) } >
                                                <EditIcon size='20px' />
                                            </Pressable>
                                            <Pressable onPress={ () => HandleDelete( item ) } >
                                                <DeleteIcon size='20px' />
                                            </Pressable>
                                        </HStack>
                                    </HStack>
                                    { item && ( <Divider width={ '100%' } marginTop={ '15px' } /> ) }
                                </VStack>
                            ) ) }
                        </VStack>
                        {(linkList?.length === 0 || linkList == undefined) && (
                            <HStack justifyContent={ 'center' } space={ '24px' } marginTop={ '24px' }>
                                <Button
                                    width={ '180px' }
                                    height={ '40px' }
                                    backgroundColor={ 'AVColor.primary' }
                                    rounded={ 'full' }
                                    isDisabled={ links?.trim().length === 0 }
                                    isLoading={ isLoadingField }
                                    onPress={ () =>

                                        handleaddfield() }
                                >
                                    <Text fontWeight={ 500 } color={ 'white' } >{ Loc.currentLang.menu.ok }</Text>
                                </Button>
                            </HStack>
                        ) }
                    </VStack>
                </div>
            </Box>
            { openInput && (
                <Box position='fixed' bgColor={ 'AVColor.overlay2' } bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        className={ `create_post_popup ${ isMobile && 'create_post_popup_mobile' }` }
                        style={ {
                            width: isMobile ? dimensions.width - 15 : '565px',
                            height: isMobile ? '650px' : '240px',
                            marginTop: '12px',
                        } }
                    >
                        <VStack width={ '100%' } padding={ '24px' }>
                            <HStack justifyContent={ 'space-between' } width={ '100%' } alignItems={ 'center' }
                                marginTop={ '20px' }
                                borderBottomColor={ 'AVColor.grey' }>
                                <Text fontSize={ '24px' }>{ editEnable ? Loc.currentLang.menu.editLink : Loc.currentLang.menu.addLink }</Text>
                  <AVCancel callback={ () => { setOpenInput(false); setEditEnable(false); addLinks(''); setEditingItem([]) } } color={ undefined } size={ undefined } />
     
                            </HStack>
                            <HStack width={ '100%' } marginTop={ '24px' }>
                                <Box width={ '95%' } justifyContent="center">
                                    <AVInputtext
                                        type={ 'text' }
                                        label={ Loc.currentLang.placeholderText.link }
                                        onChange={ handleAddLinks }
                                        value={ links }
                                        height={ '20px' }
                                        maxLength={ 500 } defaultValue={ undefined } width={ undefined } minRows={ undefined } multiline={ undefined } placeholder={ undefined } size={ undefined } />
                                </Box>                              
                            </HStack>
                            <HStack justifyContent={ 'center' } space={ '24px' } marginTop={ '24px' }>
                                <Button
                                    width={ '180px' }
                                    height={ '40px' }
                                    backgroundColor={ 'AVColor.primary' }
                                    rounded={ 'full' }
                                    isDisabled={ links?.trim().length === 0 }
                                    isLoading={ isLoadingField }
                                    onPress={ () =>

                                        handleaddfield() }
                                >
                                    <Text fontWeight={ 500 } color={ 'white' } >{ Loc.currentLang.menu.ok }</Text>
                                </Button>
                            </HStack>
                        </VStack>
                    </div>
                </Box>
            ) }
        </>
    )
}
export default AddandEditLinks;