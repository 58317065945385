import * as React from 'react';

const Notification = ({ color }) => (
  <>
    <path
      d='M18,13.18 L18,10 C17.9970353,7.07447929 15.8846041,4.57758553 13,4.09 L13,3 C13,2.44771525 12.5522847,2 12,2 C11.4477153,2 11,2.44771525 11,3 L11,4.09 C8.11539594,4.57758553 6.00296468,7.07447929 6,10 L6,13.18 C4.8042713,13.6027483 4.00356437,14.7317451 4,16 L4,18 C4,18.5522847 4.44771525,19 5,19 L8.14,19 C8.61339782,20.7419603 10.1948597,21.9510005 12,21.9510005 C13.8051403,21.9510005 15.3866022,20.7419603 15.86,19 L19,19 C19.5522847,19 20,18.5522847 20,18 L20,16 C19.9964356,14.7317451 19.1957287,13.6027483 18,13.18 Z M8,10 C8,7.790861 9.790861,6 12,6 C14.209139,6 16,7.790861 16,10 L16,13 L8,13 L8,10 Z M12,20 C11.2897779,19.9957131 10.6351046,19.6150891 10.28,19 L13.72,19 C13.3648954,19.6150891 12.7102221,19.9957131 12,20 Z M18,17 L6,17 L6,16 C6,15.4477153 6.44771525,15 7,15 L17,15 C17.5522847,15 18,15.4477153 18,16 L18,17 Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Notification;
