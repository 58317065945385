/*!
 * Copyright © 2021 Actavivo
 */
import { AVBase } from './AVBase';
import * as AV from './Interfaces/AV.Interfaces';
import { Server } from '../Platform//Server';

export class Feed extends AVBase {
  public constructor (public data : AV.IFeed) {
    super();
  }

  public static async getFeed (Params : AV.IFeedParam) : Promise<AV.IFeed> {
    return await Server.post<AV.IFeed>(this._api + 'Feed/Get', Params);
  }

  public static async getPosts (param : AV.IFK, ids : string[]) : Promise<AV.IPost[]> {

    let list : AV.IPost[] = await Server.post<AV.IPost[]>(this._api + 'Feed/GetPosts', { Feed: param, Ids: ids });
    return list;
  }
  public static getPostList (feed : AV.IFeed) : string[] {
    var retval : string[] = [];
    //followIds
    for (var i = 0; feed.followIds && i < feed.followIds.length; i++) {
      retval.push(feed.followIds[ i ].id);
      //@ts-ignore
      for (var g = 0; feed.followIds[ i ].gallery && g < feed.followIds[ i ].gallery.length; g++) {
        //@ts-ignore
        retval.push(feed.followIds[ i ].gallery[ g ]);
      }
    }
    // privateIds
    for (var i = 0; feed.privateIds && i < feed.privateIds.length; i++) {
      retval.push(feed.privateIds[ i ].id);
      //@ts-ignore
      for (var g = 0; feed.privateIds[ i ].gallery && g < feed.privateIds[ i ].gallery.length; g++) {
        //@ts-ignore
        retval.push(feed.privateIds[ i ].gallery[ g ]);
      }
    }
    // publicIds
    for (var i = 0; feed.publicIds && i < feed.publicIds.length; i++) {
      retval.push(feed.publicIds[ i ].id);
      //@ts-ignore
      for (var g = 0; feed.publicIds[ i ].gallery && g < feed.publicIds[ i ].gallery.length; g++) {
        //@ts-ignore
        retval.push(feed.publicIds[ i ].gallery[ g ]);
      }
    }

    return retval;
  }
  public static createPost (entity : AV.IAVEntity) : AV.IPost {
    //@ts-ignore
    let post : AV.IPost = { pk: entity.pk, region: entity.region };
    return post;
  }
}
