import { HappyIcon } from 'assets/icons/icon-assets';
import { Pressable } from 'native-base';
import React, { FC } from 'react';

interface Props {
  showEmoji: any;
}
const AVEmoji: FC<Props> = ({ showEmoji }) => {
  return (
    <Pressable
      onPress={showEmoji}

    >
          <HappyIcon size={30} color={'#FA6400'} />
    </Pressable>
  );
};

export default AVEmoji;
