import { Server } from "../Platform/Server";
export class Store {
    public static async Get(key: string): Promise<string> {
        let val = localStorage.getItem(key)
        if (!val && key === "USER") {
            val = await Server.get("/api/Person" + "/GetSelf");
            localStorage.setItem(key, JSON.stringify(val))
            return val ? val : "";
        }
        if (key === "MediaStore")
            return val ? val : "";
        return val ? JSON.parse(val) : "";
    }
    public static async GetObj<T>(key: string): Promise<T | undefined> {
        let so = await Store.Get(key);
        if (so)
            return JSON.parse(so);
        return undefined;
    }
    public static async Set(key: string, val: object) {
        localStorage.setItem(key, JSON.stringify(val))
    }
}