import React, { useState,useEffect } from 'react';
import { HStack, VStack, Box, Center, Image, Button } from 'native-base';
import { useDropzone } from 'react-dropzone';
import { AddPhotoIcon, Profile } from '../../assets/icons/icon-assets';
import * as AV from '../../AVCore/AVCore';
import { useSelector, RootStateOrAny } from 'react-redux';



const AVMascot = ({ Entity, setEntity, mascotImage, setMascotImage, isShowEdit }) => {
    const [MascotLoader, SetMascotLoader] = useState(false);
    const [mascot, setMascot] = useState<any>('');
    const currentUser = useSelector((state: RootStateOrAny) => state.user.user[0]);

    //const { getRootProps: getMascotPicFile, getInputProps: getMascotInputProps } = useDropzone({
    //    accept: 'image/*',
    //    onDrop: (acceptedFiles) => {
    //        
    //        setMascot(URL.createObjectURL(acceptedFiles[0]));
    //        getMedia(acceptedFiles).then((res) => {
    //            setEntity((orgFK) => {
    //                return { ...orgFK, mascotPic: res };
    //            });
    //        });
    //    },
    //});

    const { getRootProps: getMascotPicFile, getInputProps: getMascotInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if ( acceptedFiles.length > 0 && acceptedFiles[ 0 ].type.startsWith( 'image' ) )
            {
                SetMascotLoader( true );
                setMascotImage( URL.createObjectURL( acceptedFiles[ 0 ] ) );
            } else
            {
                // Handle non-image files
                // Implement your validation or error handling logic here
                console.log( "Unsupported file type. Please drop an image file." );
                SetMascotLoader( false );
                return;
            }
            getMedia(acceptedFiles).then((res) => {
                setMascotImage(res);
                setEntity((orgFK) => {
                    return { ...orgFK, mascot: res };
                } );
                setMascot( AV.Media.getURL( res ) )
            } );
             
        },
    });

    const getMedia = async (file: any) => {
        let iMedia = await AV.Media.saveFiles(AV.Media.getFormData(file), Entity?.region ? Entity?.region : currentUser?.region);
        SetMascotLoader( false );
        return iMedia[0];
    };
    useEffect(() => {
        setMascot(AV.Media.getURL(Entity?.mascot))
    }, [ Entity?.mascot, isShowEdit])
    return (
        <HStack width={'80%'} zIndex={-3}>
            <VStack zIndex={-3}>
                <HStack
                    fontWeight={'400'}
                    fontSize={'25px'}
                    marginBottom={'15px'}
                    zIndex={-3}
                >
                    {'Mascot'}
                </HStack>
                <Box mb={'5px'} width={200} zIndex={-3}>
                    <Center>
                        {(Entity?.mascot || mascot) && (
                            <Image
                                source={{
                                    uri: mascot,
                                }}
                                width={'170px'}
                                height={'170px'}
                                borderWidth={'5px'}
                                borderColor={'white'}
                                rounded={'full'}
                                alt=' '
                            />
                        )}

                        {(!Entity?.mascot || !mascot) && (
                            <Profile height={'170'} width={'170'} color={"#32AE78"} />
                        )}

                        <Button
                            bgColor={'AVColor.white'}
                            position={'absolute'}
                            height={'40px'}
                            width={'40px'}
                            right={'4px'}
                            bottom={'20px'}
                            rounded={'full'}
                            _hover={{ backgroundColor: 'AVColor.hovercolor' }}
                            shadow={1}
                            zIndex={99}
                            isLoading={MascotLoader}
                            _spinner={{ color: 'AVColor.black' }}
                        >
                            <Box
                                {...getMascotPicFile({
                                    className: 'dropzone',
                                })}
                            >
                                <input {...getMascotInputProps()} />
                                <AddPhotoIcon size={28} zIndex={-3} color={'black'} />
                            </Box>
                        </Button>
                    </Center>
                </Box>
            </VStack>
        </HStack>
    )

}
export default AVMascot;