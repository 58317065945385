import React from 'react';
import { Server } from '../Platform/Server';
import _ from 'lodash';
import { SSE } from './SSE';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setUser, setLastFeedUpdate } from '../Redux/Actions/userActions';
import * as AV from '../AVCore/AVCore';
import { setConvo } from 'Redux/Actions/convoActions';

function useInitSSE (  )
{
    const dispatch = useDispatch();
    var sse;
    var fetchInterval;
    const CurrentUser = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const deviceFCMToken = useSelector( ( state : RootStateOrAny ) => state.user.devicefcmtoken );
    const notificationEnabled = useSelector( ( state : RootStateOrAny ) => state.user.notificationEnabled );
    const currentDateTime : any = new Date();
    const lastUpdateFeed = useSelector( ( state : RootStateOrAny ) => state.user.lastUpdateFeed );
    const convoval = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );

    const fetchAlertsOnfocus = async ( notificationEnabled ) =>
    {
        await ( await SSE() ).fetchAlertsOnfocus( notificationEnabled );
    };

    const onfocus = async () =>
    {
        console.log( 'on Focus...' );
        console.log( 'deviceFCMToken.....', deviceFCMToken );
        if ( deviceFCMToken != undefined )
        {
            fetchAlertsOnfocus( notificationEnabled );
        }
    };

    const fetchSelfOnfocus = async () =>
    {
        let person = await AV.Person.getSelf( true );
        console.log( "person......", person );
        console.log( "CurrentUser_etag......", CurrentUser._etag );
        console.log( "GetSelf._etag......", person._etag );
        console.log( "person......", person );
        if ( CurrentUser._etag != person._etag )
            dispatch( setUser( person ) );
    };

    const handlegetfeed = async ( item ) =>
    {
        console.log( "getfeed" )
       
        let convovalue : AV.IConversation[] = [];
        let ConvovalList = convoval;
        await AV.Feed.getFeed( item ).then( ( response : any ) =>
        {
            let data : any = {
                Ifeed: item,
                lasttime: new Date()
            };
            let Index = lastUpdateFeed?.findIndex((x) => x?.Ifeed?.feed?.id == item?.feed?.id)
            if (Index !== -1) {
                lastUpdateFeed[ Index ] = data
            }
            dispatch(setLastFeedUpdate(lastUpdateFeed));
            let PostList = AV.Feed.getPostList( response );
            if ( PostList?.length > 0 )
            {
                AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList ).then( ( x : any ) =>
                {
                    if ( x?.length > 0 )
                    {
                        x.sort( ( a : any, b : any ) =>
                        {
                            const createDateA = new Date( a.createDate ) as any;
                            const createDateB = new Date( b.createDate ) as any;
                            return createDateB - createDateA;
                        } );
                        response.posts = x;
                        if ( response?.posts !== undefined && response?.posts?.length > 0 ) convovalue.push( response );
                        Convoupdate( convovalue, ConvovalList );

                    }
                } );
            }
        } );
    }

    const Convoupdate = ( convovalue, ConvovalList ) =>
    {
        if ( ConvovalList?.length === 0 || ConvovalList === undefined || ConvovalList === null ) ConvovalList = [];
        for ( let i = 0; i < convovalue?.length; i++ )
        {
            let excitingconvo = ConvovalList?.filter( ( x ) => x?.id == convovalue[ i ]?.id );
            if ( excitingconvo?.length > 0 )
            {
                for ( let j = 0; j < convovalue[ i ]?.posts?.length; j++ )
                {
                    let excitingconvopost = excitingconvo[ 0 ]?.posts?.filter(
                        ( x ) => x?.id === convovalue[ i ]?.posts[ j ]?.id
                    );
                    if ( excitingconvopost?.length === 0 )
                    {
                        excitingconvo[ 0 ]?.posts.push( convovalue[ i ]?.posts[ j ] );
                    }
                }
            } else ConvovalList.unshift( convovalue[ i ] );
        }
        ConvovalList = _.uniqBy( ConvovalList, 'id' );
        dispatch( setConvo( ConvovalList ) );
    };

    const stopFetchingSelfOnEvery5mins = () =>
    {
        clearInterval( fetchInterval );
    };

    const StartSSE = async () => {
        console.log("Call Start SSE function...");
        if (!Server.SSE) {
            console.log("Start New SSE...");
            await (await SSE()).SSEupdate();
        }
    };

    const CloseSSE = async () =>
    {
        console.log("Call Close SSE function...");
        if ( Server.SSE )
        {
            console.log("Close existing SSE...");
            await Server.SSE.close();
            Server.SSE = null;
        }
    };

    React.useEffect( () =>
    {
        let isMounted = true;
        let closeTimer;
        let isFocused = false;
        const handleVisibilityChange = () =>
        {
            if ( document.visibilityState === 'visible' && !isFocused )
            {
                console.log( 'App in focus' );
                isFocused = true;

                onfocus();
                if ( lastUpdateFeed?.length > 0 )
                {
                    lastUpdateFeed.forEach( ( item : any ) =>
                    {
                        const lastUpdateTime : any = new Date( item.lasttime );
                        const timeDifference : number = ( currentDateTime.getTime() - lastUpdateTime.getTime() ) / ( 1000 * 60 );
                        if ( timeDifference < 60 && notificationEnabled )
                        {
                            item.Ifeed.startDate = item.lasttime;
                            handlegetfeed( item.Ifeed );
                        }
                    } );
                }
                if (lastUpdateFeed == undefined) {
                    for (let i = 0; i < convoval.length; i++) {
                        handlegetfeed(convoval[i]);
                    }
                }
                console.log( "onfocus call" );
                clearTimeout( closeTimer ); // Clear the close timer if the tab becomes visible
            } else if ( document.visibilityState !== 'visible' )
            {              
                console.log('App not in focus');
                isFocused = false;       
                //closeTimer = setTimeout( () =>
                //{
                //    console.log( sse );
                //    console.log('App not in focus');
                //    isFocused = false;                   
                //   // stopFetchingSelfOnEvery5mins(); // Stop fetching when app is not visible
                //}, 30000 );
            }
        };

        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);
     
        return () =>
        {
            // Clean up the event listener and clear the close timer when the component unmounts
            document.removeEventListener( 'visibilitychange', handleVisibilityChange );
            clearTimeout( closeTimer );
            //stopFetchingSelfOnEvery5mins(); // Ensure to stop the interval when the component unmounts
            isMounted = false;
        };
    }, [] );

    return {
        StartSSE,
        CloseSSE,
    };
}

export default useInitSSE;
