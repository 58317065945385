import React, { useState, useEffect } from 'react';

export function useWindowDimension () {
    const [ dimension, setDimension ] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
            setDimension({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }, 100); // 100ms
        window.addEventListener('resize', debouncedResizeHandler);
        return () => window.removeEventListener('resize', debouncedResizeHandler);
    }, []); // Note this empty array. this effect should run only on mount and unmount
    return dimension;
}

function debounce (fn : (...args : any[]) => void, ms : number) {
    let timer;
    return function (this : any, ...args : any[]) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, args);
        }, ms);
    };
}


