import { extendTheme } from 'native-base';

const AVTheme = extendTheme({
    colors: {
        AVColor: {
            primary: '#32AE78',
            secondary: '#00A7BC',
            yellow: '#F5E732',
            gradient0: '#00A7BC',
            gradient100: '#F5E732',
            bodyBg: '#F8F8F8',
            searchBg: '#EEEEEE',
            white: '#ffffff',
            black: '#000000',
            grey: '#00000033',
            inputBg: '#EEEEEE',
            lightGrey: '#00000080',
            white85: 'rgba(255, 255, 255, 0.85)',
            overlay: 'rgba(8, 7, 7, 0.85)',
            overlay2: 'rgba(0,0,0,0.6)',
            gradient: {
                1: '#0590A1',
                2: '#11A766',
            },
            secondaryText: '#65676b',
            primaryText: '#050505',
            textLite: '#979797',
            buttonBg: '#e4e6eb',
            liteBg: '#eeeeee',
            bgcolor: '#f3f7ff',
            hovercolor: '#e4e6eb',
            hovercolor2: '#eeeeee',
            iconbg: '#eee',
            skeletonStart: '#E0E0E0',
            skeletonDark: '#9E9E9E',
        },
    },

    fontConfig: {
        Roboto: {
            100: {
                normal: 'Roboto-Light',
                italic: 'Roboto-LightItalic',
            },
            200: {
                normal: 'Roboto-Light',
            },
            300: {
                normal: 'Roboto-Light',
            },
            400: {
                normal: 'Roboto-Regular',
            },
            500: {
                normal: 'Roboto-Regular',
            },
            600: {
                normal: 'Roboto-Regular',
            },
        },
    },
    fonts: {
        // heading: "Roboto",
        // body: "Roboto",
        //  mono: "Roboto",
    },
    sizes: {
        AVIconXs: 14,
        AVIconSmall: 24,
        AVIconMedium: 32,
        AVNativePadding: 21,
        AVAppWidth: 1200,
        headerMobile: 55,
        headerWeb: 60,
        appMaxWidth: 1366,
        borderRadius: 5,
    },
    lineHeights: {},
    config: {
        // Changing initialColorMode to 'dark'
        initialColorMode: 'light',
    },
    //components
    components: {
        Text: {
            defaultProps: {
                //fontSize: 'md',
                //fontFamily: 'Roboto',
                //color: 'rgba(0,0,0,0.85)',
            },
            variants: {
                AVLandingHeaderText: { //Landing page Header text
                    fontSize: '36px',
                    fontWeight: 500,
                    color: 'AVColor.white',
                    lineHeight: '36px',
                    letterSpacing: '0.2px'
                },
                AVLandingTitleText: { //Landing page Title text
                    fontSize: '24px',
                    color: 'AVColor.white',
                    lineHeight: '24px'
                },
                AVLandinglinetext: { //Landing page Line text
                    fontSize: '16px',
                    color: 'AVColor.white',
                    lineHeight: '16px'
                },
                AVHeaderText: {
                    fontSize: '24px',
                    lineHeight: '24px'
                },
                AVTitleText: {
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '20px'
                },
                AVlinetext: {
                    fontSize: '16px',
                    lineHeight: '16px'
                },
                AVSearchTitleText: {
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '20px'
                },
                AVSearchlinetext: {
                    fontSize: '16px',
                    lineHeight: '16px',
                    color: "#00A7BC"
                },
            },
        },
        Box: {
            variants: {
                AVAppHeader: {
                    flexDirection: 'row',
                    backgroundColor: 'AVColor.white',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    py: 2,
                    px: [ 2, 4, 8 ],
                    shadow: 2,
                    borderTopWidth: 3,
                    borderTopColor: 'AVColor.primary',
                },
                AVSearch: {
                    flexDirection: 'row',
                    bg: [ 'none', 'none', 'none', 'AVColor.searchBg' ],
                    rounded: 'full',
                    alignItems: 'center',
                },
                AVCard: {
                    overflow: 'hidden',
                    borderColor: 'coolGray.200',
                    borderWidth: '1',
                    backgroundColor: 'AVColor.white',
                    rounded: 'lg',
                    shadow: '3',
                    mb: '5',
                },
                AVFeed: {
                    overflow: 'hidden',
                    borderColor: 'coolGray.200',
                    borderWidth: '1',
                    backgroundColor: 'AVColor.white',
                    rounded: 'lg',
                    shadow: '3',
                    mb: '5',
                },
                AVAction: {
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '70%',
                    mx: 'auto',
                    mt: '16px',
                    mb: '29px',
                    cursor: 'pointer',
                },
            },
        },
        Input: {
            variants: {
                AVSearchInput: {
                    color: 'AVColor.black',
                    placeholderTextColor: 'AVColor.black',
                },
                AVCommentInput: {
                    color: 'AVColor.black',
                    placeholderTextColor: 'AVColor.black',
                },
            },
        },
        Button: {
            variants: {
                AVButton: {
                    backgroundColor: 'AVColor.searchBg',
                    _text: {
                        color: 'AVColor.secondary',
                    },
                    alignSelf: 'left',
                },
                AVOutlineButton: {
                    bgColor: 'AVColor.primary',
                    borderColor: 'AVColor.white',
                    borderWidth: 1,
                    borderRadius: 20,
                    width: '15%',
                    height: '53px',
                    _text: {
                        color: 'AVColor.white',
                        fontSize: '16px',
                        fontWeight: 500,
                        textAlign: 'center',
                        lineHeight: '19px'
                    }
                },
                AVOutlineMobileButton: {
                    bgColor: 'AVColor.primary',
                    borderColor: 'AVColor.white',
                    borderWidth: 1,
                    borderRadius: 20,
                    width: '46%',
                    height: '53px',
                    _text: {
                        color: 'AVColor.white',
                        fontSize: '16px',
                        fontWeight: 500,
                        textAlign: 'center',
                        lineHeight: '19px'
                    }
                },
            },
        },
        IconButton: {
            variants: {
                AVMenuButton: () => {
                    return {
                        rounded: 'full',
                        width: 55,
                        height: 55,
                        mr: '3',
                        justifyContent: 'center',
                        alignItems: 'center',
                        _hover: {
                            bg: 'AVColor.secondary',
                            _icon: {
                                color: '#fff',
                            },
                        },
                        _pressed: {
                            bg: 'AVColor.secondary',
                            _icon: {
                                color: '#fff',
                            },
                        },
                        _focus: {
                            bg: 'AVColor.secondary',
                            _icon: {
                                color: '#fff',
                            },
                        },
                    };
                },
                AVUserMenuButton: () => {
                    return {
                        rounded: 'full',

                        width: '40px',
                        height: '40px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bg: 'AVColor.yellow',
                        size: [ 20, 30, 40, 50 ],
                        _focus: {
                            bg: 'AVColor.yellow',
                        },
                        _hover: {
                            bg: 'AVColor.yellow',
                        },
                    };
                },
            },
        },
        Heading: {
            baseStyle: ({ colorMode }) => {
                return {
                    // color: colorMode === "dark" ? "secondary.300" : "primary.500",
                    color: 'AVColor.primary',
                    fontWeight: 'semibold',
                    fontSize: '24px',
                    lineHeight: '3xl',
                    letterSpacing: 'lg',
                };
            },
        },
    },
});

export default AVTheme;

