import * as React from 'react';

const Search = ({ color }) => (
  <>
    <path
      d='M20.7472592,19.4825322 L17.4102457,16.1699879 C20.0998372,12.8139167 19.6991785,7.9392812 16.4977569,5.06814338 C13.2963352,2.19700557 8.41077866,2.3307934 5.37104004,5.37284137 C2.33130142,8.41488935 2.19761516,13.3041576 5.06657336,16.5080114 C7.93553157,19.7118652 12.8064665,20.1128283 16.1599899,17.4211935 L19.4700195,20.7337378 C19.6389089,20.9041471 19.8688084,21 20.1086394,21 C20.3484704,21 20.5783698,20.9041471 20.7472592,20.7337378 C21.0842469,20.3848435 21.0842469,19.8314264 20.7472592,19.4825322 Z M11.1139937,17.4211935 C7.63666981,17.4211935 4.81774179,14.6001238 4.81774179,11.1201581 C4.81774179,7.64019239 7.63666981,4.81912277 11.1139937,4.81912277 C14.5913177,4.81912277 17.4102457,7.64019239 17.4102457,11.1201581 C17.4102457,12.7912966 16.7468925,14.3939896 15.5661162,15.5756629 C14.3853399,16.7573363 12.7838636,17.4211935 11.1139937,17.4211935 Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Search;
