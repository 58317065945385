import React, { useState, useEffect, useRef } from 'react'
import { VStack, Text, HStack, Box, Input, Image, Pressable, Button, Tooltip, Divider } from 'native-base'
import { AddPhotoIcon, MoreHorizontalIcon, Profile } from '../../assets/icons/icon-assets';
import * as AV from "../../AVCore/AVCore";
import { IPlayer } from '../../AVCore/Interfaces/AV.Interfaces';
import { AVEntity } from "../../AVCore/AVEntity";
import { Media } from '../../AVCore/Media';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { setUser } from '../../Redux/Actions/userActions';
import { Loc } from '../../App/Loc';

function PlayersCard ( { leagueTeam, data, setPlayersList, isAdminView, HandleRemoveplayers } )
{
    const dispatch = useDispatch();
    let item = data?.item;
    const [ isPicLoader, setIsPicLoader ] = useState( false );
    let LeagueTeam = leagueTeam;
    const currentUser = useSelector( ( state : RootStateOrAny ) => state.user.user[ 0 ] );
    const navigate = useNavigate();
    const [ isEditPlayerDetails, setIsEditPlayerDetails ] = useState( false );
    const [ jersyNumber, setJersyNumber ] = useState( item?.jerseyNumber );
    const [ playerPosition, setPlayerPosition ] = useState( item?.position );
    const [ isdelete, setIsDelete ] = useState( false );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ isCurrentUser, setCurrentUser ] = useState( false );
    const [ isOpen, setIsOpen ] = useState( false );
    const [ isMemberData, setIsMemberData ] = useState( '' );
    const [ isSlienceLoad, setIsSlienceLoad ] = useState( false );
    const [ unSlienceLoad, setIsUnSlienceLoad ] = useState( false )
    const [ RemoveMemberLoad, setIsRemoveMembetLoad ] = useState( false );
    var sourceFK : any = AV.AVEntity.getFk( currentUser );
    const baseUrl = 'https://ust-store.actavivo.com/thumb/';
    const imagePath = item?.thumb;
    const fullUrl = `${ baseUrl }${ imagePath }`;
    const [ playerProfile, setPlayerProfile ] = useState<any>( item?.linkPic?.id?.trim()?.length > 0 ? AV.Media.getURL( item?.linkPic ) : fullUrl )
    const menuRefs = useRef( null );
    useOutsideAlerter( menuRefs );

    //Add Jersy Number
    const handleJersyNumber = ( e ) =>
    {
        e.preventDefault();
        setJersyNumber( e.target.value )
    }
    //Add player position
    const handlePlayerPosition = ( e ) =>
    {
        e.preventDefault();
        setPlayerPosition( e.target.value )
    }
    //getMedia
    const getMedia = async ( file ?: any ) =>
    {
        let iMedia = await Media.saveFiles(
            AV.Media.getFormData( file ),
            item?.region
        );
        return iMedia[ 0 ];
    };
    //Add ProfilePic
    const { getRootProps: getProfilePicFile, getInputProps: getProfileInputProps } = useDropzone( {
        accept: 'image/*',
        onDrop: ( acceptedFiles : any ) =>
        {
            setIsPicLoader( true );
            setPlayerProfile( URL.createObjectURL( acceptedFiles[ 0 ] ) );
            getMedia( acceptedFiles )
                .then( ( res ) =>
                {
                    let player : IPlayer = item;
                    player.linkPic = res;
                    setIsPicLoader( false );

                } )
        },
    } );
    //Add slience
    const HandleSlience = async ( item ) =>
    {
        setIsSlienceLoad( true );
        await AVEntity.silence( sourceFK, item );
        let userUpdate = await AV.Person.getSelf();
        if ( userUpdate?.id )
            dispatch( setUser( userUpdate ) );
        setIsSlienceLoad( false );
        setIsOpen( !isOpen )
    }
    //Remove slience
    const HandleUnSlience = async ( item ) =>
    {
        setIsUnSlienceLoad( true )
        await AVEntity.UnSilence( sourceFK, item );
        let userUpdate = await AV.Person.getSelf();
        if ( userUpdate?.id )
            dispatch( setUser( userUpdate ) );
        setIsUnSlienceLoad( false )
        setIsOpen( !isOpen )
    }
    //save player
    const handleUpdatePlayer = async () =>
    {
        setIsLoading( true );
        let player : IPlayer = item;
        player.jerseyNumber = jersyNumber
        player.position = playerPosition;
        LeagueTeam = await AV.Team.updatePlayer( AVEntity.getFk( LeagueTeam ), player );
        if ( LeagueTeam !== null )
        {
            setPlayersList( LeagueTeam?.players );
        }
        setIsEditPlayerDetails( false );
        setIsLoading( false );
    }
    //outside Click Close
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {

                }
            }
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    //UseEffect
    useEffect( () =>
    {
        if ( LeagueTeam?.staff )
        {
            if ( LeagueTeam?.staff.length > 0 )
            {
                var getstafflist = LeagueTeam?.staff.filter( ( x ) => x?.id === currentUser?.id );
                if ( getstafflist.length > 0 )
                {
                    setCurrentUser( true )
                }
                else
                {
                    setCurrentUser( false );
                }
            } else
            {
                setCurrentUser( false );
            }
        }
    }, [ leagueTeam ] )
    return (
        <>
            { !isdelete &&
                <Box key={ item.id }
                    bgColor={ '#fff' }
                    borderWidth={ '1px' }
                    borderColor={ 'gray.400' }
                    width={ '235px' }
                    height={ '240px' }
                    borderRadius={ 10 }
                    m={ '12px' }
                    shadow={ 1 } >
                    <VStack ref={ menuRefs } width={ '100%' } height={ '100%' }>
                        <HStack width={ '100%' } height={ '100%' }>
                            <VStack width={ '100%' } height={ '100%' }>
                                <Pressable
                                    marginTop={ '31px' }
                                    key={ item.id }
                                    width={ '100%' } height={ '100%' }
                                    onPress={ () =>
                                    {
                                        if ( !isEditPlayerDetails )
                                        {
                                            navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                state: {
                                                    user: {
                                                        id: item?.id,
                                                        pk: item?.pk,
                                                        region: item?.region,
                                                        type: item?.type,
                                                        name: item?.title,
                                                    },
                                                },
                                            } );
                                        }
                                    } }
                                >
                                    { isAdminView && ( <Pressable position={ 'absolute' } right={ '15px' } top={ '10px' }
                                        onPress={ () => { setIsOpen( !isOpen ); setIsMemberData( item ) } }
                                    >
                                        <MoreHorizontalIcon size={ '24px' } color={ "gray.600" } style={ { transform: [ { rotate: '90deg' } ] } } />
                                    </Pressable> ) }
                                    <VStack alignItems={ "center" } padding={ 2 } position={ 'absolute' } left={ '10px' } overflow="hiden" >
                                        { !isEditPlayerDetails &&
                                            <Text color={ "black" } lineHeight={ "1.5em" } fontWeight={ "bold" } fontSize={ "25px" } width={ '47px' } text-overflow={ 'ellipsis' } numberOfLines={ 1 }>{ item?.jerseyNumber ? item?.jerseyNumber : '' }</Text>
                                        }
                                        { isEditPlayerDetails && leagueTeam?.subType !== "Class" && (
                                            <Input placeholder="Number"
                                                width={ '45px' }
                                                ref={ menuRefs }
                                                height={ "30px" }
                                                fontSize={ "14px" }
                                                fontWeight={ 400 }
                                                textAlign={ "center" }
                                                defaultValue={ item?.jerseyNumber }
                                                onChange={ handleJersyNumber }
                                            />

                                        )
                                        }
                                    </VStack>
                                    { ( isOpen && isMemberData == item ) && ( <VStack
                                        width={ '128px' }
                                        height='auto'
                                        shadow={ 2 }
                                        background={ '#FFFFFF' }
                                        position={ 'absolute' }
                                        top={ '40px' }
                                        right={ '40px' }
                                        zIndex={ 999 }
                                        borderRadius={ '6px' }
                                    >
                                        { currentUser?.silenced?.filter( x => x?.id == item?.id )?.length == 0 ?
                                            <Button
                                                width={ '100%' }
                                                height={ '36px' }
                                                alignItems={ 'center' }
                                                justifyContent={ 'center' }
                                                backgroundColor={ isSlienceLoad ? 'gray.400' : 'white' }
                                                isLoading={ isSlienceLoad }
                                                onPress={ () => HandleSlience( item ) }

                                            >
                                                <Text
                                                    fontSize={ '14px' }
                                                    fontFamily={ 'Roboto' }
                                                >
                                                    Silence
                                                </Text>
                                            </Button>
                                            : <Button
                                                width={ '100%' }
                                                height={ '36px' }
                                                alignItems={ 'center' }
                                                justifyContent={ 'center' }
                                                isLoading={ unSlienceLoad }
                                                backgroundColor={ unSlienceLoad ? 'gray.400' : 'white' }
                                                onPress={ () => HandleUnSlience( item ) }

                                            >
                                                <Text
                                                    fontSize={ '14px' }
                                                    fontFamily={ 'Roboto' }
                                                >
                                                    UnSilence
                                                </Text>
                                            </Button> }
                                        <Divider />
                                        <Button
                                            width={ '100%' }
                                            height={ '36px' }
                                            alignItems={ 'center' }
                                            justifyContent={ 'center' }
                                            isLoading={ RemoveMemberLoad }

                                            backgroundColor={ RemoveMemberLoad ? 'gray.400' : 'white' }
                                            onPress={ () => HandleRemoveplayers( item ) }

                                        >
                                            <Text
                                                fontSize={ '14px' }
                                                fontFamily={ 'Roboto' }
                                            >
                                                Delete
                                            </Text>
                                        </Button>
                                        <Divider />
                                        <Button
                                            width={ '100%' }
                                            height={ '36px' }
                                            alignItems={ 'center' }
                                            justifyContent={ 'center' }
                                            backgroundColor={ 'white' }
                                            onPress={ () => { setIsEditPlayerDetails( true ); setIsOpen( false ) } }

                                        >
                                            <Text
                                                fontSize={ '14px' }
                                                fontFamily={ 'Roboto' }
                                            >
                                                Edit
                                            </Text>
                                        </Button>
                                    </VStack> ) }
                                    { ( item?.thumb || item?.linkPic ) ? (
                                        <Box
                                            borderRadius={ [ 'full' ] }
                                            width={ '88px' }
                                            height={ '88px' }
                                            left={ '1px' }
                                            alignSelf={ 'center' }
                                            p={ 0 }
                                        >
                                            <Image
                                                source={ {
                                                    uri: playerProfile,
                                                } }
                                                borderRadius={ [ 'full' ] }
                                                //top={ "2" }
                                                width={ '100%' }
                                                height={ [ '100%' ] }
                                                alignSelf={ 'center' }
                                            />
                                            { isEditPlayerDetails && <Button
                                                bgColor={ 'AVColor.white' }
                                                position={ 'absolute' }
                                                top={ '45px' }
                                                right={ '-6px' }
                                                p={ 0 }
                                                rounded={ 'full' }
                                                _hover={ { backgroundColor: 'AVColor.hovercolor' } }
                                                shadow={ 1 }
                                                zIndex={ 99 }
                                                isLoading={ isPicLoader }
                                                _spinner={ { color: 'AVColor.black' } }
                                            >
                                                <Box { ...getProfilePicFile( { className: 'dropzone' } ) }>
                                                    <input { ...getProfileInputProps() } />
                                                    <AddPhotoIcon size={ 18 } p={ 1 } />
                                                </Box>
                                            </Button> }
                                        </Box>
                                    ) : (
                                        <Box
                                            alignItems={ 'center' }
                                            zIndex={ -3 }
                                        >
                                            <Profile height={ '88' } width={ '88' } color={ "#32AE78" } />
                                        </Box>
                                    ) }
                                    <VStack padding={ 2 } alignItems={ 'center' } marginTop={ '10px' }>
                                        <Tooltip label={ item.title } openDelay={ 500 }>
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                        state: {
                                                            user: {
                                                                id: item?.id,
                                                                pk: item?.pk,
                                                                region: item?.region,
                                                                type: item?.type,
                                                                name: item?.title,
                                                            },
                                                        },
                                                    } );
                                                } }
                                            >
                                                <Text marginLeft={ 5 } fontWeight={ 'medium' } fontSize={ '17px' } textTransform={ 'capitalize' } width={ '160px' } text-overflow={ 'ellipsis' } numberOfLines={ 1 }>{ item.title }</Text>
                                            </Pressable>
                                        </Tooltip>
                                        { !isEditPlayerDetails &&
                                            <Text marginLeft={ 5 } fontWeight={ 'medium' } fontSize={ '14px' } textTransform={ 'capitalize' } width={ '160px' } text-overflow={ 'ellipsis' } numberOfLines={ 1 }>{ item?.position ? item?.position : '' }</Text>
                                        }
                                          { isEditPlayerDetails && leagueTeam?.subType !== "Class" &&
                                            <Input placeholder="Position"
                                                width={ "100%" }
                                                zIndex={ 999 }
                                                ref={ menuRefs }
                                                height={ "28px" }
                                                overflowY={ "hidden" }
                                                fontSize={ "14px" }
                                                fontWeight={ "400" }
                                                textAlign={ "center" }
                                                defaultValue={ item?.position }
                                                onChange={ handlePlayerPosition }
                                            />

                                        }
                                    </VStack>
                                    <HStack left={ "40px" } top={ item?.position ? "5px" : "25px" } ref={ menuRefs }>
                                        { isEditPlayerDetails &&
                                            <>
                                                <HStack space={ 3 }>
                                                    <Button
                                                        ref={ menuRefs }
                                                        bgColor={ "AVColor.primary" }
                                                        borderRadius={ 'full' }
                                                        shadow={ 1 }
                                                        top={ item?.position ? "-8px" : "-26px" }
                                                        minWidth={ '80px' }
                                                        maxHeight='30px'
                                                        isLoading={ isLoading }
                                                        alignContent={ "space-around" }
                                                        onPress={ handleUpdatePlayer }
                                                    >
                                                        { Loc.currentLang.menu.save }
                                                    </Button>
                                                    <Button
                                                        ref={ menuRefs }
                                                        bgColor={ "gray.100" }
                                                        borderRadius={ 'full' }
                                                        shadow={ 1 }
                                                        top={ item?.position ? "-8px" : "-26px" }
                                                        minWidth={ '80px' }
                                                        maxHeight='30px'
                                                        alignContent={ "space-around" }
                                                        onPress={ () => setIsEditPlayerDetails( false ) }
                                                    >
                                                        <Text color={ 'black' }>{ Loc.currentLang.menu.cancel }</Text>
                                                    </Button>
                                                </HStack>
                                            </>
                                        }
                                    </HStack>

                                </Pressable>

                            </VStack>
                        </HStack>
                    </VStack>
                </Box>
            }
        </>
    );
}

export default React.memo( PlayersCard );
