import {
  Configuration,
  PublicClientApplication,
  LogLevel,
  SilentRequest,
  AccountInfo,
  AuthenticationResult,
} from '@azure/msal-browser';
//@ts-ignore

import settings from './settings.json';

export class Auth {
    // MSAL configuration
    static apiConfig = {
        b2cScopes: [ 'https://ActavivoAuth.onmicrosoft.com/API/AV.RW' ],
    };

    static b2cPolicies = {
        names: {
            signUpSignIn: 'B2C_1_signUpIn',
            editProfile: 'B2C_1_edit_profile_v2',
        },
        authorities: {
            signUpSignIn: {
                authority: 'https://ActavivoAuth.b2clogin.com/ActavivoAuth.onmicrosoft.com/B2C_1_signUpIn',
            },
            editProfile: {
                authority:
                    'https://ActavivoAuth.b2clogin.com/ActavivoAuth.onmicrosoft.com/B2C_1_edit_profile_v2',
            },
        },
        authorityDomain: 'ActavivoAuth.b2clogin.com',
    };

    static configuration : Configuration = {
        auth: {
            clientId: '54ddd835-f775-4232-b292-183f36d472af',
            redirectUri: window.location.origin,
            authority: Auth.b2cPolicies.authorities.signUpSignIn.authority,
            knownAuthorities: [ Auth.b2cPolicies.authorityDomain ],
        },
        cache: {
            cacheLocation: 'localStorage',
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            //console.info(message);
                            return;
                        case LogLevel.Verbose:
                            //console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
            },
        },
    };

    static pca : PublicClientApplication = new PublicClientApplication(Auth.configuration);
    static result ?: AuthenticationResult = undefined;
    public static getLoginRequest () {
        return {
            scopes: [ ...Auth.apiConfig.b2cScopes ],
            authority: Auth.b2cPolicies.authorities.signUpSignIn.authority,
        };
    }
    public static getTokenRequest (force : boolean = false, acc ?: AccountInfo) : SilentRequest {
        return {
            scopes: [ ...Auth.apiConfig.b2cScopes ],
            account: acc ?? Auth.currentUser() ?? undefined,
            forceRefresh: force, // Set this to "true" to skip a cached token and go to the server to get a new token
        };
    }

    public static async init () {
        if (!Auth.result && Auth.currentUser()) await Auth.refreshToken();
        setTimeout(async () => {
            await Auth.refreshToken();
        }, 60000);
    }

    public static isLoggedIn () : boolean {
        if (Auth.result) {
            return true;
        }
        return false;
    }
    public static isAppAuth () : boolean {
        //@ts-ignore
        if (Auth.result && Auth.result.idTokenClaims?.extension_PersonId) {
            return true;
        }
        return false;
    }

    public static async logout () {
        await localStorage.clear();
        // Auth.result = undefined;
        // window.location.href = window.location.origin;
        await Auth.pca.logoutRedirect({
            postLogoutRedirectUri: window.location.origin,
        });
    }
    public static async signIn () {
        await Auth.pca.loginRedirect(Auth.getLoginRequest());
    }

    public static async refreshToken (force : boolean = false) {
        try {
            Auth.result = await Auth.pca.acquireTokenSilent(Auth.getTokenRequest(force));
            return;
        } catch { }
        try {
            Auth.result = await Auth.pca.acquireTokenSilent(Auth.getTokenRequest(true));
            return;
        } catch { }
    }
    public static async getToken () {
        if (!Auth.result && Auth.currentUser()) {
            await Auth.refreshToken();
        }
        const currentDateTime = new Date();
        if (Auth.result?.expiresOn && Auth.result?.expiresOn < currentDateTime) {
            await Auth.refreshToken(true);
        }
        var acc = this.currentUser();
        //@ts-ignore
        if (!Auth.isAppAuth()) {
            try {
                Auth.result = await Auth.pca.acquireTokenSilent(Auth.getTokenRequest(true, acc));
            } catch {
                await Auth.logout();
                await Auth.signIn();
            }
        }
        return Auth.result?.accessToken;
    }

    public static currentUser () : AccountInfo {
        try {
            if (Auth.result?.account) return Auth.result?.account;

            let acc = Auth.pca.getActiveAccount();
            if (acc) return acc;
        } catch { }

        let acc = this.pca.getAllAccounts()[ 0 ];
        //Auth.pca.setActiveAccount(acc);

        return acc;
    }
    public static isValidClaim () : boolean {
        //@ts-ignore
        if (Auth.result && (Auth.result.idTokenClaims?.extension_PersonId || Auth.result.idTokenClaims?.emails.length > 0)) {
            return true;
        }
        else {
            Auth.signIn();
        }
        return false;
    }
}
