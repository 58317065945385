/*!
 * Copyright © 2024 Actavivo
 */
import * as AV from './Interfaces/AV.Interfaces';
import { Server } from '../Platform/Server';
import { AVBase } from './AVBase';
import { Post } from './Post';
import { Event } from './Event';
import { AVEntity } from './AVEntity';

export class Alert extends AVBase {
  static _alertApi = '/api/Alert';
  static _startDate?: Date;

  public static async processAlerts(alert: AV.IAlert): Promise<boolean> {
    console.log('+==+++++++++++++', alert);
    switch (alert.alertType) {
      case 'NewPost':
      //break;
      case 'Impression':
      //break;
      case 'PostReview':
      //break;
      case 'PostReply':
        var post = await Post.get(alert.target);
        //Cache and call hooks
        break;

      //Events
      case 'NewEvent':
      case 'UpdateEvent':
      case 'CancelEvent':
        //var ev = await AVEntity.get<AV.IEvent>(alert.target);
        //Cache and call hooks
        break;

      //Resources
      case 'NewResource':
        break;
      case 'DelResource':
        break;
      case 'UpdResource':
        break;

      //Convos
      case 'ReplyDM-Ward':
      //break;
      case 'NewDM-Ward':
      //break;
      case 'NewDM':
      //break;
      case 'ReplyDM':
      //break;
      case 'NewConvo':
        var dm = await Post.get(alert.target);
        //Cache and call hooks
        break;

      //Requests
      case 'NewRequest':
        break;
      case 'NewRequest-Ward':
        break;
    }

    return true;
  }
  public static async List (startDate ?: Date,isDumbBrowser:boolean = false) : Promise<AV.IAlertList> {
        if (!startDate) startDate = this._startDate;
      var al : AV.IAlertList = await Server.post<AV.IAlertList>(Alert._alertApi + '/List?isDumb='+ isDumbBrowser.toString(), startDate);
        this._startDate = new Date(Date.now());
        //if (al == null)
        //    return [];
        return al;
    }
    public static async updateAll (actionTaken : string): Promise<AV.IAlert> {
        var a : AV.IAlert = await Server.post<AV.IAlert>(Alert._alertApi + "/UpdateAll", actionTaken);
        return a;
    }
  public static async Save(value: AV.IAlert): Promise<AV.IAlert> {
    var a: AV.IAlert = await Server.post<AV.IAlert>(Alert._alertApi, value);
    return a;
  }
}
