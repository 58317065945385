import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { FC } from 'react';
import dayjs from 'dayjs';
interface Props {
    onChange: any;
    label: string;
    defaultValue: string | any;
}

const AVDataPicker: FC<Props> = ({
    label,
    onChange,
    defaultValue,
}) => {
    const date = new Date();
    const disablePastDates = ( date ) =>
    {
        return dayjs( date ).isBefore( dayjs(), 'day' );
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    defaultValue={defaultValue}
                    label={label}
                    onChange={onChange}
                    slotProps={ { textField: { fullWidth: true } } }
                    shouldDisableDate={ disablePastDates }
                />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default AVDataPicker;
