import * as React from 'react';

const Organization = ({ color }) => (
  <>
    <path
      d='M11.425 14.209h-4.64a.928.928 0 0 0-.927.928v.928c1.024 0 1.855.83 1.855 1.855v1.856c0 1.025-.83 1.856-1.855 1.856H4.002a1.856 1.856 0 0 1-1.856-1.856V17.92c0-1.025.83-1.855 1.856-1.855v-.928a2.784 2.784 0 0 1 2.783-2.784h4.64V9.569H8.641a1.856 1.856 0 0 1-1.856-1.856V4.002c0-1.025.831-1.856 1.856-1.856h.928a.928.928 0 0 1 0 1.856h-.928v3.711h7.424V4.002h-3.712a.928.928 0 1 1 0-1.856h3.712c1.025 0 1.855.83 1.855 1.856v3.711c0 1.025-.83 1.856-1.855 1.856H13.28v2.784h4.665a2.784 2.784 0 0 1 2.783 2.784v.928l.068.002c.982.048 1.763.86 1.763 1.853v1.856c0 1.025-.83 1.856-1.856 1.856h-1.856a1.856 1.856 0 0 1-1.855-1.856V17.92c0-.993.78-1.805 1.763-1.853l.117-.002v-.928a.928.928 0 0 0-.927-.928H13.28v1.856c1.025 0 1.856.83 1.856 1.855v1.856c0 1.025-.831 1.856-1.856 1.856h-1.856a1.856 1.856 0 0 1-1.856-1.856V17.92c0-1.025.831-1.855 1.856-1.855v-1.856ZM5.858 17.92H4.002v1.856h1.856V17.92Zm14.846 0h-1.856v1.856h1.856V17.92Zm-7.423 0h-1.856v1.856h1.856V17.92Z'
      id='Shape'
      fillOpacity='0.85'
      fill={color ? color : '#000000'}
      fillRule='nonzero'
    ></path>
  </>
);

export default Organization;
